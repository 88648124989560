import React, { useEffect } from 'react';
import {
    BrowserRouter as Router,
    Switch,
    Redirect,
    Route
} from "react-router-dom";
import _ from "lodash";
import { connect } from 'react-redux';

import { checkIsAuth } from '../../auth/actions/auth.action';
import { getCatgoryTree } from '../actions/app.action';

// layout
import Header from '../components/header.component';
import Footer from '../components/footer.component';
import Head from "../components/head.component";

// główna strona
import HomeCardsContainer from '../../home/containers/homeCards.container';
import HomeOffersContainer from '../../home/containers/homeOffers.container';
import SingleCardContainer from '../../home/containers/singleCard.container';
import SingleOfferContainer from '../../home/containers/singleOffer.container';

// autoryzacja, logowanie, rejestracja
import RegisterContainer from '../../auth/containers/register.container';
import LoginInContainer from '../../auth/containers/loginIn.container';
import ReminderContainer from '../../auth/containers/reminder.container';

// profil uzytkownika / firmy
import ProfileContainer from '../../panel/containers/profile.container';
import CardsContainer from '../../panel/containers/cards.container';
import CardContainer from '../../panel/containers/card.container';
import OffersContainer from '../../panel/containers/offers.container';
import OfferContainer from '../../panel/containers/offer.container';

import LoaderComponent from '../components/loader.component';
import ToastComponent from '../components/toast.component';

// admin routes
import AcceptanceContainer from '../../admin/containers/acceptance.container';
import CardDetailsContainer from '../../admin/containers/cardDetails.container';
import OfferDetailsContainer from '../../admin/containers/offerDetails.container';

// 404 route
import NoMatchPageComponent from '../components/noMatch.component';
// import ModuleHeaderComponent from '../components/moduleHeader.component';

// import GramyRazem from '../assets/img/gramy-razem.jpg';
// import GramyRazemMobile from '../assets/img/gramy-razem-mobile.jpg';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../assets/global.css';


// google analytics
import ReactGA from 'react-ga';
if (!_.includes(window.location.href, "usermd.net") && !_.includes(window.location.href, "localhost")) {
    ReactGA.initialize('UA-164687457-1');
    ReactGA.pageview(window.location.pathname + window.location.search);
}

function App(props) {

    const { checkIsAuth, getCatgoryTree } = props;

    useEffect(() => {
        checkIsAuth();
        getCatgoryTree();
    }, [checkIsAuth, getCatgoryTree]);

    return (
        <Router>
            <Head />
            <Header />
            <LoaderComponent />
            <Switch>
                <Route exact path="/">
                    <Redirect to="/kandydaci" />
                </Route>
                <Route exact path={["/kandydaci", "/kandydaci/page/:page?"]}>
                    <HomeCardsContainer />
                </Route>
                <Route exact path={["/oferty", "/oferty/page/:page?"]}>
                    <HomeOffersContainer />
                </Route>
                <Route path="/cards/:cardId">
                    <SingleCardContainer />
                </Route>
                <Route path="/offers/:offerId">
                    <SingleOfferContainer />
                </Route>
                <Route path={["/login/:token", "/login"]}>
                    <LoginInContainer />
                </Route>
                <Route path={["/reminder/:token", "/reminder"]}>
                    <ReminderContainer />
                </Route>
                <Route path="/register">
                    <RegisterContainer />
                </Route>

                {/* dla zalogowanych użytkowników z rolą "użytkownik" */}
                <PrivateUserRoute {...props} path="/cards" permissionRole="person">
                    <CardsContainer />
                </PrivateUserRoute>
                <PrivateUserRoute  {...props} path={["/card/:id", "/card"]} permissionRole="person">
                    <CardContainer />
                </PrivateUserRoute>

                {/* dla zalogowanych uzytkowników z rolą "firma" */}
                <PrivateUserRoute  {...props} path="/offers" permissionRole="company">
                    <OffersContainer />
                </PrivateUserRoute>
                <PrivateUserRoute  {...props} path={["/offer/:id", "/offer"]} permissionRole="company">
                    <OfferContainer />
                </PrivateUserRoute>

                {/* dla zalogowanego użytkownika z rolą "użytkownik" lub "firma" */}
                <PrivateUserRoute  {...props} path="/profile">
                    <ProfileContainer />
                </PrivateUserRoute>

                {/* dla zalogowanego admina */}
                <AdminRoute exact {...props} path={["/admin/:selectedTabParam", "/admin"]}>
                    <AcceptanceContainer />
                </AdminRoute>
                <AdminRoute  {...props} path="/admin/card/:cardId">
                    <CardDetailsContainer />
                </AdminRoute>
                <AdminRoute  {...props} path="/admin/offer/:offerId">
                    <OfferDetailsContainer />
                </AdminRoute>

                {/* 404 page */}
                <Route component={NoMatchPageComponent} />


            </Switch>
            <Footer />
            <ToastComponent />
        </Router>
    )

    // ten route widzi tylko zalogowany użytkownik niebędący adminem
    function PrivateUserRoute({ children, loggedUser, isAuthLoading, permissionRole, ...rest }) {

        // dostep kiedy jest request isAlowed, jest zalogowany i nie jest adminem, i pasuje permissionRole, albo jest zalogowany i nie jest adminem, ale nie jest wymagany permissionRole
        let isAllowed = isAuthLoading || (loggedUser && !loggedUser.isAdmin && loggedUser.type === permissionRole) || (loggedUser && !loggedUser.isAdmin && !permissionRole);

        return (
            <Route
                {...rest}
                render={({ location }) => {

                    let pathName = "/login";
                    if (!isAuthLoading && isAllowed && loggedUser && !loggedUser.profileCompleted) {
                        pathName = "/profile";
                        if (location.pathname !== '/profile') {
                            isAllowed = false;
                        }
                    } else if ((loggedUser && loggedUser.isAdmin) || (loggedUser && loggedUser.type !== permissionRole)) {
                        pathName = "/";
                    }

                    return isAllowed ? (
                        children
                    ) : (
                            <Redirect
                                to={{
                                    pathname: pathName,
                                    state: { from: location }
                                }}
                            />
                        )
                }
                }
            />
        );
    }

    // ten route widzi tylko zalogowany użytkownik będący adminem
    function AdminRoute({ children, loggedUser, isAuthLoading, ...rest }) {
        return (
            <Route
                {...rest}
                render={({ location }) =>
                    isAuthLoading || (loggedUser && loggedUser.isAdmin) ? (
                        children
                    ) : (
                            <Redirect
                                to={{
                                    pathname: "/",
                                    state: { from: location }
                                }}
                            />
                        )
                }
            />
        );
    }
}

const mapStateToProps = state => ({
    loggedUser: state.auth.loggedUser,
    isAuthLoading: state.auth.isAuthLoading,
    categoryTree: state.app.categoryTree
});

const mapDispatchToProps = (dispatch) => {
    return {
        checkIsAuth: () => dispatch(checkIsAuth()),
        getCatgoryTree: () => dispatch(getCatgoryTree())
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(App);


