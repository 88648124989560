import React, { useState, useEffect, Fragment } from 'react';
import { Link } from 'react-router-dom'

import CaretIcon from '../../application/assets/img/caret.svg';

const limit = 20;

function PaginationComponent({ totalCount, paginationRoute, route, setPaginationRoute }) {

   const [pagesCount, setPagesCount] = useState(0);

   useEffect(() => {
      setPagesCount(Math.ceil(totalCount / limit))
   }, [totalCount])

   if (pagesCount < paginationRoute) {
      return null;
   }

   // środkowe routy
   function getRoutes() {
      const firstRoute = paginationRoute - 1;
      const secoundRoute = paginationRoute;
      const thirdRoute = paginationRoute + 1;

      return <Fragment>
         {paginationRoute > 1 && <Link to={`${route}/page/${firstRoute}`} className="hexagons__pagination-item mx-2" onClick={() => setPaginationRoute(firstRoute)}>{firstRoute}</Link>}
         <span className="hexagons__pagination-item mx-2 active" onClick={() => setPaginationRoute(secoundRoute)}>{secoundRoute}</span>
         {thirdRoute < pagesCount && <Link to={`${route}/page/${thirdRoute}`} className="hexagons__pagination-item mx-2" onClick={() => setPaginationRoute(thirdRoute)}>{thirdRoute}</Link>}
      </Fragment>
   }

   return <div id="hexagonsPagination" className="hexagons__pagination bg-white d-flex align-items-center justify-content-center py-3 px-4 mt-md-5 mb-5">
      {/* ARROW LEFT */}
      {paginationRoute !== 1 && <Link className="hexagons__pagination-item hexagons__pagination-prev mx-2" to={`${route}/page/${paginationRoute - 1}`}
         onClick={() => setPaginationRoute(paginationRoute - 1)}>
         <img src={CaretIcon} alt="" />
      </Link>}
      {paginationRoute > 2 && <Link className="hexagons__pagination-item mx-2" to={`${route}/page/${1}`} onClick={() => setPaginationRoute(1)}>
         <span className="single-route">1</span>
      </Link>}
      {pagesCount > 3 && paginationRoute !== 2 && paginationRoute !== 1 && <div className="route-breaker">
         <span>...</span>
      </div>}
      {getRoutes()}
      {pagesCount > 3 && paginationRoute !== (pagesCount - 1) && paginationRoute !== pagesCount && <div className="route-breaker">
         <span>...</span>
      </div >}
      {paginationRoute < pagesCount && <Link className="last-route" to={`${route}/page/${pagesCount}`} onClick={() => setPaginationRoute(pagesCount)}>
         <span className={`hexagons__pagination-item mx-2 ${paginationRoute === pagesCount ? "active" : ""}`} >{pagesCount}</span>
      </Link>}
      {/* ARROW RIGHT */}
      {paginationRoute !== pagesCount && <Link className="hexagons__pagination-item hexagons__pagination-next mx-2" to={`${route}/page/${paginationRoute + 1}`}
         onClick={() => setPaginationRoute(paginationRoute + 1)}>
         <img src={CaretIcon} alt="" />
      </Link>}
   </div>
}

export default PaginationComponent;