
import React from 'react';
import { Container, Row } from "react-bootstrap";

function NomatchComponent() {
    return (
        <Container>
            <Row className="page-not-found-wrapper">
                <div> 404 - Nie ma takiej strony</div>
            </Row>
        </Container>);
}

export default NomatchComponent;