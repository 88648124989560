import React from 'react';
import HomeComponent from '../components/home.component';

import { connect } from 'react-redux';
import { getRecords, clearRecords, clearOffset, setPaginationRoute, clearPaginationRoute, setListType } from '../actions/home.action';


function HomeContainer(props) {
    return (
        <HomeComponent {...props} />
    );
}

const mapStateToProps = (state) => {
    return {
        cards: state.home.cards,
        offers: state.home.offers,
        listType: state.home.listType,
        categoryTree: state.app.categoryTree,
        isLoading: state.app.isLoading,
        total: state.home.total,
        paginationRoute: state.home.page
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        getRecords: () => dispatch(getRecords()), // kiedy niepotrzebujemy promise
        clearRecords: () => dispatch(clearRecords()), // kiedy niepotrzebujemy promise
        clearOffset: () => dispatch(clearOffset()),
        setPaginationRoute: (page) => dispatch(setPaginationRoute(page)),
        clearPaginationRoute: () => dispatch(clearPaginationRoute()),
        setListType: (type) => dispatch(setListType(type))
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(HomeContainer);
