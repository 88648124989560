import React, { useState, useEffect } from 'react';
import _ from 'lodash';

import ArrowDownIcon from '../assets/img/chevron_down.svg';

import '../assets/tree.css';

function TreeComponent(props) {
    const { selectedCategories, setFilters, categoriesTree, showCategoryFilters } = props;
    const [treeData, setTreeData] = useState([]);

    useEffect(() => {
        let chunkData = _.chunk(categoriesTree, 7);
        setTreeData(chunkData);
    }, [categoriesTree]);

    // tymczasowa tablica dla zaznaczonych kategorii, setowane selectNode po jej uzupełnieniu przy kazdym zaznaczeniu
    let categoriesTmp = [];

    // hooksy dla zaznaczonych kategorii oraz otwartych gałęzi
    // const [openNodes, setOpenNode] = useState([]);

    function handleMoreFilters(document) {
        let filtersChevron = document.querySelector('.filters__more-chevron');
        let filtersList = document.querySelector('.filters__more-list');

        if (filtersChevron && filtersList) {
            filtersList.classList.toggle('active');
            filtersChevron.classList.toggle('active');
        }
    }

    function selectCategory(selectedCategory, e) {

        categoriesTmp = [...selectedCategories];

        if (selectedCategories.indexOf(selectedCategory) >= 0) {
            // kategoria jest już zaznaczona więc odznczam ją
            categoriesTmp = [...categoriesTmp.filter(cat => cat !== selectedCategory)];

            //zaznacza wszystkie dzieci jak rodzic zaznaczony
            checkCategoryChildren(categoriesTree, selectedCategory, false)
            // zaznacza rodzica jak wszystkie deci zaznaczone;
            checkParentForChildren(categoriesTree, selectedCategory, false);
        } else {
            // zaznaczam katgorię bo jeszcze nie była zaznaczona
            categoriesTmp = [...categoriesTmp, selectedCategory];

            //zaznacza wszystkie dzieci jak rodzic zaznaczony
            checkCategoryChildren(categoriesTree, selectedCategory, true);
            // zaznacza rodzica jak wszystkie deci zaznaczone
            checkParentForChildren(categoriesTree, selectedCategory, true);
        }

        setFilters("category", [...categoriesTmp]);
    }

    function checkParentForChildren(data, selectedCategory, checked) {
        data.map((category) => {
            if (category.children && category.children.length > 0) {

                let allChecked = true;
                let findedParent = false;

                category.children.map(childrenCategory => {
                    // jeżeli któreś dziecko nie jest zaznaczone to oznaczamy że nie wszystkie
                    if (categoriesTmp.indexOf(childrenCategory.id) < 0) {
                        allChecked = false;
                    }
                    // znaleziono gałąź dziecka
                    if (childrenCategory.id === selectedCategory) {
                        findedParent = true;
                    }

                    return true;
                });

                if (findedParent) {
                    // dla znalezionego rodzica zaznaz kategorie jak nie jest zaznaczona gdy wszystkie dzieci są zaznaczone
                    if (allChecked && categoriesTmp.indexOf(category.id) < 0) {
                        categoriesTmp = [...categoriesTmp, category.id];
                    } else {
                        categoriesTmp = [...categoriesTmp.filter(cat => cat !== category.id)];
                    }
                }

                // rekurencja
                checkParentForChildren(category.children, selectedCategory, checked);
            }

            return true;
        });
    }

    function checkCategoryChildren(data, selectedCategory, checked) {
        data.map(category => {
            if (category.id === selectedCategory) {
                if (category.children && category.children.length > 0) {
                    // zaznaczamy wszystkim dzieciom checked jak rodzic jest zaznaczony
                    category.children.map(childrenCategory => {
                        // jeżeli zaznaczony rodzic
                        if (checked) {
                            // zaznaczamy tylko wtedy kiedy nie było zaznaczone
                            if (categoriesTmp.indexOf(childrenCategory.id) < 0) {
                                categoriesTmp = [...categoriesTmp, childrenCategory.id];
                            }
                        } else { // jeżeli rodzic odznaczony to odznaczamy dzieci
                            categoriesTmp = [...categoriesTmp.filter(cat => cat !== childrenCategory.id)];
                        }

                        return true;
                    });
                }
            }

            // rekurencja
            return checkCategoryChildren(category.children, selectedCategory, checked);
        });
    }

    // funkcja generująca drzewo (rodzice i dzieci w rekurencji)
    function generateTree(data) {
        if (data.length === 0) {
            return;
        }

        return data.map((category, index) => {
            let isChecked = selectedCategories.indexOf(category.id) >= 0 ? true : false;
            let isCheckedClass = isChecked ? "checked" : "";

            return (
                <li key={`category-node-${category.name}-${index}`} className={`${category.children.length > 0 ? 'filters__more' : ''}`}>
                    <label className="pure-material-checkbox">
                        <input
                            type="checkbox"
                            className="filters__more-checkbox"
                            onChange={(e) => selectCategory(category.id, e)}
                            checked={isCheckedClass} />
                        <span>{category.name}</span>
                    </label>

                    {category.children.length > 0 ? (
                        <img
                            src={ArrowDownIcon}
                            alt=""
                            className="filters__more-chevron ml-2 mb-3 cursor-pointer"
                            onClick={(e) => handleMoreFilters(e.target.parentNode)} />
                    ) : <div className="empty-block" />}

                    <ul className="filters__more-list">
                        {generateTree(category.children)}
                    </ul>
                </li>
            )
        });
    }

    return (
        <section id="filters" className={`filters ${showCategoryFilters ? 'active' : ''}`}>
            <div className="container-fluid">
                <div className="row py-5">
                    {treeData.map((data, index) => {
                        return <div key={`tree-thunk-${index}`} className="col-12 col-md-6 col-xl-auto">
                            <ul>
                                {generateTree(data)}
                            </ul>
                        </div>
                    })}
                </div>
            </div>
        </section>
    )
}


export default TreeComponent;
