import React from 'react';
import SingleCardComponent from '../components/singleCard.component';

import { connect } from 'react-redux';
import { getCardDetails, clearCardDetails, sendContactMail } from '../actions/home.action';

function SingleCardContainer(props) {
    return (
        <SingleCardComponent {...props} />
    );
}

const mapStateToProps = (state) => {
    return {
        details: state.home.cardDetails
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        getCardDetails: (cardId) => dispatch(getCardDetails(cardId)), // kiedy nie potrzebujemy promise
        clearCardDetails: () => dispatch(clearCardDetails()),  // kiedy nie potrzebujemy promise
        sendContactMail: (data) => sendContactMail(data, dispatch)  // kiedy potrzebujemy promise
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(SingleCardContainer);
