import { getRecords, clearPaginationRoute } from "../../home/actions/home.action";

const namespace = 'FILTER';

export const RESET_FILTERS = `RESET_FILTERS_${namespace}`;
export const SET_FILTERS = `SET_FILTERS_${namespace}`;
export const SET_ROUTE = `SET_ROUTE_${namespace}`;
export const SET_CATEGORIES_TREE = `SET_CATEGORIES_TREE_${namespace}`;

export const resetQueryFilters = () => {
    return dispatch => {
        dispatch({
            type: RESET_FILTERS
        });
    }
}

export const resetFilters = () => (dispatch, getState) => {
    dispatch({
        type: RESET_FILTERS
    });
    dispatch(clearPaginationRoute());
    dispatch(getRecords());

}

export const setRoute = (route) => dispatch => {
    dispatch({
        type: SET_ROUTE,
        route
    });
}

export const setCategoriesTree = (categoriesTree) => dispatch => {
    dispatch({
        type: SET_CATEGORIES_TREE,
        categoriesTree
    });
}

export const setFilters = (filter, value) => (dispatch, getState) => {
    dispatch({
        type: SET_FILTERS,
        filter,
        value
    });
    dispatch(clearPaginationRoute());
    dispatch(getRecords());
}
