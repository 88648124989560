import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import '../assets/autocomplete.css';
import _ from 'lodash';

/**
 * Description: możemy wybrać autocomplete:
 * 1. multiple - czyli możliwość wielokrotnego wyboru
 * 2. totalSelected - czyli ile maksymalnie możemy wybrać (uwzględnia flagę multi)
 * 3. onlyFilteredValue - czyli wartości wpisywane (pomija wtedy options)
 */

function AutocompleteComponent(props) {

    const { state, setState, multiple, options, placeholder, handleOnSelect, onlyFilteredValue, totalSelected, getOptionsMethod, isRequired, handleFocusInputs } = props;

    const [inputOptions, setInputOptions] = useState([]);
    const [isFocus, setIsFocus] = useState(false);
    const [autocompleteText, setAutocompleteText] = useState('');
    const [selectToken, setSelectToken] = useState(null);

    // ustawiamy na start options z parent component
    useEffect(() => {
        if (options && options.length > 0 && !onlyFilteredValue) {
            if (!_.get(options, '[0].value')) {
                throw new Error("options value required name and value parametr");
            }
            setInputOptions(options);
        }
    }, [options, onlyFilteredValue]);

    useEffect(() => {
        if (state && state.length > 0) {
            if (!_.get(state, '[0].value')) {
                throw new Error("state value required name and value parametr");
            }
        }
    }, [state])

    // jak zmieni sięwartość na inpucie to ustawiamy ją w state inputa i filtrujemy options dla tej wartości
    function onChange(e) {

        const currentValue = e.target.value;
        setAutocompleteText(currentValue);

        if (currentValue !== "") {

            // jeżeli użytkownik wpisze przecinek do dodajemy od razu, dla selekta bez options
            if (currentValue.indexOf(",") >= 0 && onlyFilteredValue) {
                onSelect({ name: currentValue.replace(",", ""), value: currentValue.replace(",", "") });
                setInputOptions([]);
                return;
            }

            let tokenUUID = selectToken;

            // generuj token dla selekta (dla google)
            if (!selectToken) {
                tokenUUID = randomUUID();
                setSelectToken(tokenUUID);
            }

            if (getOptionsMethod) {
                getOptionsMethod(currentValue, tokenUUID).then((options) => {
                    if (options && options.length > 0 && !_.get(options, '[0].value')) {
                        throw new Error("options value required name and value parametr");
                    }
                    setInputOptions(options);
                });
            } else {
                if (onlyFilteredValue) {
                    setInputOptions([{ name: currentValue, value: currentValue }]);
                } else {
                    setInputOptions(options.filter((option) => option.name.match(new RegExp(currentValue, 'i')) !== null))
                }
            }
        } else {
            setSelectToken(null);
        }
    }

    // po wybraniu wartości z "options"
    function onSelect(value) {
        //sprwadzamy czy wartość nie jest już wybrana i czy nie przekroczyła maksymalnej ilości wyboru
        if (state.filter((singleSelectedValue) => singleSelectedValue.value === value.value).length === 0 && (!totalSelected || (totalSelected && state.length < totalSelected))) {

            // czyścimy tekst autocomplete
            setAutocompleteText('');

            // tylko aktualizujemy jak nie ma onlyFilterValue - czyli dodawania wartości z palca
            if (!onlyFilteredValue) {
                setInputOptions(options);
            }

            const currentValues = multiple ? [...state, value] : [value];

            // ustaw wybrane wartości do state componentu
            setState(currentValues);
            // przekaż wybrane wartości do parent component
            handleOnSelect(currentValues);
            // po wybraniu czyścimy token
            setSelectToken(null);
        }
    }

    // obsługa show placeholder gdy focus na inpucie
    function onFocus() {
        setIsFocus(true);
        handleFocusInputs();
    }

    // obsługa show placeholder gdy focus na inpucie
    function onBlur() {
        setTimeout(() => {
            setIsFocus(false);
            if (onlyFilteredValue) {
                setInputOptions([]); // wyczyść dla wartości dynamicznych (wpisywanych ręcznie)
            }
        }, 200);
    }

    function randomUUID() {
        let s = [], itoh = '0123456789ABCDEF';
        for (let i = 0; i < 36; i++) s[i] = Math.floor(Math.random() * 0x10);
        s[14] = 4;
        s[19] = (s[19] & 0x3) | 0x8;
        for (let i = 0; i < 36; i++) s[i] = itoh[s[i]];
        s[8] = s[13] = s[18] = s[23] = '-';
        return s.join('');
    }

    // to dla wartości wpisywanych, obsługa onKeyPressa dla entera
    function onKeyPress(e) {

        if (onlyFilteredValue && e.key === 'Enter' && e.target.value !== "") {
            onSelect({ name: e.target.value, value: e.target.value });
            setInputOptions([]); // wyczyść dla wartości dynamicznych (wpisywanych ręcznie)
            e.preventDefault(); // blokujemy event dla formularzy
        }

    }

    return (
        <div className="mb-2">
            <div className="d-flex align-items-center">
                <label className="ml-5">{placeholder} {isRequired && <span className="required"> *</span>}</label>
                <input
                    value={autocompleteText}
                    onKeyPress={onKeyPress}
                    onBlur={onBlur}
                    className="material-input__input h-100"
                    type="text"
                    onChange={(e) => onChange(e)}
                    onFocus={onFocus} />
            </div>
            {isFocus && inputOptions.length > 0 && <div className="autocomplete-options-wrapper">
                {inputOptions.map((singleOption, index) => {
                    return <span key={`option-${index}`} onClick={() => onSelect(singleOption)}>{singleOption.name}</span>;
                })}
            </div>
            }
        </div>
    )
}

AutocompleteComponent.propTypes = {
    options: PropTypes.array.isRequired,
    handleOnSelect: PropTypes.func.isRequired,
    initialSelected: PropTypes.array,
    getOptionsMethod: PropTypes.func,
    multiple: PropTypes.bool,
    totalSelected: PropTypes.number
};

export default AutocompleteComponent;