import React from "react";
import { useLocation } from "react-router-dom";
import { Helmet } from "react-helmet";

export default function Head() {

    const location = useLocation();
    let url = location.pathname;
    let title = null;
    let description = null;
    if (url === "/login") {
        title = "Beemployee.pl - logowanie";
        description = "Beemployee.pl - zostaw swoją wizytówkę, pochwal się swoimi osiągnięciami i bądź gotów na nowe wyzwania. Jeżeli jesteś firmą to tu znajdziesz najlepiej dopasowanych kandydatów do swoich potrzeb!";
    } else if (url === "/register") {
        title = "Beemployee.pl - rejestracja";
        description = "Beemployee.pl - zostaw swoją wizytówkę, pochwal się swoimi osiągnięciami i bądź gotów na nowe wyzwania. Jeżeli jesteś firmą to tu znajdziesz najlepiej dopasowanych kandydatów do swoich potrzeb!";
    } else if (url.indexOf("kandydaci") > -1) {
        title = "Beemployee.pl - kandydaci";
        description = "Beemployee.pl - zostaw swoją wizytówkę, pochwal się swoimi osiągnięciami i bądź gotów na nowe wyzwania. Jeżeli jesteś firmą to tu znajdziesz najlepiej dopasowanych kandydatów do swoich potrzeb!";
    } else if (url.indexOf("oferty") > -1) {
        title = "Beemployee.pl - oferty pracy";
        description = "Beemployee.pl - zostaw swoją wizytówkę, pochwal się swoimi osiągnięciami i bądź gotów na nowe wyzwania. Jeżeli jesteś firmą to tu znajdziesz najlepiej dopasowanych kandydatów do swoich potrzeb!";
    } else {
        title = "Beemployee.pl - kandydaci i oferty pracy!";
        description = "Beemployee.pl - zostaw swoją wizytówkę, pochwal się swoimi osiągnięciami i bądź gotów na nowe wyzwania. Jeżeli jesteś firmą to tu znajdziesz najlepiej dopasowanych kandydatów do swoich potrzeb!";
    }

    if (title && description) {
        return (
            <Helmet>
                <title>{title}</title>
                <meta name="description" content={description} />
            </Helmet>
        );
    } else {
        return null;
    }
}
