import { showToast } from '../../application/actions/app.action';
import { logout } from '../../auth/actions/auth.action';

class Api {

    constructor() {
        // this.host = 'http://localhost:3001/';
        this.host = 'https://api-bee.overcloud.usermd.net/';
        // this.host = 'https://api.beemployee.pl/';
        this.headers = {
            'Content-Type': 'application/json'
        }
    }

    getToken() {
        return localStorage.getItem('token') ? `JWT:${localStorage.getItem('token')}` : false;
    }

    setDispatch(dispatch) {
        this.dispatch = dispatch;
    }

    getDispatch() {
        return this.dispatch;
    }

    async get(url, params) {
        if (params) {
            url += "?" + Object.keys(params).map(function (key) {
                return key + '=' + params[key];
            }).join('&');
        }

        return await this.request(this.host + url, 'GET', null);
    }

    async post(url, data) {
        return await this.request(this.host + url, 'POST', data);
    }

    async customPost(url, data) {
        return await this.request(url, 'POST', data);
    }

    async put(url, data, id) {
        if (id) {
            url += `/${id}`;
        }
        return await this.request(this.host + url, 'PUT', data);
    }

    async delete(url, id) {
        if (id) {
            url += `/${id}`;
        }
        return await this.request(this.host + url, 'DELETE', null);
    }

    async request(url, method, data) {

        let options = {
            method: method,
            headers: this.headers
        }


        if (this.getToken()) {
            this.headers.authorization = this.getToken();
        }

        // dla POST, PUT
        if (data && Object.keys(data).length > 0) {
            // jeżeli mamy plik do uploadu
            if (data.file) {
                delete options.headers['Content-Type'];
                const fileData = new FormData();

                fileData.append('file', data.file);
                options.body = fileData;
            } else {
                options.body = JSON.stringify(data);
                options.headers['Content-Type'] = 'application/json';
            }
        } else {
            options.headers['Content-Type'] = 'application/json';
        }

        return await fetch(url, options).then((response) => {
            switch (response.status) {
                case 200:
                case 201:
                case 422:
                case 404:
                case 403:
                    return response.json();
                case 401:
                    // bardzo ważny element, usuwamy token i zalogowanego uzytkownika ze stora jak jakiś request zwróci 401
                    if (localStorage.getItem('token')) {
                        this.getDispatch()(showToast('alert', 'brak autoryzacji'));
                        this.getDispatch()(logout());
                    }
                    return response.json();
                default:
                    this.getDispatch()(showToast('alert', 'Wystąpił błąd krytyczny'));
                    return response.json();
                    break;
            }

        }).then((data) => {
            return data;
        });

    }

}

export default new Api();