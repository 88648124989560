import React from 'react';
import { connect } from 'react-redux'
import CardComponent from '../components/card.component';
import { getAllAdministrativeAreaLevel1, getPlaces } from '../../application/actions/app.action';
import { saveCard, getCard, clearCard } from '../actions/card.action';

function CardContainer(props) {
    return (
        <CardComponent {...props} />
    );
}

const mapStateToProps = (state) => {

    return {
        card: state.panelCard.card,
        loggedUser: state.auth.loggedUser,
        categoryTree: state.app.categoryTree
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        saveCard: (cardData) => saveCard(cardData, dispatch), // jak potrzebujemy promise
        getCard: (cardId) => dispatch(getCard(cardId)), // jak nie potrzebujemy promise
        clearCard: () => dispatch(clearCard()), // jak nie potrzebujemy promise,
        getAllAdministrativeAreaLevel1: () => getAllAdministrativeAreaLevel1(),
        getPlaces: (query, token) => getPlaces('cities', query, token),
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(CardContainer);
