import Api from '../../application/service/api.service';
import { isLoading, showToast } from '../../application/actions/app.action';
import _ from "lodash";
const namespace = 'PROFILE_OFFER';

export const SET_OFFERS = `SET_OFFERS_${namespace}`;
export const CLEAR_OFFERS = `CLEAR_OFFERS_${namespace}`;
export const SET_OFFER = `SET_OFFER_${namespace}`;
export const CLEAR_OFFER = `CLEAR_OFFER_${namespace}`;

export const setOffer = (offer) => ({
    type: SET_OFFER,
    offer
});

export const clearOffer = () => ({
    type: CLEAR_OFFER
});

export function getOffer(offerId) {
    return dispatch => {

        // globalny is loading 
        dispatch(isLoading(true));

        return Api.get(`profile/offer/${offerId}`, null).then((result) => {

            // zawsze ukrywamy globalny is loading
            dispatch(isLoading(false));

            if (result.success) {
                dispatch(setOffer(result.document));
            } else if ('errors' in result) {
                dispatch(showToast('alert', 'Błąd podczas pobierania oferty'));
            }
        });
    };
}

export function deleteOffer(offerId) {
    return dispatch => {

        // globalny is loading 
        dispatch(isLoading(true));

        return Api.delete(`profile/offer`, offerId).then((result) => {

            // zawsze ukrywamy globalny is loading
            dispatch(isLoading(false));

            if (result.success) {
                dispatch(getOffers());
            } else if ('errors' in result) {
                dispatch(showToast('alert', 'Błąd podczas usuwania oferty'));
            }
        });
    };
}

export const setOffers = (offers, total) => ({
    type: SET_OFFERS,
    offers,
    total
});

export const clearOffers = () => ({
    type: CLEAR_OFFERS
});

export function getOffers() {
    return (dispatch, getState) => {

        const { filter: { filters } } = getState();

        let params = {
            "sort[createdAt]": -1,
            "limit": 30
        };

        if (filters.query instanceof Array && filters.query.length > 0) {
            params["category.categoryName[orin]"] = filters.query.join(',');
            params["position[orin]"] = filters.query.join(',');
            params["shortDescription[orin]"] = filters.query.join(',');
            params["description[orin]"] = filters.query.join(',');
        }

        if (filters.category instanceof Array && filters.category.length > 0) {
            params["category.categoryId[in]"] = filters.category.join(",");
        }

        if (filters.place instanceof Array && filters.place.length > 0) {
            params["address.city[in]"] = filters.place.join(',');
            params["address.adnimistrative_area_level_1[in]"] = filters.place.join(',');
        }

        // globalny is loading 
        dispatch(isLoading(true));

        return Api.get('profile/offer', params).then((result) => {

            // zawsze ukrywamy globalny is loading
            dispatch(isLoading(false));

            if (result.success) {
                dispatch(setOffers(result.documents, result.total))
            } else if ('errors' in result) {
                dispatch(showToast('alert', 'Błąd podczas pobierania ofert'));
            }
        });
    };
}

export function saveOffer(offerData, dispatch) {
    return new Promise((resolve, reject) => {

        // globalny is loading 
        dispatch(isLoading(true));
        if (_.isArray(offerData.requiredSkills)) {
            offerData.requiredSkills = offerData.requiredSkills.map((skill) => {
                if (_.has(skill, "value")) {
                    return skill.value;
                } else {
                    return skill;
                }
            });
        }

        if (offerData._id) { // edycja
            return Api.put('profile/offer', offerData, offerData._id).then((result) => {

                // zawsze ukrywamy globalny is loading
                dispatch(isLoading(false));

                if (result.success) {
                    dispatch(showToast('info', 'Oferta została zaktualizowana'));
                    return resolve();
                } else if ('errors' in result) {
                    return reject(result.errors);
                }
            });
        } else { // dodawanie
            return Api.post('profile/offer', offerData).then((result) => {

                // zawsze ukrywamy globalny is loading
                dispatch(isLoading(false));

                if (result.success) {
                    dispatch(showToast('info', 'Oferta została dodana'));
                    return resolve();
                } else if ('errors' in result) {
                    return reject(result.errors);
                }
            });
        }
    });
}

export function changeStatus(offerId, isActive) {
    return dispatch => {

        // globalny is loading 
        dispatch(isLoading(true));

        return Api.put('profile/offer/changeIsActive', { active: isActive }, offerId).then((result) => {

            // zawsze ukrywamy globalny is loading
            dispatch(isLoading(false));

            if (result.success) {
                dispatch(getOffers()); // pobieramy jeszcze raz oferty by się na widoku odświeżyło
                dispatch(showToast('info', 'Status został zmieniony poprawnie'));
            } else if ('errors' in result) {
                dispatch(showToast('alert', 'Błąd podczas zmiany statusu oferty'));
            }
        });
    };
}


export function refreshOffer(offerId) {
    return dispatch => {
        // globalny is loading 
        dispatch(isLoading(true));

        return Api.put('profile/refreshOffer', {}, offerId).then((result) => {

            // zawsze ukrywamy globalny is loading
            dispatch(isLoading(false));

            if (result.success) {
                dispatch(getOffers()); // pobieramy jeszcze raz wizytówki by się na widoku odświeżyło
                dispatch(showToast('info', 'Oferta została odświeżona'));
            } else if ('errors' in result) {
                dispatch(showToast('alert', 'Błąd podczas odświeżania oferty'));
            }
        });
    };
}
