import { SET_OFFER, CLEAR_OFFER } from '../actions/offer.action';

const initial_state = {
    offer: []
}

const offer = (state = initial_state, action) => {
    switch (action.type) {
        case SET_OFFER:
            return { ...state, offer: action.offer };
        case CLEAR_OFFER:
            return { offer: [] };
        default:
            return state;
    }
};

export default offer;