// import _ from 'lodash';
import React, { useEffect, Fragment } from 'react';
import { useParams, useLocation, Link } from "react-router-dom";
import { Container, Row } from 'react-bootstrap';
import FilterComponent from '../../application/containers/filter.container';
import PaginationComponent from './pagination.component';

import BeeIcon from '../../application/assets/img/bee_icon.svg';
import HexagonIcon from '../../application/assets/img/hexagon.png';

function HomeComponent(props) {
   const { getRecords, listType, cards, offers, clearRecords, isLoading, clearOffset, clearPaginationRoute, setPaginationRoute, total, paginationRoute, setListType } = props;

   let { page } = useParams();
   let location = useLocation();

   useEffect(() => {
      if (location.pathname.indexOf("kandydaci") > -1) {
         setListType("cards", true);
      } else {
         setListType("offers", true);
      }
      setPaginationRoute(page);
      return () => {// componentUnmount
         clearPaginationRoute();
      }
   }, [setPaginationRoute, clearPaginationRoute, page]);

   useEffect(() => {
      return () => {// componentUnmount
         clearRecords();
         clearOffset();
      }
   }, [getRecords, clearRecords, clearOffset]);

   function getListByType() {
      if (listType === "cards") {
         return (
            <Fragment>
               {cards.map((card, index) => {
                  return (
                     <li key={'card-' + index} className="hex hex--job">
                        <div className="hex__in">
                           <div className="hex__content">
                              <div className="hex__icon zi-1">
                                 <img src={BeeIcon} alt="" className="w-100" />
                              </div>
                              <p className="hex__name zi-1">#{card.cardNumber}</p>
                              <p className="hex__position">{card.position}</p>
                              <p className="hex__category-title zi-1">Kategoria</p>
                              {card.categories.map((category, index) => {
                                 return index < 2 && <p key={`card-catgory-${index}`} className="hex__category zi-1">{category.categoryBreadcrumbs}</p>
                              })}
                           </div>
                           <img src={HexagonIcon} alt="" className="hex__bg" />
                           <Link to={`/cards/${card._id}`} className="hex__link"> </Link>
                        </div>
                     </li>
                  )
               })}
               {cards.length === 0 && !isLoading && <Container>
                  <Row className="justify-content-center">
                     <div>Nie znaleziono żadnych wizytówek</div>
                  </Row>
               </Container>}
            </Fragment>
         )
      } else if (listType === "offers") {
         return (
            <Fragment>
               {offers.map((offer, index) => {
                  return (
                     <li className="hex hex--job" key={'offer-' + index} >
                        <div className="hex__in">
                           <div className="hex__content">
                              <div className="hex__icon zi-1">
                                 <img src={BeeIcon} alt="" className="w-100" />
                              </div>
                              <p className="hex__id zi-1">#{offer.offerNumber}</p>
                              <p className="hex__position">{offer.position}</p>
                              <p className="hex__category-title zi-1">Kategoria</p>
                              {offer.categories.map((category, index) => {
                                 return index < 2 && <p key={`card-catgory-${index}`} className="hex__category zi-1">{category.categoryBreadcrumbs}</p>
                              })}
                           </div>
                           <img src={HexagonIcon} alt="" className="hex__bg" />
                           <Link to={`/offers/${offer._id}`} className="hex__link"></Link>
                        </div>
                     </li>
                  )
               })}
               {offers.length === 0 && !isLoading && <Container>
                  <Row className="justify-content-center">
                     <div>Nie znaleziono żadnych ofert pracy</div>
                  </Row>
               </Container>}
            </Fragment>
         )
      }
   }

   return (
      <div>
         <FilterComponent route="home" />
         <section id="hexagons" className="hexagons mt-4">
            <div className="container-fluid">
               <div className="row">
                  <div className="col-12">
                     <ul id="hexGrid">
                        {getListByType()}
                     </ul>
                     <div className="row justify-content-center">
                        <div className="col-auto">
                           <PaginationComponent
                              totalCount={total || 0}
                              route={listType === "cards" ? "/kandydaci" : "/oferty"}
                              paginationRoute={parseInt(paginationRoute)}
                              setPaginationRoute={setPaginationRoute} />
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </section>
      </div>
   )

}

export default HomeComponent;
