import React, { Fragment, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import languagues from "../assets/languagues.json";
import AutocompleteComponent from '../../application/components/autocomplete.component';

import CloseIcon from '../../application/assets/img/filter_close.svg';

function LanguageSelector(props) {

    const { setErrors, errors, handleSelectLanguage, handleFocusInputs, formikErrors, formikTouched, isRequired } = props;

    const [selectedLanugage, setLanugage] = useState([]);
    const [selectedLanugageLevel, setLanugageLevel] = useState([]);

    useEffect(() => {
        if (selectedLanugage.length !== 0 && selectedLanugageLevel.length !== 0) {
            if (setErrors) setErrors({});

            handleSelectLanguage(`${selectedLanugage[0].value} ${selectedLanugageLevel[0].value}`);
            setLanugage([]);
            setLanugageLevel([]);
        }
    }, [selectedLanugage, selectedLanugageLevel])

    function handleRemoveLanguage() {
        setLanugage([]);
    }

    function handleRemoveLanguageLevel() {
        setLanugageLevel([]);
    }

    return (
        <Fragment>
            <div className={`col-12 col-md-6 material-input ${(errors && errors.selectedLanugage) || (formikErrors && formikTouched) ? 'input-error' : ''}`}>
                <AutocompleteComponent
                    state={selectedLanugage}
                    setState={setLanugage}
                    options={languagues}
                    handleOnSelect={() => { }}
                    placeholder="Wybierz język"
                    handleFocusInputs={() => handleFocusInputs()}
                    isRequired={isRequired}
                />
                {errors && errors.selectedLanugage ? (
                    <div className="material-input__error">{errors.selectedLanugage}</div>
                ) : null}
                {formikErrors && formikTouched ? (
                    <div className="material-input__error">{formikErrors}</div>
                ) : null}
                <div className="col-12 mb-4">
                    <div className="row no-gutters">
                        {selectedLanugage && selectedLanugage.length > 0 && selectedLanugage.map((language, index) => {
                            return <div className="col-auto" key={'language-' + index}>
                                <button
                                    className="btn btn--filter mr-4 mt-4 filter-result__single"
                                    onClick={() => handleRemoveLanguage()}>{language.name}
                                    <span className="ml-5"><img src={CloseIcon} alt="" /></span>
                                </button>
                            </div>
                        })}
                    </div>
                </div>
            </div>
            <div className={`col-12 col-md-6 material-input ${(errors && errors.selectedLanugageLevel) || (formikErrors && formikTouched) ? 'input-error' : ''}`}>
                <AutocompleteComponent
                    state={selectedLanugageLevel}
                    setState={setLanugageLevel}
                    options={[
                        { name: 'Podstawowy', value: 'podstawowy' },
                        { name: 'Średni', value: 'średni' },
                        { name: 'Średnio zaawansowany', value: 'średnio zaawansowany' },
                        { name: 'Zaawansowany', value: 'zaawansowany' },
                        { name: 'Ojczysty', value: 'ojczysty' },
                    ]}
                    handleOnSelect={() => { }}
                    placeholder="Wybierz poziom"
                    handleFocusInputs={() => handleFocusInputs()}
                    isRequired={isRequired}
                />
                {errors && errors.selectedLanugageLevel ? (
                    <div className="material-input__error">{errors.selectedLanugageLevel}</div>
                ) : null}
                {formikErrors && formikTouched ? (
                    <div className="material-input__error">{formikErrors}</div>
                ) : null}
                <div className="col-12 mb-4">
                    <div className="row no-gutters">
                        {selectedLanugageLevel && selectedLanugageLevel.length > 0 && selectedLanugageLevel.map((languageLevel, index) => {
                            return <div className="col-auto" key={'language-' + index}>
                                <button
                                    className="btn btn--filter mr-4 mt-4 filter-result__single"
                                    onClick={() => handleRemoveLanguageLevel()}>{languageLevel.name}
                                    <span className="ml-5"><img src={CloseIcon} alt="" /></span>
                                </button>
                            </div>
                        })}
                    </div>
                </div>
            </div>
        </Fragment>
    )

}

LanguageSelector.propTypes = {
    handleSelectLanguage: PropTypes.func.isRequired,
    setErrors: PropTypes.func
};

export default LanguageSelector;