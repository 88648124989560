import { useFormik } from 'formik';
import React, { useCallback, useEffect, useState } from 'react';
import { Col } from 'react-bootstrap';
import AutocompleteComponent from '../../application/components/autocomplete.component';
import ConfirmModalComponent from '../../application/components/confirmModal.component';
import LanguageSelector from '../../application/components/languageSelector.component';
import '../assets/profile.css';

import { ProfilePersonInitialValues, ProfilePersonSchema } from '../schemas/profile.schema';
import AddEducationComponent from './addEducation.component';
import ContentnavComponent from '../../application/components/contentNav.component';

import CloseIcon from '../../application/assets/img/filter_close.svg';

function PersonProfileComponent(props) {

   const { getProfile, clearProfile } = props;

   useEffect(() => {
      getProfile();
      return () => {
         clearProfile();
      }
   }, [getProfile, clearProfile]);

   const Profile = (props) => {

      const { profile, handleUploadCV, handleDeleteCV, downloadCv, deleteAccount } = props;

      const fileRef = React.createRef();
      const clearButton = <svg enableBackground="new 0 0 32 32" fill="white" height="15px" viewBox="0 0 32 32" width="15px"><path d="M20.377,16.519l6.567-6.566c0.962-0.963,0.962-2.539,0-3.502l-0.876-0.875c-0.963-0.964-2.539-0.964-3.501,0  L16,12.142L9.433,5.575c-0.962-0.963-2.538-0.963-3.501,0L5.056,6.45c-0.962,0.963-0.962,2.539,0,3.502l6.566,6.566l-6.566,6.567  c-0.962,0.963-0.962,2.538,0,3.501l0.876,0.876c0.963,0.963,2.539,0.963,3.501,0L16,20.896l6.567,6.566  c0.962,0.963,2.538,0.963,3.501,0l0.876-0.876c0.962-0.963,0.962-2.538,0-3.501L20.377,16.519z" fill="white" /></svg>;
      const editButton = <svg height="16px" viewBox="0 0 16 16" width="16px" ><g fill="none" fillRule="evenodd" id="Icons with numbers" stroke="none" strokeWidth="1"><g fill="#000000" id="Group" transform="translate(-384.000000, -192.000000)"><path d="M385,203.950806 L389,208 L385,208 Z M392,196 L396,200 L389.978638,207.044189 L386,203 Z M394.084619,193.781497 C394.709458,193.156658 395.90929,193.343426 396.764518,194.198654 L397.538782,194.972918 C398.394011,195.828147 398.580778,197.027979 397.95594,197.652817 L396.720394,198.888363 L392.849074,195.017043 Z M394.084619,193.781497" id="Triangle 313" /></g></g></svg>;

      const [areaProfileLevel1, setProfileAreaLevel1] = useState([]);
      const [profileCity, setProfileCity] = useState([]);

      const [editedEducation, setEditedEducation] = useState({});
      const [cvFileName, setCvFileName] = useState("");
      const [errors, setApiErrors] = useState(null);
      const formData = useCallback(setFormData, [profile]);
      const [showModal, setShowModal] = useState(false);

      useEffect(() => {
         handleFocusInputs();
      })
      useEffect(() => { // jak przyjdzie profil w propsach to ustawiamy go na formularzu
         formData();
         setCvFileName(profile.cv || null);
      }, [profile, formData]);

      function handleFocusInputs() {
         let materialInput = document.querySelectorAll('.material-input');

         if (materialInput) {
            materialInput.forEach(function (e) {
               let input = e.querySelector('.material-input__input');
               let label = e.querySelector('label');

               if (input && label) {
                  input.addEventListener('focusin', (event) => {
                     label.classList.add('float-label');
                  })

                  input.addEventListener('focusout', (event) => {
                     if (input.value === "") {
                        label.classList.remove('float-label');
                     }
                  })
               }

            })
         }
      }

      function handleOnValueInput() {
         let materialInput = document.querySelectorAll('.material-input');

         if (materialInput) {
            materialInput.forEach(function (e) {
               let input = e.querySelector('.material-input__input');
               let label = e.querySelector('label');

               if (input && input.value && input.value !== '') {
                  label.classList.add('float-label');
               }

            })
         }
      }
      handleOnValueInput();

      function uploadCV(e) {
         handleUploadCV(e).then((fileName) => {
            setCvFileName(fileName);
         });
      }

      function deleteCV() {
         handleDeleteCV().then(() => {
            setCvFileName("");
         });
      }

      function toggleModal() {
         setShowModal(!showModal);
      }

      function handleDeleteAccount() {
         toggleModal(); // zamykamy modal
         deleteAccount();
      }

      function setFormData() {
         Object.keys(profile).map((name) => {
            if (name === 'address') {
               setProfileCity([{ name: profile[name].city, value: profile[name].city }]);
               setProfileAreaLevel1([{ name: profile[name].administrative_area_level_1, value: profile[name].administrative_area_level_1 }]);

               formik.setFieldValue(name, profile[name]);
            } else {
               formik.setFieldValue(name, profile[name]);
            }
            return true;
         });
      }

      function handleSelectLanguage(selectedLanguage) {
         setApiErrors({});
         if (formik.values.languages.indexOf(selectedLanguage) >= 0) {
            setApiErrors({ selectedLanugage: "Ten język już został wybrany" });
         } else {
            formik.setFieldValue("languages", [...formik.values.languages, selectedLanguage]);
         }
      }

      function handleRemoveLanguage(selectedLanguage) {
         formik.setFieldValue("languages", [...formik.values.languages.filter(e => e !== selectedLanguage)]);
      }

      function handleAddEducation(newEducation) {
         let currentEducationArray = formik.values.education;

         // dla edukacji edytowanych
         if (Number.isInteger(newEducation.index)) {
            currentEducationArray[newEducation.index] = newEducation;
            delete currentEducationArray[newEducation.index].index;
            formik.setFieldValue("education", [...currentEducationArray]);
         } else {
            formik.setFieldValue("education", [...formik.values.education, newEducation]);
         }
      }

      function handleRemoveEducation(educationIndex) {
         const allEducation = formik.values.education;
         allEducation.splice(educationIndex, 1);
         formik.setFieldValue("education", [...allEducation]);
      }

      function handleEditEducation(educationIndex) {
         let editedEducation = formik.values.education[educationIndex];
         editedEducation.index = educationIndex;
         setEditedEducation(editedEducation);
      }

      function removeProfileCityFromSelected(value) {
         let filterSelectedValues = profileCity.filter((selectedValue) => {
            return selectedValue.name !== value.name
         });

         formik.setFieldValue("address.profileCity", filterSelectedValues);
         setProfileCity(filterSelectedValues);
      }

      function removeAreasProfileLevel1FromSelected(value) {
         let filterSelectedValues = areaProfileLevel1.filter((selectedValue) => {
            return selectedValue.name !== value.name
         });

         formik.setFieldValue("address.administrative_area_level_1", filterSelectedValues);
         setProfileAreaLevel1(filterSelectedValues);
      }

      const formik = useFormik({
         initialValues: ProfilePersonInitialValues,
         validationSchema: ProfilePersonSchema,
         onSubmit: (values) => {
            // próbujemy się rejestrować
            props.saveProfile(values).then(
               () => { },
               (errors) => {
                  // nie udało się to podstawiamy błędy
                  setApiErrors(errors);
               });
         }
      });

      return (
         <form onSubmit={formik.handleSubmit}>
            <ContentnavComponent />
            <section id="myProfile" className="my-profile__content">
               <div className="container-fluid">
                  <div className="row justify-content-center">
                     <div className="col-12 col-xl-8 mb-5">
                        <div className="my-profile__info bg-white p-5">
                           <div className="row mb-4">
                              <div className="col-12">
                                 <h3 className="mb-4">Dane osobowe<span className="required"> *</span></h3>
                              </div>
                              {/* IMIE */}
                              <div className={`col-12 col-md-6 ${(formik.errors.name && formik.touched.name) || (errors && errors.name) ? 'input-error' : ''} material-input mb-5`}>
                                 <div className="my-profile__name d-flex align-items-center position-relative">
                                    <label className="ml-5">Imię<span className="required">  *</span></label>
                                    <input id="name"
                                       name="name"
                                       type="text"
                                       autoComplete="off"
                                       onChange={formik.handleChange}
                                       value={formik.values.name}
                                       className="material-input__input h-100"
                                       onFocus={() => handleFocusInputs()} />
                                 </div>
                                 {formik.errors.name && formik.touched.name ? (
                                    <div className="material-input__error">{formik.errors.name}</div>
                                 ) : null}
                                 {errors && errors.name ? (
                                    <div className="material-input__error">{errors.name}</div>
                                 ) : null}
                              </div>
                              {/* NAZWISKO */}
                              <div className={`my-profile__last-name col-12 ${(formik.errors.surname && formik.touched.surname) || (errors && errors.surname) ? 'input-error' : ''} col-md-6 material-input mb-5`}>
                                 <div className="d-flex align-items-center position-relative">
                                    <label className="ml-5">Nazwisko<span className="required"> *</span></label>
                                    <input id="surname"
                                       name="surname"
                                       type="text"
                                       autoComplete="off"
                                       onChange={formik.handleChange}
                                       value={formik.values.surname}
                                       className="material-input__input h-100" />
                                 </div>
                                 {formik.errors.surname && formik.touched.surname ? (
                                    <div className="material-input__error">{formik.errors.surname}</div>
                                 ) : null}
                                 {errors && errors.surname ? (
                                    <div className="material-input__error">{errors.surname}</div>
                                 ) : null}
                              </div>
                              {/* EMAIL */}
                              <div className={`my-profile__mail col-12 ${(formik.errors.email && formik.touched.email) || (errors && errors.email) ? 'input-error' : ''} col-md-6 material-input mb-5`}>
                                 <div className="d-flex align-items-center position-relative">
                                    <label className="ml-5">E-mail<span className="required"> *</span></label>
                                    <input id="email"
                                       name="email"
                                       type="email"
                                       placeholder="Uzupełnij pole"
                                       autoComplete="off"
                                       onChange={formik.handleChange}
                                       value={formik.values.email}
                                       className="material-input__input h-100" />
                                 </div>
                                 {formik.errors.email && formik.touched.email ? (
                                    <div className="material-input__error">{formik.errors.email}</div>
                                 ) : null}
                                 {errors && errors.email ? (
                                    <div className="material-input__error">{errors.email}</div>
                                 ) : null}
                              </div>
                              {/* TELEFON */}
                              <div className={`my-profile__phone col-12 ${(formik.errors.phone && formik.touched.phone) || (errors && errors.phone) ? 'input-error' : ''} col-md-6 material-input mb-4`}>
                                 <div className="d-flex align-items-center position-relative">
                                    <label className="ml-5">Telefon</label>
                                    <input id="phone"
                                       name="phone"
                                       autoComplete="new-password"
                                       value={formik.values.phone}
                                       onChange={formik.handleChange}
                                       className="material-input__input h-100" />
                                 </div>
                                 {formik.errors.phone && formik.touched.phone ? (
                                    <div className="material-input__error">{formik.errors.phone}</div>
                                 ) : null}
                                 {errors && errors.phone ? (
                                    <div className="material-input__error">{errors.phone}</div>
                                 ) : null}
                              </div>
                              {/* WOJEWODZTWO */}
                              <div className={`col-12 material-input mb-4 
                                  ${(formik.errors.address && formik.touched.address && formik.errors.address.administrative_area_level_1) ||
                                    (errors && errors.address && errors.address.administrative_area_level_1) ? 'input-error' : ''}`}>
                                 {/* WYBRANE WOJEWODZTWA */}
                                 <div className="row no-gutters mb-4">
                                    {areaProfileLevel1 && areaProfileLevel1.length > 0 && areaProfileLevel1.map((areaProfileLevel1, index) => {
                                       return <div className="col-auto" key={'tag-' + index}>
                                          <button
                                             className="btn btn--filter mr-4 mt-4 filter-result__single"
                                             onClick={() => removeAreasProfileLevel1FromSelected(areaProfileLevel1)}>{areaProfileLevel1.name}
                                             <span className="ml-5"><img src={CloseIcon} alt="" /></span>
                                          </button>
                                       </div>
                                    })}
                                 </div>
                                 <AutocompleteComponent
                                    state={areaProfileLevel1}
                                    setState={setProfileAreaLevel1}
                                    options={props.getAllAdministrativeAreaLevel1()}
                                    value={formik.values.address.administrative_area_level_1}
                                    handleOnSelect={(values) => formik.setFieldValue("address.administrative_area_level_1", values.length > 0 ? values[0].value : "")}
                                    placeholder="Województwo"
                                    isRequired={true}
                                    handleFocusInputs={() => handleFocusInputs()} />
                                 {formik.errors.address && formik.touched.address && formik.errors.address.administrative_area_level_1 ? (
                                    <div className="material-input__error">{formik.errors.address.administrative_area_level_1}</div>
                                 ) : null}
                                 {errors && errors.address && errors.address.administrative_area_level_1 ? (
                                    <div className="material-input__error">{errors.address.administrative_area_level_1}</div>
                                 ) : null}
                              </div>
                              {/* MIASTO */}
                              <div className={`col-12 material-input ${(formik.errors.address && formik.touched.address && formik.errors.address.city) ||
                                 (errors && errors.address && errors.address.city) ? 'input-error' : ''}`}>
                                 {/* WYBRANE MIASTA */}
                                 <div className="row no-gutters mb-4">
                                    {profileCity && profileCity.length > 0 && profileCity.map((city, index) => {
                                       return <div className="col-auto" key={'tag-' + index}>
                                          <button
                                             type="button"
                                             className="btn btn--filter mr-4 mt-4 filter-result__single"
                                             onClick={() => removeProfileCityFromSelected(city)}>{city.name}
                                             <span className="ml-5"><img src={CloseIcon} alt="" /></span>
                                          </button>
                                       </div>
                                    })}
                                 </div>
                                 <AutocompleteComponent
                                    state={profileCity}
                                    setState={setProfileCity}
                                    options={[]}
                                    getOptionsMethod={props.getPlaces}
                                    value={formik.values.address.city}
                                    handleOnSelect={(values) => formik.setFieldValue("address.city", values.length > 0 ? values[0].value : "")}
                                    placeholder="Miasto"
                                    isRequired={true}
                                    handleFocusInputs={() => handleFocusInputs()}
                                 />
                                 {formik.errors.address && formik.touched.address && formik.errors.address.city ? (
                                    <div className="material-input__error">{formik.errors.address.city}</div>
                                 ) : null}
                                 {errors && errors.address && errors.address.city ? (
                                    <div className="material-input__error">{errors.address.city}</div>
                                 ) : null}
                              </div>
                           </div>
                        </div>
                        <div className="my-profile__info bg-white p-5 mt-4">
                           {/* JEZYKI */}
                           <div className="row">
                              <div className="col-12">
                                 <h3 className="mb-4">Znajomość języków</h3>
                              </div>
                              <div className="col-12 mb-4">
                                 <div className="row no-gutters">
                                    {formik.values.languages && formik.values.languages.map((language, index) => {
                                       return <div className="col-auto" key={'language-' + index}>
                                          <button
                                             className="btn btn--filter mr-4 mt-4 filter-result__single"
                                             onClick={() => handleRemoveLanguage(language)}>{language}
                                             <span className="ml-5"><img src={CloseIcon} alt="" /></span>
                                          </button>
                                       </div>
                                    })}
                                 </div>
                              </div>
                              <LanguageSelector
                                 errors={errors}
                                 setErrors={setApiErrors}
                                 isRequired={false}
                                 handleSelectLanguage={handleSelectLanguage}
                                 handleFocusInputs={() => handleFocusInputs()} />
                           </div>
                        </div>
                        <div className="my-profile__info bg-white p-5 mt-4">
                           {/* WYKSZTAŁCENIE */}
                           <div className="row">
                              <div className="col-12">
                                 <h3 className="mb-4">Wykształcenie</h3>
                              </div>
                              <div className="row col-12 mb-5">
                                 {formik.values.education && formik.values.education.map((singleEducation, index) => {
                                    return <Col key={`education-card-${index}`} sm={12} md={6} lg={6} xl={4} className="single-education-wrapper">
                                       <div className={`single-education-card ${editedEducation && editedEducation.index === index ? 'active' : ''}`}>
                                          <div onClick={() => handleEditEducation(index)} className="single-education-edit-button">
                                             {editButton}
                                          </div>
                                          <div onClick={() => handleRemoveEducation(index)} className="single-education-remove-button">
                                             {clearButton}
                                          </div>
                                          <div><span className="education-field">Wykształcenie:</span> <span className="education-value">{singleEducation.degree}</span></div>
                                          <div><span className="education-field">Nazwa szkoły:</span> <span className="education-value">{singleEducation.schoolName}</span></div>
                                          <div><span className="education-field">Specjalizacja / kierunek:</span> <span className="singleEducationeducation-value">{singleEducation.major}</span></div>
                                       </div>
                                    </Col>
                                 })}
                              </div>
                              <AddEducationComponent
                                 errors={errors}
                                 setErrors={setApiErrors}
                                 editedEducation={editedEducation}
                                 setEditedEducation={setEditedEducation}
                                 handleAddEducation={handleAddEducation}
                                 handleFocusInputs={() => handleFocusInputs()} />
                           </div>
                        </div>

                        <div className="my-profile__info bg-white p-5 mt-4">
                           {/* CV */}
                           <div className="row">
                              <div className="col-12">
                                 <h3 className="mb-4">Moje CV</h3>
                              </div>
                              <input id='input-cv-file' type="file" onChange={uploadCV} ref={fileRef} />

                              {cvFileName && <div className="col-12 col-lg-4 mb-4">
                                 {cvFileName && <div className="cv-text-wrapper">
                                    <span>{cvFileName}</span>
                                 </div>}
                              </div>}

                              {!cvFileName && <div className="col-12">
                                 <div className="row justify-content-center">
                                    <div className="col-12 col-lg-5 d-flex justify-content-center">
                                       <button type="button" className="btn btn--grey w-100 h-100 font-weight-600" onClick={() => fileRef.current.click()}>
                                          Dodaj CV
                                       </button>
                                    </div>
                                 </div>
                              </div>}

                              {cvFileName && <div className="col-12 col-lg-4 mb-4">
                                 <button type="button" className="btn btn--grey w-100 h-100 font-weight-600" onClick={() => downloadCv(cvFileName)}>
                                    Pobierz
                                 </button>
                              </div>}

                              {cvFileName && <div className="col-12 col-lg-4 mb-4">
                                 <button type="button" className="btn btn--grey w-100 h-100 font-weight-600" onClick={deleteCV}>
                                    Usuń
                                 </button>
                              </div>}
                           </div>
                        </div>

                        <div className="my-profile__info bg-white p-5 mt-4">
                           {/* USUN KONTO */}
                           <div className="row justify-content-md-end justify-content-center">
                              <div className="col-12 col-lg-3 d-flex justify-content-center">
                                 <button
                                    type="button"
                                    id="myProfileDelete"
                                    className="btn btn--secondary w-100"
                                    data-toggle="modal"
                                    onClick={toggleModal}>Usuń konto</button>
                              </div>
                           </div>

                           <div className="row mt-4 justify-content-center">
                              <div className="col-12 col-lg-5">
                                 <button id="myProfileSave" className="btn btn--primary w-100">Zapisz</button>
                              </div>
                           </div>
                        </div>

                     </div>
                  </div>
               </div>
            </section>
            <ConfirmModalComponent
               show={showModal}
               handleSubmit={handleDeleteAccount}
               handleToggleModal={toggleModal}
               contentText="Czy na pewno chcesz usunuąć konto? Operacja jest procesem nieodwracalnym."
               submitText="TAK" />
         </form >
      )
   };

   return <Profile {...props} />
}

export default PersonProfileComponent;
