import React from 'react';
import LoginInComponent from '../components/loginIn.component';

import { connect } from 'react-redux'
import { loginIn, checkToken } from '../actions/auth.action';

function LoginInContainer(props) {
    return (
        <LoginInComponent {...props} />
    );
}

const mapDispatchToProps = (dispatch) => {
    return {
        loginIn: (loginData) => loginIn(loginData, dispatch),
        checkToken: (token) => checkToken(token, dispatch)
    }
};

export default connect(null, mapDispatchToProps)(LoginInContainer);
