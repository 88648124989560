import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useFormik } from 'formik';
import { ContactSchema } from '../schemas/contact.schema';

import CloseIcon from '../../application/assets/img/close.svg';

import '../assets/contactModal.css';

function ContactModalComponent(props) {

    function handleFocusInputs() {
        let materialInput = document.querySelectorAll('.material-input');

        if (materialInput) {
            materialInput.forEach(function (e) {
                let input = e.querySelector('.material-input__input');
                let label = e.querySelector('label');

                if (input && label) {
                    input.addEventListener('focusin', (event) => {
                        label.classList.add('float-label');
                    })

                    input.addEventListener('focusout', (event) => {
                        if (input.value === "") {
                            label.classList.remove('float-label');
                        }
                    })
                }

            })
        }
    }

    function handleCloseContactModal() {
        let singleCardForm = document.getElementById('singleCardForm');
        let singleCardFormClose = document.getElementById('singleCardFormClose');

        if (singleCardFormClose) singleCardForm.classList.remove('active')
    }

    const Contact = () => {

        const [errors, setApiErrors] = useState(null);

        const formik = useFormik({
            initialValues: {
                recordId: '',
                recordType: '',
                contact: '',
                message: '',
                regulationsAcceptance: false
            },
            validationSchema: ContactSchema,
            onSubmit: (values, actions) => {
                values.recordId = props.recordId;
                values.recordType = props.recordType;

                // próbujemy się rejestrować
                props.sendContactMail(values).then(
                    () => {
                        // jak się uda to spadamy stąd
                        props.toggleModal();
                    },
                    (errors) => {
                        // nie udało się to podstawiamy błędy
                        setApiErrors(errors);
                    });
            }
        });

        return (
            <form className="row justify-content-center" onSubmit={formik.handleSubmit}>
                <div className={`col-12 material-input ${(formik.errors.contact && formik.touched.contact) || (errors && errors.contact) ? " input-error" : ""} mb-4`}>
                    <div className="d-flex align-items-center">
                        <label className="ml-5">E-mail / Telefon<span className="required"> *</span></label>
                        <input id="contact"
                            name="contact"
                            type="text"
                            onChange={formik.handleChange}
                            value={formik.values.contact}
                            className="material-input__input h-100"
                            onFocus={() => handleFocusInputs()} />
                    </div>
                    {formik.errors.contact && formik.touched.contact ? (
                        <div className="material-input__error">{formik.errors.contact}</div>
                    ) : null}
                    {errors && errors.contact ? (
                        <div className="material-input__error">{errors.contact}</div>
                    ) : null}
                </div>
                <div className={`col-12 material-input ${(formik.errors.message && formik.touched.message) || (errors && errors.message) ? " input-error" : ""} material-textarea mb-4`}>
                    <div>
                        <label className="ml-5">Wiadomość<span className="required"> *</span></label>
                        <textarea id="message"
                            name="message"
                            as="textarea"
                            rows="3"
                            onChange={formik.handleChange}
                            value={formik.values.message}
                            className="material-input__input h-100"></textarea>
                    </div>
                    {formik.errors.message && formik.touched.message ? (
                        <div className="material-input__error">{formik.errors.message}</div>
                    ) : null}
                    {errors && errors.message ? (
                        <div className="material-input__error">{errors.message}</div>
                    ) : null}
                </div>
                <div className="col-auto text-center">
                    <button className="btn btn--primary" type="submit">Skontaktuj się</button>
                </div>
            </form>
        );
    };

    return (
        <div id="singleCardForm" className="single-card__form p-5">
            <div className="row justify-content-center">
                <div className="col-12">
                    <span id="singleCardFormClose" className="cursor-pointer single-card__form-close">
                        <img src={CloseIcon} alt="" onClick={() => handleCloseContactModal()} />
                    </span>
                    <h4 className="mb-4">{props.modalTitle}</h4>
                    <p className="mb-5 text--light-grey">
                        Możesz skontaktować się z wizytówką tylko jeśli Twój adres e-mail widnieje w naszej bazie. Jeśli Cię w niej nie ma <a href="/register">zarejestruj się</a> :)
                    </p>
                </div>
                <Contact />
            </div>
        </div>
    )
}

ContactModalComponent.propTypes = {
    show: PropTypes.bool.isRequired,
    toggleModal: PropTypes.func.isRequired,
    modalTitle: PropTypes.string
};

export default ContactModalComponent;
