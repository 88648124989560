
import React, { useState } from 'react';
import {
    useParams as navigateParam,
    Link,
    useLocation
} from "react-router-dom";

import CardsAcceptanceContainer from '../containers/cardsAcceptance.container';
import OffersAcceptanceContainer from '../containers/offersAcceptance.container';
import UsersContainer from '../containers/users.container';

import '../assets/acceptance.css';

function AdcceptanceComponent(props) {
    const location = useLocation();
    const { selectedTabParam } = navigateParam();
    const [query, setQuery] = useState("");
    const [selectedTab, setSelectedTab] = useState(selectedTabParam ? selectedTabParam : "cards");

    function handleSearchRecords(query) {
        setQuery(query);
        props.searchRecords(query, selectedTab);
    }

    function handleSelectTab(tab) {
        setSelectedTab(tab);
        setQuery("");
        props.searchRecords(null, tab);
    }

    function getCurrentComponent() {
        if (selectedTab === "cards") {
            return <CardsAcceptanceContainer
                handleSearchRecords={(val) => handleSearchRecords(val)}
                query={query} />
        }
        if (selectedTab === "offers") {
            return <OffersAcceptanceContainer
                handleSearchRecords={(val) => handleSearchRecords(val)}
                query={query} />
        }
        if (selectedTab === "users") {
            return <UsersContainer
                handleSearchRecords={(val) => handleSearchRecords(val)}
                query={query} />
        }
    }

    return (
        <div className="admin">
            <section id="adminTabs" className="admin-tabs bg--primary pt-5">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-12">
                            <ul className="nav nav-pills mb-3 justify-content-center" id="pills-tab" role="tablist">
                                <li className="nav-item mr-5" onClick={() => handleSelectTab("cards")}>
                                    <Link to={location.pathname} className={`nav-link ${selectedTab === "cards" ? "active" : ""}`} id="pills-offers-tab">Wizytówki</Link>
                                </li>
                                <li className="nav-item mr-5" onClick={() => handleSelectTab("offers")}>
                                    <Link to={location.pathname} className={`nav-link ${selectedTab === "offers" ? "active" : ""}`} id="pills-offers-tab">Oferty</Link>
                                </li>
                                <li className="nav-item" onClick={() => handleSelectTab("users")}>
                                    <Link to={location.pathname} className={`nav-link ${selectedTab === "users" ? "active" : ""}`} id="pills-users-tab">Użytkownicy</Link>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </section>
            {getCurrentComponent()}
        </div>
    )
}

export default AdcceptanceComponent;