import * as Yup from 'yup';

export const ProfilePersonInitialValues = {
    name: '',
    surname: '',
    email: '',
    phone: '',
    address: {
        administrative_area_level_1: '',
        city: ''
        // street: '',
        // streetNumber: '',
    },
    languages: [],
    education: [],
    cv: ''
}

export const ProfilePersonSchema = Yup.object().shape({
    name: Yup.string()
        .required('Wartość jest wymagana'),
    surname: Yup.string()
        .required('Wartość jest wymagana'),
    email: Yup.string()
        .required('Wartość jest wymagana'),
    phone: Yup.string(),
    address: Yup.object().shape( // adress osoby OBIEKT
        {
            administrative_area_level_1: Yup.string().required('Wartość jest wymagana'),
            city: Yup.string().required('Wartość jest wymagana'),
            // street: Yup.string(),
            // streetNumber: Yup.string()
        }
    ),
    languages: Yup.array(), // umiejętności TABLICA STRINGÓW
        // .required('Wartość jest wymagana. Po wybraniu języka kliknij "dodaj wybrany język"'),
    education: Yup.array().of( // Wykształcenie TABLICA OBIEKTÓW
        Yup.object().shape({
            degree: Yup.string(),
            schoolName: Yup.string(),
            major: Yup.string() // specjalizacja
            // dateFrom: Yup.string().required('Wartość jest wymagana'),
            // dateTo: Yup.string().required('Wartość jest wymagana'),
            // untilNow: Yup.boolean().required('Wartość jest wymagana'), // do teraz
            // description: Yup.string(),
        })
    ),
    cv: Yup.string()
});

export const ProfileCompanyInitialValues = {
    companyName: '',
    NIP: '',
    email: '',
    phone: '',
    address: {
        administrative_area_level_1: '',
        city: '',
        street: '',
        streetNumber: '',
    },
}

export const ProfileCompanySchema = Yup.object().shape({
    companyName: Yup.string()
        .required('Wartość jest wymagana'),
    NIP: Yup.string(),
    email: Yup.string()
        .required('Wartość jest wymagana'),
    phone: Yup.string(),
    address: Yup.object().shape(
        {
            administrative_area_level_1: Yup.string().required('Wartość jest wymagana'),
            city: Yup.string().required('Wartość jest wymagana'),
            street: Yup.string().required('Wartość jest wymagana'),
            streetNumber: Yup.string().required('Wartość jest wymagana')
        }
    )
});


