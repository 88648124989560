import React from 'react';
import { connect } from 'react-redux';

import { getUsers, clearUsers, activateUser, sendActivateMail } from '../actions/admin.action';
import UsersComponent from '../components/users.component';

function UsersContainer(props) {
    return (
        <UsersComponent {...props} />
    );
}

const mapStateToProps = (state) => {
    return {
        users: state.admin.users
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        getUsers: () => dispatch(getUsers()),
        clearUsers: () => dispatch(clearUsers()),
        activateUser: (id) => dispatch(activateUser(id)),
        sendActivateMail: (email) => dispatch(sendActivateMail(email))
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(UsersContainer);
