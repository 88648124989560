import React from 'react';
import { connect } from 'react-redux';
import { setMatches } from "../../home/actions/home.action";
import { getOffers, changeStatus, deleteOffer, refreshOffer, clearOffers } from '../actions/offer.action';

import OffersComponent from '../components/offers.component';

function OffersContainer(props) {
    return (
        <OffersComponent {...props} />
    );
}

const mapStateToProps = (state) => {

    return {
        offers: state.panelOffers.offers,
        isLoading: state.app.isLoading
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        getOffers: () => dispatch(getOffers()), // jak nie potrzebujemy promise
        deleteOffer: (offerId) => dispatch(deleteOffer(offerId)), // jak nie potrzebujemy promise
        refreshOffer: (offerId) => dispatch(refreshOffer(offerId)), // jak nie potrzebujemy promise
        changeStatus: (offerId, isActive) => dispatch(changeStatus(offerId, isActive)),  // jak nie potrzebujemy promise
        clearOffers: () => dispatch(clearOffers()), // jak nie potrzebujemy promise
        setMatches: (matches, type) => dispatch(setMatches(matches, type))
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(OffersContainer);
