import React from 'react';
import { connect } from 'react-redux'
import { getAllAdministrativeAreaLevel1, getPlaces } from '../../application/actions/app.action';
import { saveOffer, getOffer, clearOffer } from '../actions/offer.action';
import OfferComponent from '../components/offer.component';

function OfferContainer(props) {

    return (
        <OfferComponent {...props} />
    );
}

const mapStateToProps = (state) => {

    return {
        offer: state.panelOffer.offer,
        categoryTree: state.app.categoryTree
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        saveOffer: (offerData) => saveOffer(offerData, dispatch), // jak potrzebujemy promise
        getOffer: (offerId) => dispatch(getOffer(offerId)), // jak nie potrzebujemy promise
        clearOffer: () => dispatch(clearOffer()), // jak nie potrzebujemy promise,
        getAllAdministrativeAreaLevel1: () => getAllAdministrativeAreaLevel1(),
        getPlaces: (query, token) => getPlaces('cities', query, token),
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(OfferContainer);
