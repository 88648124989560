import React from 'react';
import { connect } from 'react-redux';

import { getCards, clearCards, cardAcceptance } from '../actions/admin.action';
import CardsAcceptanceComponent from '../components/cardsAcceptance.component';

function CardsAcceptanceContainer(props) {
    return (
        <CardsAcceptanceComponent {...props} />
    );
}

const mapStateToProps = (state) => {
    return {
        cards: state.admin.cards,
        isLoading: state.app.isLoading
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        getCards: () => dispatch(getCards()),
        clearCards: () => dispatch(clearCards()),
        cardAcceptance: (cardId, data) => dispatch(cardAcceptance(cardId, data))
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(CardsAcceptanceContainer);
