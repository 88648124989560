import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { logout } from '../../auth/actions/auth.action';
import { Link, useHistory, useLocation } from "react-router-dom";
import { Nav } from 'react-bootstrap';
import Logo from '../assets/img/logo.svg';
import Facebook from '../assets/img/fb.png';
import Linkedin from '../assets/img/linkedin.png';
import HelpdeskModalComponent from './helpdeskModal.component';

function Header(props) {
    const { loggedUser, logout } = props;
    const [showHelpdeskModal, setShowHelpdeskModal] = useState(false);

    const isMobile = window.innerWidth < 768 ? true : false;

    let currentRoute = useLocation().pathname;
    if (currentRoute.indexOf("kandydaci") > -1 || currentRoute.indexOf("oferty") > -1) {
        currentRoute = "/";
    }
    const history = useHistory();

    const [isLogged, setIsLogged] = useState(false);
    const [isExpanded, setIsExpanded] = useState(false);

    useEffect(() => {
        setIsLogged(loggedUser);
    }, [loggedUser]);

    function handleLogout() {
        logout();
        history.push('/login');
    }

    function setNavExpanded() {
        if (isMobile) {
            setIsExpanded(!isExpanded);
        }
    }

    function toggleHelpdeskModal() {
        setShowHelpdeskModal(!showHelpdeskModal);
    }

    function toggleMenu(e) {
        e.preventDefault();
        let burger = document.querySelector('.hamburger');
        let mobileInner = document.querySelector('.header-mobile__inner');

        if (burger && mobileInner) {
            burger.classList.toggle("is-active");
            mobileInner.classList.toggle("show-menu");
        }
    }

    const MenuList = () => {
        return <Nav className="nav">
            {/* STRONA GŁOWNA */}
            <li className={`nav-item header__menu__item ${currentRoute === '/' ? 'active' : ''}`} onClick={setNavExpanded}>
                <Link className="nav-link" to="/kandydaci">Strona główna</Link>
            </li>
            {/* ZALOGUJ SIE */}
            {!isLogged && <li className={`nav-item header__menu__item ${currentRoute === '/login' ? 'active' : ''}`} onClick={setNavExpanded}>
                <Link className="nav-link" onClick={setNavExpanded} to="/login">Zaloguj się</Link>
            </li>}
            {/* REJESTRACJA */}
            {!isLogged && <li className={`nav-item header__menu__item ${currentRoute === '/register' ? 'active' : ''}`} onClick={setNavExpanded}>
                <Link className="nav-link" to="/register">Rejestracja</Link>
            </li>}
            {/* DODAJ WIZYTOWKE */}
            {isLogged && loggedUser && loggedUser.profileCompleted && !loggedUser.isAdmin && loggedUser.type === "person" && <li
                className={`nav-item header__menu__item ${currentRoute === '/card' ? 'active' : ''}`} onClick={setNavExpanded}>
                <Link className="nav-link" to="/card">Dodaj wizytówkę</Link>
            </li>}
            {/* MOJE WIZYTOWKI */}
            {isLogged && loggedUser && loggedUser.profileCompleted && !loggedUser.isAdmin && loggedUser.type === "person" && <li
                className={`nav-item header__menu__item ${currentRoute === '/cards' ? 'active' : ''}`} onClick={setNavExpanded}>
                <Link className="nav-link" to="/cards">Moje wizytówki</Link>
            </li>}
            {/* DODAJ OFERTE */}
            {isLogged && loggedUser && loggedUser.profileCompleted && !loggedUser.isAdmin && loggedUser.type === "company" && <li
                className={`nav-item header__menu__item ${currentRoute === '/offer' ? 'active' : ''}`} onClick={setNavExpanded}>
                <Link className="nav-link" to="/offer">Dodaj ofertę</Link>
            </li>}
            {/* MOJE OFERTY */}
            {isLogged && loggedUser && loggedUser.profileCompleted && !loggedUser.isAdmin && loggedUser.type === "company" && <li
                className={`nav-item header__menu__item ${currentRoute === '/offers' ? 'active' : ''}`} onClick={setNavExpanded}>
                <Link className="nav-link" to="/offers">Moje oferty</Link>
            </li>}
            {/* Profil */}
            {isLogged && loggedUser && !loggedUser.isAdmin && <li
                className={`nav-item header__menu__item ${currentRoute === '/profile' ? 'active' : ''}`} onClick={setNavExpanded}>
                <Link className="nav-link" to="/profile">Profil</Link>
            </li>}
            {/* ADMINISTRACJA */}
            {isLogged && loggedUser && loggedUser.isAdmin && <li
                className={`nav-item header__menu__item ${currentRoute === '/admin' ? 'active' : ''}`} onClick={setNavExpanded}>
                <Link className="nav-link" to="/admin">Administracja</Link>
            </li>}
            {/* ZGŁOS PROBLEM */}
            <li className={`nav-item header__menu__item`} onClick={setNavExpanded}>
                <Link className="nav-link" to={window.location.pathname} onClick={toggleHelpdeskModal}>Zgłoś problem</Link>
            </li>
            {/* WYLOGUJ */}
            {isLogged && <li className={`nav-item header__menu__item`} onClick={setNavExpanded}>
                <Nav.Link className="nav-link" onClick={() => handleLogout()}>Wyloguj</Nav.Link>
            </li>}
        </Nav>
    }

    const MobileHeader = () => {
        return <header id="headerMobile" className="header-mobile header py-4 bg-white d-block d-xl-none">
            <div className="container-fluid">
                <div className="row justify-content-between">
                    <div className="col-auto">
                        <div className="header__logo">
                            <Link className="navbar-brand" to="/kandydaci">
                                <img src={Logo} alt="" />
                            </Link>
                        </div>
                    </div>
                    <div className="hamburger hamburger--squeeze position-fixed bg--secondary" onClick={toggleMenu}>
                        <div className="hamburger-box">
                            <div className="hamburger-inner"></div>
                        </div>
                    </div>
                    <div className="header-mobile__inner position-fixed bg--secondary pb-5" onClick={toggleMenu}>
                        <div className="header-mobile__hidden no-gutters">
                            <div className="header__menu">
                                <MenuList />
                            </div>
                            <a href="https://www.facebook.com/Beemployeepl-106968494360330/?ref=py_c" target="__blank" className="btn btn--social mr-4 mt-4 d-inline-flex d-xl-flex">
                                <img src={Facebook} alt="" />
                            </a>
                            <a href="https://pl.linkedin.com/showcase/beemployee-pl" target="__blank" className="btn btn--social mt-4 d-inline-flex d-xl-flex"><img src={Linkedin} alt="" /></a>
                        </div>
                    </div>
                </div>
            </div>
        </header>
    }

    return (
        <div>
            <header id="header" className="header py-4 bg-white d-xl-block d-none">
                <div className="container-fluid">
                    <div className="row justify-content-between">
                        <div className="col-auto">
                            <div className="header__logo">
                                <Link className="navbar-brand" to="/kandydaci">
                                    <img src={Logo} alt="" />
                                </Link>
                            </div>
                        </div>
                        <div className="col-auto d-flex align-items-center">
                            <div className="header__menu">
                                <MenuList />
                            </div>
                            <a href="https://www.facebook.com/Beemployeepl-106968494360330/?ref=py_c" target="__blank" className="btn btn--social mr-4"><img src={Facebook} alt="" /></a>
                            <a href="https://pl.linkedin.com/showcase/beemployee-pl" target="__blank" className="btn btn--social"><img src={Linkedin} alt="" /></a>
                        </div>
                    </div>
                </div>
            </header>
            <MobileHeader />
            <HelpdeskModalComponent show={showHelpdeskModal} toggleModal={toggleHelpdeskModal} />
        </div >
    )

}

const mapStateToProps = state => ({
    cardCount: state.home.cardCount,
    offerCount: state.home.offerCount,
    loggedUser: state.auth.loggedUser,
    isLoading: state.app.isLoading,
    listType: state.home.listType,
});

const mapDispatchToProps = dispatch => {
    return {
        logout: () => dispatch(logout()),
        // setListType: (listType) => dispatch(setListType(listType))
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(Header);
