import { SET_OFFERS, CLEAR_OFFERS } from '../actions/offer.action';

const initial_state = {
    offers: [],
    total: 0
}

const offers = (state = initial_state, action) => {
    switch (action.type) {
        case SET_OFFERS:
            return { ...state, offers: action.offers, total: action.total };
        case CLEAR_OFFERS:
            return { ...state, offers: [], total: 0 };
        default:
            return state;
    }
};

export default offers;