import * as Yup from 'yup';

export const OfferInitialState = {
    categories: [],
    companyDescription: '',
    address: {
        cities: [],
        administrative_areas_level_1: [],
        wholeCountry: false,
        outsiteCountry: false
    },
    position: '',
    positionDescription: '',
    typeOfContract: '',
    requirements: '',
    benefits: '',
    requiredSkills: [],
    languages: [],
    recruiter: {
        name: '',
        surname: '',
        contact: ''
    }
}

export const OfferSchema = Yup.object().shape({
    categories: Yup.array() // kategoria oferty
        .required('Wartość jest wymagana'),
    companyDescription: Yup.string().test("len", "Wartość jest wymagana", val => { // .min(100, 'Minimalna ilość znaków opisu to 100')
        return getLengthFromDescription("companyDescription") === 0 ? false : true;
    }).test("len", "Maksymalna ilość znaków opisu to 4000", val => { // .max(4000, 'Minimalna ilość znaków opisu to 4000')
        return getLengthFromDescription("companyDescription") < 4000;
    }),
    address: Yup.object().shape(
        {
            wholeCountry: Yup.boolean(), // czy oferta obejmuje cały kraj? jeżeli zaznaczone to nie wymagany adres !
            administrative_areas_level_1: Yup.array().when('wholeCountry', {
                is: value => !!value === false,
                then: Yup.array().when('outsiteCountry', {
                    is: value => !!value === false,
                    then: Yup.array().required(
                        'Wartość jest wymagana'
                    ),
                    otherwise: Yup.array()
                }),
                otherwise: Yup.array()
            }),
            cities: Yup.array().when('wholeCountry', {
                is: value => !!value === false,
                then: Yup.array().when('outsiteCountry', {
                    is: value => !!value === false,
                    then: Yup.array().required(
                        'Wartość jest wymagana'
                    ),
                    otherwise: Yup.array()
                }),
                otherwise: Yup.array()
            }),
        }
    ),
    position: Yup.string() // stanowisko oferty
        .required('Wartość jest wymagana'),
    positionDescription: Yup.string().test("len", "Wartość jest wymagana", val => { // .min(100, 'Minimalna ilość znaków opisu to 100')
        return getLengthFromDescription("positionDescription") === 0 ? false : true;
    }).test("len", "Maksymalna ilość znaków opisu to 4000", val => { // .max(4000, 'Minimalna ilość znaków opisu to 4000')
        return getLengthFromDescription("positionDescription") < 4000;
    }),
    typeOfContract: Yup.string() // stanowisko oferty
        .required('Wartość jest wymagana'),
    requirements: Yup.string().test("len", "Wartość jest wymagana", val => { // .min(100, 'Minimalna ilość znaków opisu to 100')
        return getLengthFromDescription("requirements") === 0 ? false : true;
    }).test("len", "Maksymalna ilość znaków opisu to 1000", val => { // .max(1000, 'Minimalna ilość znaków opisu to 1000')
        return getLengthFromDescription("requirements") < 1000;
    }),
    benefits: Yup.string().test("len", "Wartość jest wymagana", val => { // .min(100, 'Minimalna ilość znaków opisu to 100')
        return getLengthFromDescription("benefits") === 0 ? false : true;
    }).test("len", "Maksymalna ilość znaków opisu to 1000", val => { // .max(1000, 'Minimalna ilość znaków opisu to 1000')
        return getLengthFromDescription("benefits") < 1000;
    }),
    requiredSkills: Yup.array() // umiejętności
        .required('Wartość jest wymagana'),
    languages: Yup.array() // umiejętności
        .required('Wartość jest wymagana'),
    recruiter: Yup.object().shape( // informacje o osobie rekrutującej
        {
            name: Yup.string(),
            surname: Yup.string(),
            contact: Yup.string().required('Wartość jest wymagana')
        })
});


function getLengthFromDescription(className) {
    let descriptionLength = 0;
    [...document.querySelectorAll(`.${className} span[data-text]`)].map((element) => {
        return descriptionLength += element.innerHTML.length;
    });
    return descriptionLength;
}