import { SET_CARD, CLEAR_CARD } from '../actions/card.action';

const initial_state = {
    card: []
}

const card = (state = initial_state, action) => {
    switch (action.type) {
        case SET_CARD:
            return { ...state, card: action.card };
        case CLEAR_CARD:
            return { card: [] };
        default:
            return state;
    }
};

export default card;