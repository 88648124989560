
import React from 'react';
import { connect } from 'react-redux';

import { getOfferDetails, clearOfferDetails,offerAcceptance } from '../actions/admin.action';
import OfferDetailsComponent from '../components/offerDetails.component';

function OfferDetailsContainer(props) {
    return (
        <OfferDetailsComponent {...props} />
    );
}

const mapStateToProps = (state) => {
    return {
        offerDetails: state.admin.offerDetails
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        getOfferDetails: (offerId) => dispatch(getOfferDetails(offerId)),
        clearOfferDetails: () => dispatch(clearOfferDetails()),
        offerAcceptance: (offerId, data) => dispatch(offerAcceptance(offerId, data))
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(OfferDetailsContainer);
