import React from 'react';
import { connect } from 'react-redux';
import { setMatches } from "../../home/actions/home.action";
import { getCards, changeStatus, deleteCard, refreshCard, clearCards } from '../actions/card.action';

import CardsComponent from '../components/cards.component';

function CardsContainer(props) {
    return (
        <CardsComponent {...props} />
    );
}

const mapStateToProps = (state) => {

    return {
        cards: state.panelCards.cards,
        isLoading: state.app.isLoading
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        getCards: () => dispatch(getCards()), // jak nie potrzebujemy promise
        deleteCard: (cardId) => dispatch(deleteCard(cardId)), // jak nie potrzebujemy promise
        refreshCard: (cardId) => dispatch(refreshCard(cardId)), // jak nie potrzebujemy promise
        changeStatus: (cardid, isActive) => dispatch(changeStatus(cardid, isActive)),  // jak nie potrzebujemy promise
        clearCards: () => dispatch(clearCards()), // jak nie potrzebujemy promise
        setMatches: (matches, type) => dispatch(setMatches(matches, type))
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(CardsContainer);
