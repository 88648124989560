import { SET_FILTERS, RESET_FILTERS, SET_ROUTE, SET_CATEGORIES_TREE } from '../actions/filter.action';

const initial_state = {
    filters: {
        query: "",
        category: [],
        place: ""
    },
    route: '',
    categoriesTree: []
}

const filter = (state = initial_state, action) => {
    switch (action.type) {
        case SET_FILTERS:
            let newState = { ...state };
            newState.filters[action.filter] = action.value;
            return newState;
        case RESET_FILTERS:
            return { ...state, filters: { place: "", category: [], query: "" } };
        case SET_CATEGORIES_TREE:
            return { ...state, categoriesTree: action.categoriesTree };
        case SET_ROUTE:
            return { ...state, route: action.route };
        default:
            return state;
    }
};

export default filter;