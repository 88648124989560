import * as Yup from 'yup';

export const CardInitialState = {
    categories: [],
    position: '',
    tags: '',
    avatar: '',
    address: {
        cities: [],
        administrative_areas_level_1: [],
        wholeCountry: false,
        outsiteCountry: false,
    },
    showLanguages: false,
    showEducation: false,
    expectedSalary: '',
    skills: [],
    description: '',
    rejectCompanies: []
}

export const CardSchema = Yup.object().shape({
    categories: Yup.array() // kategoria oferty TABLICA STRINGÓW
        .required('Wartość jest wymagana'),
    position: Yup.mixed() // stanowisko oferty TABLICA STRINGÓW
        .required('Wartość jest wymagana'),
    tags: Yup.mixed(),
    avatar: Yup.string(),
    address: Yup.object().shape(
        {
            wholeCountry: Yup.boolean(), // czy oferta obejmuje cały kraj? jeżeli zaznaczone to nie wymagany adres !
            outsiteCountry: Yup.boolean(), // czy oferta obejmuje cały kraj? jeżeli zaznaczone to nie wymagany adres !
            administrative_areas_level_1: Yup.array().when('wholeCountry', {
                is: value => !!value === false,
                then: Yup.array().when('outsiteCountry', {
                    is: value => !!value === false,
                    then: Yup.array().required(
                        'Wartość jest wymagana'
                    ),
                    otherwise: Yup.array()
                }),
                otherwise: Yup.array()
            }),
            cities: Yup.array(),

        }
    ),
    expectedSalary: Yup.string(),
    showLanguages: Yup.bool(),
    showEducation: Yup.bool(),
    skills: Yup.array() // umiejętności
        .required('Wartość jest wymagana'),
    description: Yup.string().test("len", "Wartość jest wymagana", val => { // .min(100, 'Minimalna ilość znaków opisu to 100')
        return getLengthFromDescription("cardDescription") === 0 ? false : true;
    }).test("len", "Maksymalna ilość znaków opisu to 2000", val => { // .max(2000, 'Minimalna ilość znaków opisu to 2000')
        return getLengthFromDescription("cardDescription") < 2000;
    }),
    rejectCompanies: Yup.mixed()
});

function getLengthFromDescription(className) {
    let descriptionLength = 0;
    [...document.querySelectorAll(`.${className} span[data-text]`)].map((element) => {
        return descriptionLength += element.innerHTML.length;
    });
    return descriptionLength;
}