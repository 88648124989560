import React from 'react';
import { connect } from 'react-redux';

import { getOffers, clearOffers, offerAcceptance } from '../actions/admin.action';
import OffersAcceptanceComponent from '../components/offersAcceptance.component';

function OffersAcceptanceContainer(props) {
    return (
        <OffersAcceptanceComponent {...props} />
    );
}

const mapStateToProps = (state) => {
    return {
        offers: state.admin.cards
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        getOffers: () => dispatch(getOffers()),
        clearOffers: () => dispatch(clearOffers()),
        offerAcceptance: (offerId, data) => dispatch(offerAcceptance(offerId, data))
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(OffersAcceptanceContainer);
