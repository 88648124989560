import React, { useState, useEffect } from 'react';
import '../assets/filter.css';

import TreeComponent from '../containers/tree.container';
import AutocompleteComponent from './autocomplete.component';
import SliderComponent from '../../application/components/slider.component';

import SearchIcon from '../assets/img/search_icon.svg';
import FilterResultsComponent from '../components/filterResults.component';

import CloseIcon from '../assets/img/close.svg';
import FiltersIcon from '../assets/img/filters_icon.svg';

function FilterComponent(props) {
   const { listType, resetFilters, resetQueryFilters, getPlaces, setFilters, setRoute, route, total, cardCount, offerCount } = props;

   const [queryValue, setQueryValue] = useState([]);
   const [placesValue, setPlacesValue] = useState([]);
   const [showCategoryFilters, setShowCategoryFilters] = useState(false);
   const [showMobileFilters, setShowMobileFiters] = useState(false);

   useEffect(() => {
      if (route) {
         setRoute(route);
      }
   }, [route, setRoute]);

   useEffect(() => {
      return () => {
         resetQueryFilters(); // czyścimy filtry po wyjściu
      }
   }, [resetQueryFilters]);

   function clearFilters() {
      setQueryValue([]); // czyścimy state dla autocompleteSelectComponent
      setPlacesValue([]); // czyścimy state dla autocompleteSelectComponent
      resetFilters(); // resetujemy filtry w store
   }

   function handleFocusInputs() {
      let materialInput = document.querySelectorAll('.material-input');

      if (materialInput) {
         materialInput.forEach(function (e) {
            let input = e.querySelector('.material-input__input');
            let label = e.querySelector('label');

            if (input && label) {
               input.addEventListener('focusin', (event) => {
                  label.classList.add('float-label');
               })

               input.addEventListener('focusout', (event) => {
                  if (input.value === "") {
                     label.classList.remove('float-label');
                  }
               })
            }

         })
      }
   }

   return (
      <div>
         <SliderComponent offersCount={offerCount} cardsCount={cardCount} listType={listType} />
         <div className="d-none d-xl-block">
            <section id="searchBar" className="search-bar pt-4 pb-xl-4 bg--light position-relative">
               <div className="container-fluid">
                  <div className="row align-items-center justify-content-between">
                     <div className="col-12 col-lg">
                        <div className="row">
                           <div className="col-12 col-md-6 material-input">
                              <AutocompleteComponent
                                 state={queryValue}
                                 setState={setQueryValue}
                                 onlyFilteredValue={true}
                                 options={[]}
                                 placeholder="Wpisz dział, stanowisko, tagi"
                                 handleOnSelect={(values) => setFilters("query", values.map((value => value.value.replace("#", ""))))}
                                 multiple={true}
                                 handleFocusInputs={() => handleFocusInputs()} />
                           </div>
                           <div className="col-12 col-md-6 material-input">
                              <AutocompleteComponent
                                 state={placesValue}
                                 setState={setPlacesValue}
                                 getOptionsMethod={getPlaces}
                                 options={[]}
                                 handleOnSelect={(values) => setFilters("place", values.map((value => value.value)))}
                                 placeholder="Wpisz województwo, miasto"
                                 multiple={true}
                                 handleFocusInputs={() => handleFocusInputs()} />
                           </div>
                        </div>
                     </div>
                     <div className="col-auto d-none d-xl-block">
                        <div className="row align-items-center">
                           <div className="col-auto text-center dropdown">
                              <div id="filterBtn" className="search-bar__filter-btn cursor-pointer mx-4" onClick={() => setShowCategoryFilters(!showCategoryFilters)} >
                                 <div>
                                    <img src={FiltersIcon} alt="" className="p-4" />
                                 </div>
                              </div>
                           </div>
                           <div className="col-auto d-flex justify-content-end">
                              <button id="runFilters" className="btn btn--primary" onClick={() => setShowCategoryFilters(false)}>
                                 <img src={SearchIcon} alt="" className="mr-5" />Szukaj
                              </button>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
               <TreeComponent showCategoryFilters={showCategoryFilters} />
            </section>
         </div>
         <FilterResultsComponent
            query={queryValue}
            setQuery={setQueryValue}
            setPlaces={setPlacesValue}
            places={placesValue}
            totalCount={total}
            handleOnDeleteQuery={(values) => setFilters("query", values.map((value => value.value)))}
            handleOnDeletePlaces={(values) => setFilters("place", values.map((value => value.value)))}
            resetFilters={clearFilters} />
         {/* MOBILE VIEW */}
         <div className="d-block d-xl-none">
            <div>
               <div className="trigger-wrapper position-fixed d-block d-xl-none">
                  <button id="filtersTrigger" style={{ opacity: '1', visibility: 'visible' }} className="btn btn--primary filters-trigger" onClick={() => setShowMobileFiters(true)}>
                     <img src={FiltersIcon} alt="" className="mr-5" /> Filtruj
                    </button>
               </div>
               <section id="filtersMobile" className={`filters-mobile pb-4 bg--light position-fixed ${showMobileFilters ? '' : 'd-none'}`}>
                  <div className="container-fluid">
                     <div className="row">
                        <div className="col-12">
                           <h2>Filtruj</h2>
                        </div>
                     </div>
                  </div>
                  <span id="filtersMobileClose" className="cursor-pointer filters-mobile__close">
                     <img src={CloseIcon} alt="" onClick={() => setShowMobileFiters(false)} />
                  </span>
                  <section id="searchBar" className="search-bar pt-4 pb-xl-4 bg--light position-relative">
                     <div className="container-fluid">
                        <div className="row align-items-center justify-content-between">
                           <div className="col-12 col-lg">
                              <div className="row">
                                 <div className="col-12 col-md-6 material-input mb-4">
                                    <AutocompleteComponent
                                       state={queryValue}
                                       setState={setQueryValue}
                                       onlyFilteredValue={true}
                                       options={[]}
                                       placeholder="Wpisz dział, stanowisko, tagi"
                                       handleOnSelect={(values) => setFilters("query", values.map((value => value.value.replace("#", ""))))}
                                       multiple={true}
                                       handleFocusInputs={() => handleFocusInputs()} />
                                 </div>
                                 <div className="col-12 col-md-6 material-input">
                                    <AutocompleteComponent
                                       state={placesValue}
                                       setState={setPlacesValue}
                                       getOptionsMethod={getPlaces}
                                       options={[]}
                                       handleOnSelect={(values) => setFilters("place", values.map((value => value.value)))}
                                       placeholder="Wpisz województwo, miasto"
                                       multiple={true}
                                       handleFocusInputs={() => handleFocusInputs()} />
                                 </div>
                              </div>
                           </div>
                           <div className="col-auto d-none d-xl-block">
                              <div className="row align-items-center">
                                 <div className="col-auto text-center dropdown">
                                    <div id="filterBtn" className="search-bar__filter-btn cursor-pointer mx-4" >
                                       <div>
                                          <img src={FiltersIcon} alt="" className="p-4" onClick={() => setShowCategoryFilters(!showCategoryFilters)} />
                                       </div>
                                    </div>
                                 </div>
                                 <div className="col-auto d-flex justify-content-end">
                                    <button id="runFilters" className="btn btn--primary" onClick={() => setShowMobileFiters(true)}>
                                       <img src={SearchIcon} alt="" className="mr-5" />
                                                Szukaj
                                    </button>
                                 </div>
                              </div>
                           </div>
                        </div>
                     </div>
                     <FilterResultsComponent
                        query={queryValue}
                        setQuery={setQueryValue}
                        setPlaces={setPlacesValue}
                        places={placesValue}
                        totalCount={total}
                        handleOnDeleteQuery={(values) => setFilters("query", values.map((value => value.value)))}
                        handleOnDeletePlaces={(values) => setFilters("place", values.map((value => value.value)))}
                        resetFilters={clearFilters}
                        showMobileFilters={showMobileFilters} />
                     <TreeComponent />
                  </section>
                  <div className="d-block d-xl-none" style={{ marginBottom: '105px' }}>
                     <div className="container-fluid">
                        <div className="row justify-content-center">
                           <div className="col-12 d-flex justify-content-center">
                              <button className="btn btn--outline filter-result__delete mb-4" onClick={() => resetFilters()}>Usuń wszystkie filtry</button>
                           </div>
                           <div className="col-auto">
                              <button id="runFiltersMobile" className="btn btn--primary" onClick={() => setShowMobileFiters(false)} >
                                 <img src={SearchIcon} alt="" className="mr-5" />Szukaj
                              </button>
                           </div>
                        </div>
                     </div>
                  </div>
               </section>
            </div>
         </div>
      </div>
   )

}

export default FilterComponent;
