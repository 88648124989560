import React, { useState } from 'react';
import PropTypes from 'prop-types';

function ChooseSalaryComponent(props) {

    const { errors, handleSelectedValue } = props;

    const salaryOptions = ['nie podano', 'do negocjacji', 'od - do', 'nie mniej niż'];
    const [selectedSalary, setSelectedSalary] = useState(salaryOptions[0]);
    const [salaryErrors, setSalaryErrors] = useState({});

    const [salaryValues, setSalaryValues] = useState({
        valueFrom: "",
        valueTo: "",
        noLessThanValue: ""
    });

    function handleFocusInputs() {
        let materialInput = document.querySelectorAll('.material-input');

        if (materialInput) {
            materialInput.forEach(function (e) {
                let input = e.querySelector('.material-input__input');
                let label = e.querySelector('label');

                if (input && label) {
                    input.addEventListener('focusin', (event) => {
                        label.classList.add('float-label');
                    })

                    input.addEventListener('focusout', (event) => {
                        if (input.value === "") {
                            label.classList.remove('float-label');
                        }
                    })
                }
            })
        }
    }

    function handleOnChange(e) {
        setSelectedSalary(e.target.value);
    }

    function handleSaveSalary() {

        setSalaryErrors({});

        let errors = {};

        if (selectedSalary === "Wybierz") {
            errors.salaryChoose = "Wartość jest wymagana";
        }

        if (selectedSalary === "od - do" && !salaryValues.valueFrom) {
            errors.valueFrom = "Wartość jest wymagana";
        }
        if (selectedSalary === "od - do" && !salaryValues.valueTo) {
            errors.valueTo = "Wartość jest wymagana";
        }
        if (selectedSalary === "nie mniej niż" && !salaryValues.noLessThanValue) {
            errors.noLessThanValue = "Wartość jest wymagana";
        }

        if (Object.keys(errors).length > 0) {
            setSalaryErrors(errors);
            return;
        }

        let salaryValue = selectedSalary;

        if (salaryValue === "od - do") {
            salaryValue = `od ${salaryValues.valueFrom} do ${salaryValues.valueTo}`;
        }
        if (salaryValue === "nie mniej niż") {
            salaryValue = `${salaryValue} ${salaryValues.noLessThanValue}`;
        }

        handleSelectedValue(salaryValue);
        setSalaryErrors({});
    }

    return (
        <div className="col-12 row">
            <div className={`col-12 ${selectedSalary && selectedSalary === "od - do" ? 'col-lg-3' : 'col-lg-6'} material-input material-select mb-4`}>
                <div className="add-card__salary d-flex align-items-center input-group position-relative" >
                    <label className="ml-5">Oczekiwane wynagrodzenie (netto)</label>
                    <select className="custom-select material-input__input h-100 cursor-pointer" id="addCardSalary" onChange={handleOnChange} onFocus={() => handleFocusInputs()}>
                        <option defaultValue></option>
                        {salaryOptions.map((option, index) => <option className="autocomplete-options-wrapper" key={`salary-option-${index}`} value={option}>{option}</option>)}
                    </select>
                </div>
            </div>
            {selectedSalary && selectedSalary === "od - do" && <div className={`col-12 col-lg-3 material-input ${salaryErrors && salaryErrors.valueFrom ? "input-error" : ""} mb-4`}>
                <div className="d-flex align-items-center">
                    <label className="ml-5">Podaj kwotę od</label>
                    <input
                        name="valueFrom"
                        type="number"
                        onChange={(e) => setSalaryValues({ ...salaryValues, valueFrom: e.target.value })}
                        value={salaryValues.valueFrom}
                        className="material-input__input h-100"
                        onFocus={() => handleFocusInputs()}
                    />
                </div>
                {salaryErrors && salaryErrors.valueFrom ? (
                    <div className="material-input__error">{salaryErrors.valueFrom}</div>
                ) : null}
            </div>}
            {selectedSalary && selectedSalary === "od - do" && <div className={`col-12 col-lg-3 material-input ${salaryErrors && salaryErrors.valueTo ? "input-error" : ""} mb-4 `}>
                <div className="d-flex align-items-center">
                    <label className="ml-5">Podaj kwotę do</label>
                    <input
                        name="valueTo"
                        type="number"
                        onChange={(e) => setSalaryValues({ ...salaryValues, valueTo: e.target.value })}
                        value={salaryValues.valueTo}
                        className="material-input__input h-100"
                        onFocus={() => handleFocusInputs()}
                    />
                </div>
                {salaryErrors && salaryErrors.valueTo ? (
                    <div className="material-input__error">{salaryErrors.valueTo}</div>
                ) : null}
            </div>}
            {selectedSalary && selectedSalary === "nie mniej niż" && <div className={`col-12 col-lg-3 material-input ${errors && errors.expectedSalary ? "input-error" : ""} mb-4`}>
                <div className="d-flex align-items-center">
                    <label className="ml-5">Podaj kwotę</label>
                    <input
                        name="valueTo"
                        type="number"
                        onChange={(e) => setSalaryValues({ ...salaryValues, noLessThanValue: e.target.value })}
                        value={salaryValues.noLessThanValue}
                        className="material-input__input h-100"
                        onFocus={() => handleFocusInputs()}
                    />
                </div>
                {errors && errors.expectedSalary ? (
                    <div className="material-input__error">{errors.expectedSalary}</div>
                ) : null}
            </div>}
            <div className="col-12 col-lg-3">
                <button type="button" className="btn btn--grey w-100" onClick={handleSaveSalary}>Zapisz</button>
            </div>
        </div>
    )
}

ChooseSalaryComponent.propTypes = {
    handleSelectedValue: PropTypes.func.isRequired,
    errors: PropTypes.object
};


export default ChooseSalaryComponent;