import React from 'react';

import SliderBtnsComponent from './slider/slider-btns.component.js';
import SliderBtnsMobileComponent from './slider/slider-btns-mobile.component.js';

function SliderComponent(props) {
   return (
      <div>
         <section id="slider" className="slider">
            <div className="container">
               <div className="row">
                  <div className="col-12 text-center">
                     <h1><p className="h1--subtitle m-0">Skracamy drogę między</p>pracownikiem, <br className="d-block d-md-none" />a pracodawcą</h1>
                     <div className="d-none d-xl-block">
                        <SliderBtnsComponent offersCount={props.offersCount} cardsCount={props.cardsCount} listType={props.listType} />
                     </div>
                  </div>
               </div>
            </div>
         </section>
         <div className="slider__buttons slider__buttons-mobile d-block d-xl-none">
            <div className="container-fluid px-4">
               <div className="row d-flex align-items-center justify-content-center">
                  <SliderBtnsMobileComponent offersCount={props.offersCount} cardsCount={props.cardsCount} listType={props.listType} />
               </div>
            </div>
         </div>
      </div>
   );
}

export default SliderComponent;
