import { SET_PROFILE, CLEAR_PROFILE } from '../actions/profile.action';

const initial_state = {
    user: {},
    uploadedFille: null
}

const card = (state = initial_state, action) => {
    switch (action.type) {
        case SET_PROFILE:
            return { ...state, user: action.user };
        case CLEAR_PROFILE:
            return { ...initial_state };
        default:
            return state;
    }
};

export default card;