import Api from '../../application/service/api.service';
import { isLoading, showToast } from '../../application/actions/app.action';
import _ from "lodash";
const namespace = 'PROFILE_CARD';

export const SET_CARDS = `SET_CARDS_${namespace}`;
export const CLEAR_CARDS = `CLEAR_CARDS_${namespace}`;
export const SET_CARD = `SET_CARD_${namespace}`;
export const CLEAR_CARD = `CLEAR_CARD_${namespace}`;

export const setCard = (card) => ({
    type: SET_CARD,
    card
});

export const clearCard = () => ({
    type: CLEAR_CARD
});

export function getCard(cardId) {
    return dispatch => {

        // globalny is loading 
        dispatch(isLoading(true));

        return Api.get(`profile/card/${cardId}`, null).then((result) => {

            // zawsze ukrywamy globalny is loading
            dispatch(isLoading(false));

            if (result.success) {
                dispatch(setCard(result.document));
            } else if ('errors' in result) {
                dispatch(showToast('alert', 'Błąd podczas pobierania wizytówki'));
            }
        });
    };
}

export function deleteCard(cardId) {
    return dispatch => {

        // globalny is loading 
        dispatch(isLoading(true));

        return Api.delete(`profile/card`, cardId).then((result) => {

            // zawsze ukrywamy globalny is loading
            dispatch(isLoading(false));

            if (result.success) {
                dispatch(getCards());
            } else if ('errors' in result) {
                dispatch(showToast('alert', 'Błąd podczas usuwania wizytówki'));
            }
        });
    };
}

export const setCards = (cards, total) => ({
    type: SET_CARDS,
    cards,
    total
});

export const clearCards = () => ({
    type: CLEAR_CARDS
});

export function getCards() {
    return (dispatch, getState) => {

        const { filter: { filters } } = getState();

        let params = {
            "sort[createdAt]": -1,
            "limit": 30
        };

        if (filters.query instanceof Array && filters.query.length > 0) {
            params["category.categoryName[orin]"] = filters.query.join(',');
            params["position[orin]"] = filters.query.join(',');
            params["shortDescription[orin]"] = filters.query.join(',');
            params["description[orin]"] = filters.query.join(',');
        }

        if (filters.category instanceof Array && filters.category.length > 0) {
            params["category.categoryId[in]"] = filters.category.join(",");
        }

        if (filters.place instanceof Array && filters.place.length > 0) {
            params["address.city[in]"] = filters.place.join(',');
            params["address.adnimistrative_area_level_1[in]"] = filters.place.join(',');
        }

        // globalny is loading 
        dispatch(isLoading(true));

        return Api.get('profile/card', params).then((result) => {

            // zawsze ukrywamy globalny is loading
            dispatch(isLoading(false));

            if (result.success) {
                dispatch(setCards(result.documents, result.total))
            } else if ('errors' in result) {
                dispatch(showToast('alert', 'Błąd podczas pobierania wizytówek'));
            }
        });
    };
}

export function saveCard(cardData, dispatch) {
    return new Promise((resolve, reject) => {

        // globalny is loading 
        dispatch(isLoading(true));
        if (_.isArray(cardData.skills)) {
            cardData.skills = cardData.skills.map((skill) => {
                if (_.has(skill, "value")) {
                    return skill.value;
                } else {
                    return skill;
                }
            });
        }

        if (cardData._id) { // edycja
            return Api.put('profile/card', cardData, cardData._id).then((result) => {

                // zawsze ukrywamy globalny is loading
                dispatch(isLoading(false));

                if (result.success) {
                    dispatch(showToast('info', 'Wizytówka została zaktualizowana'));
                    return resolve();
                } else if ('errors' in result) {
                    return reject(result.errors);
                }
            });
        } else { // dodawanie
            return Api.post('profile/card', cardData).then((result) => {

                // zawsze ukrywamy globalny is loading
                dispatch(isLoading(false));

                if (result.success) {
                    dispatch(showToast('info', 'Wizytówka została dodana'));
                    return resolve();
                } else if ('errors' in result) {
                    return reject(result.errors);
                }
            });
        }
    });
}

export function changeStatus(cardId, isActive) {
    return dispatch => {

        // globalny is loading 
        dispatch(isLoading(true));

        return Api.put('profile/card/changeIsActive', { active: isActive }, cardId).then((result) => {

            // zawsze ukrywamy globalny is loading
            dispatch(isLoading(false));

            if (result.success) {
                dispatch(getCards()); // pobieramy jeszcze raz wizytówki by się na widoku odświeżyło
                dispatch(showToast('info', 'Status został zmieniony poprawnie'));
            } else if ('errors' in result) {
                dispatch(showToast('alert', 'Błąd podczas zmiany statusu wizytówki'));
            }
        });
    };
}

export function refreshCard(cardId) {
    return dispatch => {
        // globalny is loading 
        dispatch(isLoading(true));

        return Api.put('profile/refreshCard', {}, cardId).then((result) => {

            // zawsze ukrywamy globalny is loading
            dispatch(isLoading(false));

            if (result.success) {
                dispatch(getCards()); // pobieramy jeszcze raz wizytówki by się na widoku odświeżyło
                dispatch(showToast('info', 'Wizytówka została odświeżona'));
            } else if ('errors' in result) {
                dispatch(showToast('alert', 'Błąd podczas odświeżania wizytówki'));
            }
        });
    };
}

