import React, { useState, Fragment, useEffect } from 'react';
import { useFormik } from 'formik';
import { useHistory, useParams as navigateParam } from "react-router-dom";
import '../assets/reminder.css';

import { Button } from 'react-bootstrap';

function ReminderComponent(props) {

    useEffect(() => {
        document.body.style.backgroundColor = "white";
        return () => {
            document.body.style.backgroundColor = "#eae9e9";
        };
    }, [])

    function handleFocusInputs() {
        let materialInput = document.querySelectorAll('.material-input');

        if (materialInput) {
            materialInput.forEach(function (e) {
                let input = e.querySelector('.material-input__input');
                let label = e.querySelector('label');

                if (input && label) {
                    input.addEventListener('focusin', (event) => {
                        label.classList.add('float-label');
                    })

                    input.addEventListener('focusout', (event) => {
                        if (input.value === "") {
                            label.classList.remove('float-label');
                        }
                    })
                }

            })
        }
    }

    const Reminder = () => {

        let { token } = navigateParam();
        let history = useHistory();

        const [errors, setApiErrors] = useState(null);
        const [resetInfo, setResetInfo] = useState("");

        const formik = useFormik({
            initialValues: {
                email: '',
                password: '',
                passwordConfirm: '',
            },
            // validationSchema: LoginInSchema,
            onSubmit: (values, actions) => {
                if (!token) {
                    props.restartPasswordMail(values).then(() => {
                        setResetInfo("Jeżeli konto istnieje w naszym systemie, otrzymasz maila z dalszymi informacjami dotyczącymi resetu hasła do konta.");
                        formik.setFieldValue("email", "");
                        setApiErrors(null);
                    }, (errors) => {
                        // nie udało się to podstawiamy błędy
                        setApiErrors(errors);
                    });
                } else {
                    props.restartPassword(values, token).then(() => {
                        // jak się uda to spadamy stąd
                        history.push('/login', { isReset: 'true' });
                        return;
                    }, (errors) => {
                        // nie udało się to podstawiamy błędy
                        setApiErrors(errors);
                    });
                }
            }
        });

        return (
            <form className="row justify-content-center" onSubmit={formik.handleSubmit}>
                <div className="col-12 reset-info-wrapper mb-5">{resetInfo}</div>
                {/* EMAIL */}
                {!token &&
                    <div className={`col-12 material-input ${formik.errors.email ? "input-error" : ""} mb-50`}>
                        <div className="d-flex align-items-center">
                            {errors && errors.message ? (
                                <div className="material-input__error">{errors.message}</div>
                            ) : null}
                            <label className="ml-5">Wpisz email<span className="required"> *</span></label>
                            <input
                                id="email"
                                name="email"
                                type="text"
                                autoComplete="off"
                                onChange={formik.handleChange}
                                value={formik.values.email}
                                className="material-input__input h-100"
                                onFocus={handleFocusInputs}
                            />
                        </div>
                        {formik.errors.email && formik.touched.email ? (
                            <div className="material-input__error">{formik.errors.email}</div>
                        ) : null}
                        {errors && errors.email ? (
                            <div className="material-input__error">{errors.email}</div>
                        ) : null}
                    </div>
                }
                {/* PASSWORD */}
                {token &&
                    <div className={`col-12 material-input ${formik.errors.password ? "input-error" : ""} mb-50`}>
                        <div className="d-flex align-items-center">
                            <label className="ml-5">Wpisz hasło<span className="required"> *</span></label>
                            <input
                                id="password"
                                name="password"
                                type="password"
                                autoComplete="new-password"
                                onChange={formik.handleChange}
                                value={formik.values.password}
                                className="material-input__input h-100"
                                onFocus={handleFocusInputs}
                            />
                        </div>
                        {formik.errors.password && formik.touched.password ? (
                            <div className="material-input__error">{formik.errors.password}</div>
                        ) : null}
                        {errors && errors.password ? (
                            <Fragment>
                                {errors.password.split(",").map((error, index) => {
                                    return <div key={`reset-pass-${index}`} className="material-input__error">{error}</div>
                                })}
                            </Fragment>
                        ) : null}
                        {errors && errors.message ? (
                            <div className="material-input__error">{errors.message}</div>
                        ) : null}
                    </div>
                }
                {/* REPEAT PASSWORD */}
                {token &&
                    <div className={`col-12 material-input ${formik.errors.password ? "input-error" : ""} mb-50`}>
                        <div className="d-flex align-items-center">
                            <label className="ml-5">Powtórz hasło<span className="required"> *</span></label>
                            <input
                                id="passwordConfirm"
                                name="passwordConfirm"
                                type="password"
                                autoComplete="new-password"
                                onChange={formik.handleChange}
                                value={formik.values.passwordConfirm}
                                className="material-input__input h-100"
                                onFocus={handleFocusInputs}
                            />
                        </div>
                        {formik.errors.passwordConfirm && formik.touched.passwordConfirm ? (
                            <div className="material-input__error">{formik.errors.passwordConfirm}</div>
                        ) : null}
                    </div>
                }
                {/* BUTTON */}
                <div className="col-md-6">
                    <Button className="btn btn--primary w-100 justify-content-center" variant="bee" type="submit">
                        {token ? 'Zresetuj hasło' : 'Zmień hasło'}
                    </Button>
                </div>
            </form>
        )
    };

    return (
        <section className="login login__wrapper">
            <div id="loginContent" className="login-content active">
                <div className="login__wrapper">
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-md-8 col-lg-6">
                                <div className="col-12 mb-50">
                                    <h2 className="text-center h1">Resetuj hasło</h2>
                                </div>
                                <Reminder />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )

}


export default ReminderComponent;
