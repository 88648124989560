import Api from '../../application/service/api.service';
import { isLoading, showToast } from '../../application/actions/app.action';
import { setIsLogged, logout } from '../../auth/actions/auth.action';

const namespace = 'PROFILE';

export const SET_PROFILE = `SET_PROFILE_${namespace}`;
export const CLEAR_PROFILE = `CLEAR_PROFILE_${namespace}`;

export const setProfile = (user) => ({
    type: SET_PROFILE,
    user
});

export const clearProfile = () => ({
    type: CLEAR_PROFILE
});

export function getProfile() {
    return dispatch => {

        // globalny is loading 
        dispatch(isLoading(true));

        return Api.get('profile/profile', null).then((result) => {

            // zawsze ukrywamy globalny is loading
            dispatch(isLoading(false));

            if (result.success) {
                dispatch(setProfile(result.document));
            } else if ('errors' in result) {
                dispatch(showToast('alert', 'Błąd podczas pobierania profilu'));
            }
        });
    };
}

export function saveProfile(profileData, dispatch) {
    return new Promise((resolve, reject) => {

        // globalny is loading 
        dispatch(isLoading(true));

        return Api.put('profile/profile', profileData, null).then((result) => {

            // zawsze ukrywamy globalny is loading
            dispatch(isLoading(false));

            if (result.success) {
                profileData.profileCompleted = true;
                dispatch(setIsLogged(true, profileData));
                dispatch(showToast('info', 'Profil został zaktualizowany'));
                return resolve();
            } else if ('errors' in result) {
                return reject(result.errors);
            }
        });
    });
}

export function uploadFile(file, dispatch, loggedUserId) {
    return new Promise((resolve, reject) => {
        return Api.put('profile/setFile', { file: file }, loggedUserId).then((result) => {
            if (result.success) {
                dispatch(showToast('info', 'Plik został dodany do profilu'));
                return resolve(result.file);
            } else if ('errors' in result) {

                dispatch(showToast('alert', 'Błąd podczas dodawania pliku do profilu'));
                return reject();
            }
        });
    });
};

export function deleteFile(dispatch, loggedUserId) {
    return new Promise((resolve, reject) => {
        return Api.delete('profile/deleteFile', loggedUserId).then((result) => {
            if (result.success) {
                dispatch(showToast('info', 'Plik został usunięty do profilu'));
                return resolve();
            } else if ('errors' in result) {
                dispatch(showToast('alert', 'Błąd podczas usuwania pliku z profilu'));
                return reject();
            }
        });
    });
}

export function downloadCv(fileName) {
    return (dispatch) => {
        dispatch(isLoading(true));
        return Api.get(`application/files/${fileName}`).then((result) => {
            dispatch(isLoading(false));
            if (result.success && result.file) {
                let blob = new Blob([new Uint8Array(result.file.data)], { type: "application/pdf" });
                var a = document.createElement("a");
                document.body.appendChild(a);
                a.style = "display: none";
                let url = window.URL.createObjectURL(blob);
                a.href = url;
                a.download = fileName;
                a.click();
                window.URL.revokeObjectURL(url);
            } else if ('errors' in result) {
                dispatch(showToast('alert', 'Błąd podczas pobierania pliku'));
            }
        });
    };
}

export function deleteAccount() {
    return async (dispatch) => {
        dispatch(isLoading(true));

        let res = await Api.delete("profile/deleteAccount");
        dispatch(isLoading(false));
        if (res && res.success) {
            dispatch(showToast('alert', 'Twoje konto zostało usunięte'));
            dispatch(logout());
        } else {
            dispatch(showToast('alert', 'Błąd podczas usuwania konta'));
        }
    }
}