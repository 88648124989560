import { combineReducers } from 'redux'

import AppReducer from './app.reducer';
import AuthReducer from '../../auth/reducers/auth.reducer';
import HomeReducer from '../../home/reducers/home.reducer';
import PanelCardsReducer from '../../panel/reducers/cards.reducer';
import PanelCardReducer from '../../panel/reducers/card.reducer';
import PanelOffersReducer from '../../panel/reducers/offers.reducer';
import PanelOfferReducer from '../../panel/reducers/offer.reducer';
import ProfileReducer from '../../panel/reducers/profile.reducer';
import FilterReducer from '../../application/reducers/filter.reducer';
import AdminReducer from '../../admin/reducers/admin.reducer';

export default combineReducers({
    app: AppReducer, // global isLoading, global showToast
    auth: AuthReducer, // rejestracja, logowanie, isAuth
    home: HomeReducer, // lista wizytówek na głównej stronie
    panelCards: PanelCardsReducer, // lista wizytówek w panelu 
    panelCard: PanelCardReducer, // edycja wizytówki w panelu
    panelOffers: PanelOffersReducer, // lista wizytówek w panelu
    panelOffer: PanelOfferReducer, // edycja wizytówki w panelu
    profile: ProfileReducer, // edycja profilu użytkownika
    filter: FilterReducer, // filtry wizytówek
    admin: AdminReducer // administracja
});