import React, { useEffect, useState } from 'react';
import { useFormik } from 'formik';
import { useHistory, useLocation, useParams as navigateParam, Link } from "react-router-dom";
import _ from 'lodash';

import { Button } from 'react-bootstrap';
import { LoginInSchema } from '../schemas/login.schema';
import '../assets/login.css';

function LoginInComponent(props) {
    let { token } = navigateParam();
    let { checkToken } = props;
    const location = useLocation();

    const [isRegistered, setIsRegistered] = useState(null);
    const [isRestart, setIsRestart] = useState(null);
    const [tokenStatus, setTokenStatus] = useState(null);

    useEffect(() => {
        document.body.style.backgroundColor = "white";
        return () => {
            document.body.style.backgroundColor = "#eae9e9";
        };
    }, [])

    useEffect(() => {
        setIsRegistered(_.get(location, 'state.isRegistered', false));
        setIsRestart(_.get(location, 'state.isReset', false));
        if (token) {
            checkToken(token).then(() => {
                setTokenStatus({ success: true, message: 'Aktywacja przebiegła pomyślnie' });
            }).catch((error) => {
                setTokenStatus({ success: false, ...error });
            });
        }
    }, [token, checkToken, location]);

    const LoginIn = () => {
        let history = useHistory();
        const [errors, setApiErrors] = useState(null);

        function handleFocusInputs() {
            let materialInput = document.querySelectorAll('.material-input');

            if (materialInput) {
                materialInput.forEach(function (e) {
                    let input = e.querySelector('.material-input__input');
                    let label = e.querySelector('label');

                    if (input && label) {
                        input.addEventListener('focusin', (event) => {
                            label.classList.add('float-label');
                        })

                        input.addEventListener('focusout', (event) => {
                            if (input.value === "") {
                                label.classList.remove('float-label');
                            }
                        })
                    }

                })
            }
        }

        const formik = useFormik({
            initialValues: {
                login: '',
                password: '',
            },
            validationSchema: LoginInSchema,
            onSubmit: (values, actions) => {
                props.loginIn(values).then(
                    (userType) => {
                        if (userType === "person") {
                            // jak się uda to spadamy stąd
                            history.push('/cards');
                        } else if (userType === "company") {
                            // jak się uda to spadamy stąd
                            history.push('/offers');
                        } else {
                            // jak się uda to spadamy stąd
                            history.push('/');
                        }
                        return;
                    },
                    (errors) => {
                        // nie udało się to podstawiamy błędy
                        setApiErrors(errors);
                    });
            }
        });

        const TokenStatus = () => {
            if (tokenStatus && tokenStatus.success !== null) {
                return <div className={tokenStatus.success ? 'token-is-valid' : 'token-no-valid'}>{tokenStatus.message}</div>
            }
            return null;
        }

        const IsRegisteredStatus = () => {
            return isRegistered ? (<div className="is-registered-message">Zostałeś poprawnie zarejestrowany, sprawdź mail w celu aktywacji konta</div>) : null;
        }

        const IsRestart = () => {
            return isRestart ? (<div className="is-registered-message">Hasło zostało poprawnie zmienione. Możesz zalogować się na nowe dane dostepowe.</div>) : null;
        }

        return (
            <form className="row justify-content-center" onSubmit={formik.handleSubmit}>
                <TokenStatus />
                <IsRegisteredStatus />
                <IsRestart />
                <div className={`col-12 material-input ${(formik.errors.login && formik.touched.login) || (errors && errors.login) ? "input-error" : ""} mb-50`}>
                    <div className="d-flex align-items-center">
                        {errors && errors.message ? (
                            <div className="material-input__error">{errors.message}</div>
                        ) : null}
                        <label className="ml-5">Wpisz email<span className="required"> *</span></label>
                        <input
                            type="text"
                            id="login"
                            name="login"
                            className="material-input__input h-100"
                            onChange={formik.handleChange}
                            value={formik.values.login}
                            onFocus={handleFocusInputs}
                        />
                    </div>
                    {formik.errors.login && formik.touched.login ? (
                        <div className="material-input__error">{formik.errors.login}</div>
                    ) : null}
                    {errors && errors.login ? (
                        <div className="material-input__error">{errors.login}</div>
                    ) : null}
                </div>
                <div className={`col-12 material-input ${(formik.errors.password && formik.touched.password) || (errors && errors.password) ? "input-error" : ""} mb-50`}>
                    <div className="d-flex align-items-center">
                        <label className="ml-5">Wpisz hasło<span className="required"> *</span></label>
                        <input
                            className="material-input__input h-100"
                            id="password"
                            name="password"
                            type="password"
                            autoComplete="off"
                            onChange={formik.handleChange}
                            value={formik.values.password}
                            onFocus={handleFocusInputs}
                        />
                    </div>
                    {formik.errors.password && formik.touched.password ? (
                        <div className="material-input__error">{formik.errors.password}</div>
                    ) : null}
                    {errors && errors.password ? (
                        <div className="material-input__error">{errors.password}</div>
                    ) : null}
                </div>
                <div className="col-12 text-right mb-50">
                    <Link to="/reminder">Przypomnij hasło</Link>
                </div>
                <div className="col-md-6">
                    <Button className="btn btn--primary w-100 justify-content-center" variant="bee" type="submit">
                        Zaloguj się
                    </Button>
                </div>
            </form>
        )
    }

    return (
        <section className="login login__wrapper">
            <div className="login__wrapper">
                <div id="loginContent" className="login-content active">
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-md-8 col-lg-6">
                                <div className="row justify-content-center">
                                    <div className="col-12 mb-50">
                                        <h1 className="text-center">Zaloguj się</h1>
                                    </div>
                                    <LoginIn />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )

}


export default LoginInComponent;
