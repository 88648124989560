import React from 'react';
import FilterComponent from '../components/filter.component';

import { connect } from 'react-redux'
import { resetFilters, resetQueryFilters, setFilters, setRoute } from '../actions/filter.action';
import { setListType } from '../../home/actions/home.action';
import { getPlaces } from '../actions/app.action';

function FilterContainer(props) {
    return (
        <FilterComponent {...props} />
    );
}

const mapStateToProps = (state) => {
    return {
        cardCount: state.home.cardCount,
        offerCount: state.home.offerCount,
        total: state.home.total || state.panelCards.total,
        listType: state.home.listType
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        getPlaces: (query, token) => getPlaces('regions', query, token),
        resetFilters: () => dispatch(resetFilters()),
        resetQueryFilters: () => dispatch(resetQueryFilters()),
        setFilters: (filter, value) => dispatch(setFilters(filter, value)),
        setRoute: (route) => dispatch(setRoute(route)),
        setListType: (listType) => dispatch(setListType(listType))
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(FilterContainer);
