import React, { Fragment, useState, useEffect } from 'react';

import PropTypes from 'prop-types';
import '../assets/education.css';

import AutocompleteComponent from '../../application/components/autocomplete.component';
import CloseIcon from '../../application/assets/img/filter_close.svg';

function AddEducationComponent(props) {

    const { errors, editedEducation, setEditedEducation, setErrors, handleAddEducation, handleFocusInputs } = props;
    const [showModal, setShowModal] = useState(false);

    const degreeData = [
        { name: 'Podstawowe', value: 'podstawowe' },
        { name: 'Zawodowe', value: 'zawodowe' },
        { name: 'Średnie', value: 'średnie' },
        { name: 'Licencjat', value: 'licencjat' },
        { name: 'Inżynier', value: 'inzynier' },
        { name: 'Magister', value: 'magister' },
        { name: 'Magister inżynier', value: 'magister inżynier' },
        { name: 'Doktorat', value: 'doktorat' },
        { name: 'Doktor hab.', value: 'doktor hab.' },
        { name: 'Profesor', value: 'profesor' },
        { name: 'MBA', value: 'MBA' },
        { name: 'Podyplomowe', value: 'podyplomowe' },
    ];

    const educationInitialState = {
        index: false,
        degree: [],
        schoolName: '',
        major: '',
        dateFrom: null,
        dateTo: null,
        untilNow: false,
        description: ''
    }

    const [education, setEducation] = useState(educationInitialState);

    function handleChangeState(name, value) {
        let currentState = { ...education };
        currentState[name] = value;

        setEducation({ ...currentState });
    }

    function handleOnValueInput() {
        let materialInput = document.querySelectorAll('.material-input');

        if (materialInput) {
            materialInput.forEach(function (e) {
                let input = e.querySelector('.material-input__input');
                let label = e.querySelector('label');

                if (input && input.value && input.value !== '') {
                    label.classList.add('float-label');
                }

            })
        }
    }
    handleOnValueInput();

    useEffect(() => {

        if (editedEducation && education.index !== editedEducation.index && Object.keys(editedEducation).length > 0) {
            setErrors({});

            let currentEducation = educationInitialState;

            if (Number.isInteger(editedEducation.index)) {
                currentEducation.index = editedEducation.index;
            }
            if (editedEducation.degree) {
                currentEducation.degree = degreeData.filter(e => e.value === editedEducation.degree);
            }

            if (editedEducation.schoolName) {
                currentEducation.schoolName = editedEducation.schoolName;
            }

            if (editedEducation.major) {
                currentEducation.major = editedEducation.major;
            }

            setEducation(educationInitialState);
            setShowModal(true);

        }
    }, [education, editedEducation, educationInitialState, degreeData, setErrors])

    function handleAddNewEducation() {

        if (setErrors) {
            setErrors({});

            let errors = {};

            if (education.degree.length === 0) {
                errors.degree = "Wartość jest wymagana";
            }

            if (!education.schoolName) {
                errors.schoolName = "Wartość jest wymagana";
            }

            if (!education.major) {
                errors.major = "Wartość jest wymagana";
            }

            if (Object.keys(errors).length > 0) {
                setErrors(errors);
                return;
            }
        }

        handleAddEducation({
            index: editedEducation && Number.isInteger(editedEducation.index) ? editedEducation.index : false,
            degree: education.degree[0].value,
            schoolName: education.schoolName,
            major: education.major
        });

        // czyścimy edytowany element
        if (setEditedEducation) {
            setEditedEducation({});
        }

        setEducation(educationInitialState);
        toggleOpenEducationModal();
    }

    function toggleOpenEducationModal() {
        setShowModal(!showModal);
    }

    function handleRemoveDegree() {
        education.degree = [];
        if (setEditedEducation) setEditedEducation(education)
        else setEducation(education);
    }

    return <Fragment>
        <div className={`col-12 col-md-6 ${errors && errors.degree ? 'input-error' : ''} material-input mb-5`}>
            <AutocompleteComponent
                state={education.degree}
                setState={(values) => handleChangeState('degree', values)}
                options={degreeData}
                handleOnSelect={() => { }}
                placeholder="Poziom wykształcenia"
                handleFocusInputs={() => handleFocusInputs()}
            />
            {errors && errors.degree ? (
                <div className="material-input__error">{errors.degree}</div>
            ) : null}
            {education && education.degree && <div className="col-12 mb-4">
                <div className="row no-gutters">
                    {education && education.degree && education.degree.length > 0 && education.degree.map((degree, index) => {
                        return <div className="col-auto" key={'language-' + index}>
                            <button
                                type="button"
                                className="btn btn--filter mr-4 mt-4 filter-result__single"
                                onClick={() => handleRemoveDegree()}>{degree.name}
                                <span className="ml-5"><img src={CloseIcon} alt="" /></span>
                            </button>
                        </div>
                    })}
                </div>
            </div>}
        </div>
        <div className={`col-12 col-md-6 ${errors && errors.schoolName ? 'input-error' : ''} material-input mb-5`}>
            <div className="my-profile__school d-flex align-items-center position-relative">
                <label className="ml-5">Nazwa szkoły</label>
                <input id="schoolName"
                    name="schoolName"
                    type="text"
                    autoComplete="off"
                    onChange={(e) => handleChangeState('schoolName', e.target.value)}
                    value={education.schoolName}
                    className="material-input__input h-100" />
            </div>
            {errors && errors.schoolName ? (
                <div className="material-input__error">{errors.schoolName}</div>
            ) : null}
        </div>
        <div className={`col-12 col-md-6 ${errors && errors.major ? 'input-error' : ''} material-input mb-5`}>
            <div className="my-profile__specialisation d-flex align-items-center position-relative">
                <label className="ml-5">Specjalizacja</label>
                <input id="major"
                    name="major"
                    type="text"
                    autoComplete="off"
                    onChange={(e) => handleChangeState('major', e.target.value)}
                    value={education.major}
                    className="material-input__input h-100" />
            </div>
            {errors && errors.major ? (
                <div className="material-input__error">{errors.major}</div>
            ) : null}
        </div>
        <div className="col-12">
            <div className="row justify-content-center">
                <div className="col-12 col-lg-5 d-flex justify-content-center">
                    <button type="button" id="myProfileAddEducation" className="btn btn--grey w-100" onClick={handleAddNewEducation}>Dodaj wykształcenie</button>
                </div>
            </div>
        </div>
    </Fragment>
}

AddEducationComponent.propTypes = {
    handleAddEducation: PropTypes.func.isRequired,
    setErrors: PropTypes.func,
    errors: PropTypes.object,
    setEditedEducation: PropTypes.func,
    editedEducation: PropTypes.object
};


export default AddEducationComponent;