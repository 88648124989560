import React from 'react';
import PropTypes from 'prop-types';
import { useFormik } from 'formik';
import * as Yup from 'yup';

import { Modal } from 'react-bootstrap';

function RejectAcceptanceModalComponent(props) {

    const { show, handleSubmit, handleToggleModal } = props;

    const RejectModal = () => {

        const RejectSchema = Yup.object().shape({
            reson: Yup.string().required('Wartość jest wymagana')
        });

        const formik = useFormik({
            initialValues: {
                reson: ""
            },
            validationSchema: RejectSchema,
            onSubmit: (values) => {
                handleSubmit(values.reson);
            }
        });

        function handleFocusInputs() {
            let materialInput = document.querySelectorAll('.material-input');

            if (materialInput) {
                materialInput.forEach(function (e) {
                    let input = e.querySelector('.material-input__input');
                    let label = e.querySelector('label');

                    if (input && label) {
                        input.addEventListener('focusin', (event) => {
                            label.classList.add('float-label');
                        })

                        input.addEventListener('focusout', (event) => {
                            if (input.value === "") {
                                label.classList.remove('float-label');
                            }
                        })
                    }

                })
            }
        }

        return (
            <form onSubmit={formik.handleSubmit} className="row justify-content-center">
                <div className="col-12">
                    <h4 className="mb-4">Odrzuć wizytówkę</h4>
                </div>
                <div className={`col-12 material-input ${formik.errors.reson && formik.touched.reson ? 'input-error' : ''} material-textarea mb-4`}>
                    <div>
                        <label className="ml-5">Podaj powód odrzucenia</label>
                        <textarea
                            id="reson"
                            name="reson"
                            as="textarea"
                            rows="3"
                            onChange={formik.handleChange}
                            value={formik.values.reson}
                            onFocus={() => handleFocusInputs()}
                            className="material-input__input h-100"></textarea>
                        {formik.errors.reson && formik.touched.reson ? (
                            <div className="material-input__error">{formik.errors.reson}</div>
                        ) : null}
                    </div>
                </div>
                <div className="col-12 col-sm-auto text-center">
                    <button type="submit" className="btn btn--primary w-100">Odrzuć</button>
                </div>
                <div className="col-12 col-sm-auto text-center">
                    <button type="submit" className="btn btn--outline h-100" onClick={handleToggleModal}>Anuluj</button>
                </div>
            </form>
        );
    };

    return (
        <Modal show={show} centered onHide={handleToggleModal}>
            <div className="modal-content">
                <div className="bug__form p-5">
                    <div className="row justify-content-center">
                        <RejectModal />
                    </div>
                </div>
            </div>
        </Modal>
    )
}

RejectAcceptanceModalComponent.propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    handleToggleModal: PropTypes.func.isRequired,
    show: PropTypes.bool.isRequired
};

export default RejectAcceptanceModalComponent;