import { IS_LOADING, SHOW_TOAST, CLEAR_TOAST, SET_CATEGORY_TREE } from '../actions/app.action';

const initial_state = {
    isLoading: false,
    categoryTree: [],
    toast: {
        show: false,
        type: 'info',
        message: ''
    }
}

const app = (state = initial_state, action) => {
    switch (action.type) {
        case IS_LOADING:
            return { ...state, isLoading: action.isLoading };
        case SET_CATEGORY_TREE:
            return { ...state, categoryTree: action.categoryTree };
        case SHOW_TOAST:
            return {
                ...state, toast: {
                    show: true,
                    type: action.toastType,
                    message: action.message
                }
            };
        case CLEAR_TOAST:
            return {
                ...state, toast: initial_state.toast
            };
        default:
            return state; 
    }
};

export default app;