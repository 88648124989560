import React from 'react';
import { Link } from "react-router-dom";

function ContentnavComponent(props) {
    const buildBreadcrumb = () => {
        return props && props.hrefs && props.hrefs.map((hrefInfo, index) => {
            let nextvalue = index + 1;
            return <li key={`href-${index}`} className={`breadcrumb-item ${props.hrefs[nextvalue] ? '' : 'active'}`}>
                <Link to={hrefInfo.route}>{hrefInfo.label}</Link>
            </li>
        })
    }

    return (
        <section id="breadcrumbs" className="breadcrumbs bg--primary pt-4">
            <div className="container-fluid">
                <div className="row">
                    <div className="col-12">
                        <nav aria-label="breadcrumb" className="ml-5">
                            <ol className="breadcrumb">
                                {buildBreadcrumb()}
                            </ol>
                        </nav>
                    </div>
                </div>
            </div>
        </section>
    )

}

export default ContentnavComponent;
