import React from 'react';
import PropTypes from 'prop-types';
import { Modal } from 'react-bootstrap';

import CloseIcon from '../../application/assets/img/filter_close.svg';

function ConfirmModalComponent(props) {

    const { show, handleToggleModal, submitText, contentText, cancelText, disableHandleSubmitButton, handleSubmit } = props;

    return (
        <Modal show={show} centered onHide={handleToggleModal}>
            <div className="modal-content">
                <div className="bug__form p-5">
                    <div className="row justify-content-center">
                        <div className="col-12">
                            <span id="modalClose" className="cursor-pointer modal-close" data-dismiss="modal" aria-label="Close">
                                <img src={CloseIcon} alt="" />
                            </span>
                            {disableHandleSubmitButton && <h4 className="mb-4">Usuń konto</h4>}
                            <p className="mb-5 text--light-grey">{contentText}</p>
                        </div>
                        {!disableHandleSubmitButton && <div className="col-auto text-center">
                            <button
                                type="button"
                                className="btn btn--secondary"
                                onClick={handleSubmit}>{submitText || 'Usuń'}</button>
                        </div>}
                        {!disableHandleSubmitButton ?
                            <div className="col-auto text-center" onClick={handleToggleModal}>
                                <button className="btn btn--outline h-100"> {cancelText || 'Anuluj'}</button>
                            </div> : <div className="col-auto text-center">
                                <button type="button" className="btn btn--primary" data-dismiss="modal" aria-label="Close" onClick={handleToggleModal}>Rozumiem</button>
                            </div>}
                    </div>
                </div>
            </div>
        </Modal>
    )
}

ConfirmModalComponent.propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    handleToggleModal: PropTypes.func.isRequired,
    show: PropTypes.bool.isRequired,
    submitText: PropTypes.string,
    contentText: PropTypes.string.isRequired,
    cancelText: PropTypes.string,
    disableHandleSubmitButton: PropTypes.bool
};

export default ConfirmModalComponent;