import React from 'react';
import ReminderComponent from '../components/reminder.component';

import { restartPasswordMail, restartPassword } from '../actions/auth.action';
import { connect } from 'react-redux'

function ReminderContainer(props) { 
    return (
        <ReminderComponent {...props} />
    );
} 

const mapDispatchToProps = (dispatch) => {
    return {
        restartPasswordMail: (email) => restartPasswordMail(email, dispatch),
        restartPassword: (data, token) => restartPassword(data, token, dispatch)
    }
};

export default connect(null, mapDispatchToProps)(ReminderContainer);
