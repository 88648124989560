import React, { useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { Modal } from 'react-bootstrap';
import Close from '../assets/img/close.svg'

import { sendError } from '../actions/app.action';

function HelpdeskModalComponent(props) {
   const { show, toggleModal } = props;

   const HelpdeskForm = () => {

      function handleFocusInputs() {
         let materialInput = document.querySelectorAll('.material-input');

         if (materialInput) {
            materialInput.forEach(function (e) {
               let input = e.querySelector('.material-input__input');
               let label = e.querySelector('label');

               if (input && label) {
                  input.addEventListener('focusin', (event) => {
                     label.classList.add('float-label');
                  })

                  input.addEventListener('focusout', (event) => {
                     if (input.value === "") {
                        label.classList.remove('float-label');
                     }
                  })
               }

            })
         }
      }

      const [errors, setApiErrors] = useState(null);
      const formik = useFormik({
         initialValues: {
            priority: 3,
            subject: 'Problem Beemployee.pl',
            description: '',
            group: '[BEEMPLOYEE]',
            name: 'Administrator BEEMPLOYEE',
            email: 'it@over-cloud.pl',
            cc_emails: '',
            attachments: []
         },
         validationSchema: Yup.object().shape({
            cc_emails: Yup.string()
               .required('Wartość jest wymagana'),
            description: Yup.string()
               .required('Wartość jest wymagana'),
            attachments: Yup.mixed()
         }),
         onSubmit: (values, actions) => {

            values.cc_emails = [values.cc_emails];

            // próbujemy się rejestrować
            props.sendError(values).then(
               () => {
                  // jak się uda to spadamy stąd
                  toggleModal();
               },
               (errors) => {
                  // nie udało się to podstawiamy błędy
                  setApiErrors(errors);
               });
         }
      });

      return (
         <form className="row justify-content-center" onSubmit={formik.handleSubmit}>
            <div className="col-12">
               <span id="modalClose" className="cursor-pointer modal-close" data-dismiss="modal"
                  aria-label="Close"><img src={Close} alt="" onClick={toggleModal} />
               </span>
               <h4 className="mb-4">Zgłoś problem</h4>
               <p className="mb-5 text--light-grey">Prosimy o szczegółowy opis napotkanego problemu.</p>
            </div>
            {/* EMAIL */}
            <div className={`col-12 material-input mb-4 ${(formik.errors.cc_emails && formik.touched.cc_emails) || (errors && errors.cc_emails) ? "input-error" : ""}`}>
               <div className="d-flex align-items-center">
                  <label htmlFor="bugMail" className="ml-5">E-mail<span className="required"> *</span></label>
                  <input
                     id="cc_emails"
                     name="cc_emails"
                     type="email"
                     onChange={formik.handleChange}
                     value={formik.values.cc_emails}
                     className="material-input__input h-100"
                     onFocus={() => handleFocusInputs()} />
               </div>
               {formik.errors.cc_emails && formik.touched.cc_emails ? (
                  <div className="material-input__error">{formik.errors.cc_emails}</div>
               ) : null}
               {errors && errors.cc_emails ? (
                  <div className="material-input__error">{errors.cc_emails}</div>
               ) : null}
            </div>
            {/* OPIS BŁĘDU */}
            <div className={`col-12 material-input material-textarea mb-4 ${(formik.errors.description && formik.touched.description) || (errors && errors.description) ? "input-error" : ""}`}>
               <div>
                  <label htmlFor="bugMsg" className="ml-5">Opis błędu<span className="required"> *</span></label>
                  <textarea
                     id="description"
                     name="description"
                     as="textarea"
                     rows="7"
                     onChange={formik.handleChange}
                     value={formik.values.description}
                     className="material-input__input h-100"></textarea>
               </div>
               {formik.errors.description && formik.touched.description ? (
                  <div className="material-input__error">{formik.errors.description}</div>
               ) : null}
               {errors && errors.description ? (
                  <div className="material-input__error">{errors.description}</div>
               ) : null}
            </div>
            <div className="col-auto text-center">
               <button className="btn btn--primary" type="submit">Skontaktuj się</button>
            </div>
         </form>
      )
   };

   return (
      <Modal show={show} centered onHide={toggleModal} >
         <div className="modal-content">
            <div className="bug__form p-5">
               <HelpdeskForm />
            </div>
         </div>
      </Modal>
   );
}

HelpdeskModalComponent.propTypes = {
   show: PropTypes.bool.isRequired,
   toggleModal: PropTypes.func.isRequired
};

const mapDispatchToProps = (dispatch) => {
   return {
      sendError: (values) => sendError(values, dispatch)
   }
};

export default connect(null, mapDispatchToProps)(HelpdeskModalComponent);
