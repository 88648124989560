import {
    SET_CARDS_TO_ACCEPTANCE,
    SET_OFFERS_TO_ACCEPTANCE,
    SET_USERS,
    CLEAR_CARDS,
    CLEAR_OFFERS,
    CLEAR_USERS,
    CLEAR_CARD_DETAILS,
    CLEAR_OFFER_DETAILS,
    SET_CARD_DETAILS,
    SET_OFFER_DETAILS,
    SET_SEARCH_QUERY,
    CLEAR_SEARCH_QUERY
} from '../actions/admin.action';

const initial_state = {
    cards: [],
    offers: [],
    users: [],
    searchQuery: null,
    cardDetails: null,
    offerDetails: null
}

const admin = (state = initial_state, action) => {
    switch (action.type) {
        case SET_CARDS_TO_ACCEPTANCE:
            return { ...state, cards: action.cards };
        case SET_OFFERS_TO_ACCEPTANCE:
            return { ...state, cards: action.offers };
        case SET_USERS:
            return { ...state, users: action.users };
        case SET_CARD_DETAILS:
            return { ...state, cardDetails: action.card };
        case SET_OFFER_DETAILS:
            return { ...state, offerDetails: action.offer };
        case SET_SEARCH_QUERY:
            return { ...state, searchQuery: action.query };
        case CLEAR_CARDS:
            return { ...state, cards: [] };
        case CLEAR_OFFERS:
            return { ...state, offers: [] };
        case CLEAR_USERS:
            return { ...state, users: [] };
        case CLEAR_CARD_DETAILS:
            return { ...state, cardDetails: null };
        case CLEAR_OFFER_DETAILS:
            return { ...state, offerDetails: null };
        case CLEAR_SEARCH_QUERY:
            return { ...state, searchQuery: null };
        default:
            return state;
    }
};

export default admin;