import React, { useEffect } from 'react';

import BeeIcon from '../../application/assets/img/bee_icon.svg';

function UsersComponent(props) {

    const { users, getUsers, clearUsers, activateUser, sendActivateMail, isLoading, handleSearchRecords, query } = props;

    useEffect(() => {
        getUsers();
        return () => {
            clearUsers();
        }
    }, [getUsers, clearUsers]);

    function handleFocusInputs() {
        let materialInput = document.querySelectorAll('.material-input');

        if (materialInput) {
            materialInput.forEach(function (e) {
                let input = e.querySelector('.material-input__input');
                let label = e.querySelector('label');

                if (input && label) {
                    input.addEventListener('focusin', (event) => {
                        label.classList.add('float-label');
                    })

                    input.addEventListener('focusout', (event) => {
                        if (input.value === "") {
                            label.classList.remove('float-label');
                        }
                    })
                }

            })
        }
    }

    function getUsersList() {
        return users.map((user, index) => {
            return (
                <div key={`single-acceptance-card=${index}`} className="row my-5">
                    <div className="col-12">
                        <div className="admin__content__item border--light-grey p-4">
                            <div className="row justify-content-center justify-content-sm-between align-items-center">
                                <div className="col">
                                    <div className="row align-items-center">
                                        <div className="col-12 col-md-auto">
                                            <div className="hex__icon zi-1">
                                                <img src={BeeIcon} alt="" className="w-100" />
                                            </div>
                                        </div>
                                        <div className="col-12 col-md-auto text-center mt-4 mt-md-0">
                                            <p className="admin__id">{user.name ? `${user.name} ${user.surname}` : user.companyName}</p>
                                        </div>
                                    </div>
                                </div>
                                {!user.active && <div className="col-12 col-md-auto d-sm-flex text-center text-sm-right">
                                    <p className="admin__content__item__status font-weight-bold m-0 text--light-grey">Brak aktywacji</p>
                                </div>}
                                {!user.active && <div className="col-auto d-sm-flex">
                                    <button id="acceptOffer" className="btn btn--primary mr-4 w-100" onClick={() => activateUser(user._id)}>Aktywuj</button>
                                    <button id="rejectOffer" className="btn btn--secondary w-100 mt-4 mt-sm-0" onClick={() => sendActivateMail(user.email)}>Wyślij maila aktywacyjnego</button>
                                </div>}
                            </div>
                        </div>
                    </div>
                </div>
            )
        });
    }

    return (
        <div className="tab-pane">
            <section className="admin__content admin__content--offer">
                <div className="container-fluid">
                    <div className="row justify-content-center">
                        <div className="col-12 col-xl-8 mb-5">
                            <div className="admin__info bg-white p-5">
                                <div className="row">
                                    <div className="col material-input">
                                        <div className="d-flex align-items-center position-relative">
                                            <label className="ml-5">Wpisz nazwę użytkownika</label>
                                            <input
                                                id="query"
                                                name="query"
                                                type="text"
                                                autoComplete="off"
                                                onChange={(e) => handleSearchRecords(e.target.value)}
                                                value={query}
                                                onFocus={() => handleFocusInputs()}
                                                className="material-input__input h-100" />
                                        </div>
                                    </div>
                                </div>
                                {getUsersList()}
                                {users.length === 0 && !isLoading && <div className="row my-5">
                                    <div className="col-12">
                                        <div className="admin__content__item admin__content__item--empty p-4">
                                            <p className="m-0">Brak użytkowników</p>
                                        </div>
                                    </div>
                                </div>}
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    )

}

export default UsersComponent;