import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom'
import CloseIcon from '../assets/img/filter_close.svg';

function FilterResultsComponent(props) {
   const { query, setQuery, places, setPlaces, totalCount, handleOnDeletePlaces, handleOnDeleteQuery, resetFilters, showMobileFilters } = props;
   const [activeFilters, setActiveFilters] = useState([]);
   const location = useLocation();

   useEffect(() => {
      let tmpActiveFilters = [];
      let tmpQuery = query.length > 0 && query.map((val) => {
         return { type: 'query', name: val.name, value: val.value }
      });
      let tmpPlaces = places.length > 0 && places.map((val) => {
         return { type: 'place', name: val.name, value: val.value }
      });

      if (tmpQuery.length > 0 && tmpPlaces.length > 0) {
         tmpActiveFilters = tmpQuery.concat(tmpPlaces);
      } else if (tmpQuery.length > 0) {
         tmpActiveFilters = tmpQuery;
      } else if (tmpPlaces.length > 0) tmpActiveFilters = tmpPlaces;

      setActiveFilters(tmpActiveFilters);
   }, [query, places]);

   function removeValueFromSelected(value) {
      let filterSelectedValues = activeFilters.filter(selectedValue => {
         return selectedValue.value !== value.value && { name: selectedValue.name, type: selectedValue.type }
      });

      setActiveFilters(filterSelectedValues);

      if (value.type === 'query') {
         let tmpQuery = query.filter(result => result.name !== value.name);

         setQuery(tmpQuery);
         handleOnDeleteQuery(tmpQuery);
      }
      if (value.type === 'place') {
         let tmpPlaces = places.filter(result => result.name !== value.name);

         setPlaces(tmpPlaces);
         handleOnDeletePlaces(tmpPlaces);
      }
   }

   return (
      <section id="filterResult" className={`filter-result mt-4 ${showMobileFilters ? '' : 'd-none'} d-xl-block`}>
         <div className="container-fluid">
            <div className="row">
               <div className="col-12">
                  <p className="mb-0">Wyszukiwane filtry:</p>
               </div>
            </div>
            <div className="row no-gutters">
               <div className="col-auto">
                  <button className="btn btn--outline mr-4 mt-4 filter-result__delete" onClick={() => resetFilters()}>Usuń wszystkie filtry</button>
               </div>
               {activeFilters && activeFilters.length > 0 && activeFilters.map((filter, index) => {
                  return <div key={`active-filter-${index}`} className="col-auto">
                     <button className="btn btn--filter mr-4 mt-4 filter-result__single">{filter.name}
                        <span className="ml-5">
                           <img src={CloseIcon} alt="" onClick={() => removeValueFromSelected(filter)} />
                        </span>
                     </button>
                  </div>
               })}
            </div>
            <div className="row mt-4">
               <div className="col-12">
                  <p className="filter-result__number">Poznaj: <span>{totalCount} {location.pathname === '/kandydaci' ? 'wizytówek' : 'ofert'}</span></p>
               </div>
            </div>
         </div>
      </section>
   )
}

export default FilterResultsComponent;