import React from 'react';
import LogoCloud from '../assets/img/cloud_logo.svg';

import { useLocation, matchPath } from "react-router-dom";

function Footer() {
    const location = useLocation();
    const whiteFooterPathNames = ['/login', '/reminder', '/register', '/cards', '/profile', '/offers', '/offer', '/card'];
    const pathMatch = matchPath(location.pathname, {
        path: [`/cards/:cardId`, `/card/:cardId`, `/offer/:offerId`, `/offers/:offerId`, '/admin/card/:cardId', '/admin/offer/:offerId'],
    });

    const FooterWhite = () => {
        return (
            <div>
                <footer id="footer" className="footer-white">
                    <div className="container-fluid">
                        <div className="row justify-content-between">
                            <div className="col-auto mb-5 mb-md-0">
                                <div className="footer__logo d-flex align-items-center">
                                    <p className="m-0 mr-4">powered by</p><a href="https://over-cloud.pl/" ><img src={LogoCloud} alt="" /></a>
                                </div>
                            </div>
                            <div className="col-auto">
                                <span className="footer__phone  mr-5">infolinia: <span className="text--primary-dark">+48 660 440 558</span></span>
                                <span className="footer__mail">kontakt: <a href="mailto:it@over-cloud.pl">it@over-cloud.pl</a></span>
                            </div>
                        </div>
                    </div>
                </footer>
            </div>
        )
    }

    if (whiteFooterPathNames.indexOf(location.pathname) > -1 || (pathMatch && pathMatch.isExact)) return <FooterWhite />
    return (
        <div>
            <footer id="footer" className="footer">
                <div className="container-fluid">
                    <div className="row justify-content-between">
                        <div className="col-auto mb-5 mb-md-0">
                            <div className="footer__logo d-flex align-items-center">
                                <p className="text-white m-0 mr-4">powered by</p><a href="https://over-cloud.pl/" ><img src={LogoCloud} alt="" /></a>
                            </div>
                        </div>
                        <div className="col-auto">
                            <span className="footer__phone text-white mr-5">infolinia: <span className="text--primary-dark">+48 660 440 558</span></span>
                            <span className="footer__mail text-white">kontakt: <a href="mailto:it@over-cloud.pl">it@over-cloud.pl</a></span>
                        </div>
                    </div>
                </div>
            </footer>
        </div>
    );
}


export default Footer;
