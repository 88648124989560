import {
    SET_CARDS,
    CLEAR_CARDS,
    SET_OFFERS,
    CLEAR_OFFERS,
    SET_LIST_TYPE,
    SET_CARD_DETAILS,
    CLEAR_CARD_DETAILS,
    SET_OFFER_DETAILS,
    CLEAR_OFFER_DETAILS,
    SET_OFFSET,
    CLEAR_OFFSET,
    SET_MATCHES,
    SET_COUNT,
    SET_PAGE,
    CLEAR_PAGE
} from '../actions/home.action';

const initial_state = {
    listType: 'cards',
    matches: null,
    cards: [],
    offers: [],
    total: 0,
    limit: 20,
    offset: 0,
    cardDetails: null,
    offerDetails: null,
    cardCount: null,
    offerCount: null,
    page: 1
}

const home = (state = initial_state, action) => {
    switch (action.type) {
        case SET_CARDS:
            return { ...state, cards: action.cards, total: action.total };
        case SET_LIST_TYPE:
            return { ...state, listType: action.listType };
        case CLEAR_CARDS:
            return { ...state, cards: [], total: 0, matches: null };
        case SET_OFFERS:
            return { ...state, offers: action.offers, total: action.total };
        case CLEAR_OFFERS:
            return { ...state, offers: [], total: 0, matches: null };
        case SET_CARD_DETAILS:
            return { ...state, cardDetails: action.details };
        case CLEAR_CARD_DETAILS:
            return { ...state, cardDetails: null };
        case SET_OFFER_DETAILS:
            return { ...state, offerDetails: action.details };
        case CLEAR_OFFER_DETAILS:
            return { ...state, offerDetails: null };
        case SET_OFFSET:
            return { ...state, offset: action.offset };
        case CLEAR_OFFSET:
            return { ...state, offset: 0 };
        case SET_MATCHES:
            return { ...state, matches: action.matches, listType: action.listType };
        case SET_COUNT:
            return { ...state, cardCount: action.cardCount, offerCount: action.offerCount };
        case SET_PAGE:
            return { ...state, page: parseInt(action.page) };
        case CLEAR_PAGE:
            return { ...state, page: 1 };
        default:
            return state;
    }
};

export default home;