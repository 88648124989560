import React from 'react';
import RegisterComponent from '../components/register.component';

import { connect } from 'react-redux'
import { signIn } from '../actions/auth.action';

function RegisterContainer(props) {
    return (
        <RegisterComponent {...props} />
    );
}

const mapDispatchToProps = (dispatch) => {
    return {
        signIn: (registerData) => signIn(registerData, dispatch)
    }
};

export default connect(null, mapDispatchToProps)(RegisterContainer);
