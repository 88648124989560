import React from 'react';
import { connect } from 'react-redux';

import { searchRecords } from '../actions/admin.action';
import AcceptanceComponent from '../components/acceptance.component';

function AcceptanceContainer(props) {
    return (
        <AcceptanceComponent {...props} />
    );
}

const mapDispatchToProps = (dispatch) => {
    return {
        searchRecords: (query, type) => dispatch(searchRecords(query, type))
    }
};

export default connect(null, mapDispatchToProps)(AcceptanceContainer);
