import React from 'react';
import { connect } from 'react-redux';

import { getCardDetails, clearCardDetails, cardAcceptance } from '../actions/admin.action';
import { downloadCv } from '../../panel/actions/profile.action';
import CardDetailsComponent from '../components/cardDetails.component';

function CardDetailsContainer(props) {
    return (
        <CardDetailsComponent {...props} />
    );
}

const mapStateToProps = (state) => {
    return {
        cardDetails: state.admin.cardDetails
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        getCardDetails: (cardId) => dispatch(getCardDetails(cardId)),
        clearCardDetails: () => dispatch(clearCardDetails()),
        cardAcceptance: (cardId, data) => dispatch(cardAcceptance(cardId, data)),
        downloadCv: (fileName) => { dispatch(downloadCv(fileName)) }
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(CardDetailsContainer);
