
import Api from '../../application/service/api.service';
import { setCategoriesTree } from '../../application/actions/filter.action';

const namespace = 'APP';
export const IS_LOADING = `IS_LOADING_${namespace}`;
export const SET_CATEGORY_TREE = `SET_CATEGORY_TREE_${namespace}`;
export const SHOW_TOAST = `SHOW_TOAST_${namespace}`;
export const CLEAR_TOAST = `CLEAR_TOAST_${namespace}`;

export const isLoading = (isLoading) => {
    return dispatch => {
        if (!isLoading) {
            setTimeout(() => { // opóżniamy loading by dane mogły się podstawić pod formularz
                dispatch({
                    type: IS_LOADING,
                    isLoading
                });
            }, 0);
        } else {
            dispatch({
                type: IS_LOADING,
                isLoading
            });
        }
    }
}


export const clearToast = () => ({
    type: CLEAR_TOAST
});

export const setCategoryTree = (categoryTree) => ({
    type: SET_CATEGORY_TREE,
    categoryTree
});

export const showToast = (toastType, message) => {
    return function (dispatch) {

        dispatch({
            type: SHOW_TOAST,
            toastType,
            message
        });

        setTimeout(() => {
            dispatch(clearToast());
        }, 3000);

    }
};

export const getAllAdministrativeAreaLevel1 = () => {
    return [
        { "name": "dolnośląskie", "value": "dolnośląskie" },
        { "name": "kujawsko-pomorskie", "value": "kujawsko-pomorskie" },
        { "name": "lubelskie", "value": "lubelskie" },
        { "name": "lubuskie", "value": "lubuskie" },
        { "name": "łódzkie", "value": "łódzkie" },
        { "name": "małopolskie", "value": "małopolskie" },
        { "name": "mazowieckie", "value": "mazowieckie" },
        { "name": "opolskie", "value": "opolskie" },
        { "name": "podkarpackie", "value": "podkarpackie" },
        { "name": "podlaskie", "value": "podlaskie" },
        { "name": "pomorskie", "value": "pomorskie" },
        { "name": "śląskie", "value": "śląskie" },
        { "name": "świętokrzyskie", "value": "świętokrzyskie" },
        { "name": "warmińsko-mazurskie", "value": "warmińsko-mazurskie" },
        { "name": "wielkopolskie", "value": "wielkopolskie" },
        { "name": "zachodniopomorskie", "value": "zachodniopomorskie" }
    ];
}

export function getPlaces(type, query, token) {
    return new Promise((resolve, reject) => {
        return Api.get('application/getLocation', {
            types: type,
            query: query,
            token: token
        }).then((result) => {

            if (result.success) {
                return resolve(result.data);
            } else if ('errors' in result) {
                return reject(result.errors);
            }
        });
    });
}

export function getCatgoryTree() {
    return dispatch => {

        // globalny is loading 
        // dispatch(isLoading(true));

        return Api.get('application/getTree').then((result) => {

            // zawsze ukrywamy globalny is loading
            // dispatch(isLoading(false));

            if (result.success) {

                // ustawienie drzewa dla filtrów
                dispatch(setCategoriesTree(result.document));

                const categoriesTmp = [];

                result.document.map((category) => {
                    categoriesTmp.push({ name: category.name, value: category.id });
                    if (category.children && category.children.length > 0) {
                        category.children.map(childrenCat => {
                            return categoriesTmp.push({ name: `${category.name} > ${childrenCat.name}`, value: childrenCat.id });
                        });
                    }
                    return true;
                });

                // przygotowane kategorie do "selektów"
                dispatch(setCategoryTree(categoriesTmp));
            }
        });
    };
}

export function sendError(data, dispatch) {
    return new Promise((resolve, reject) => {

        // globalny is loading 
        dispatch(isLoading(true));
        data.description += getBrowserInfo();
        
        return Api.customPost('https://helpdesk.overcloud.usermd.net/helpdesk/ticket', data).then((result) => {

            // globalny is loading 
            dispatch(isLoading(false));

            if (result.success) {
                dispatch(showToast('info', 'Zgłoszenie zostało wysłane do administracji, dziękujemy!'));
                return resolve();
            } else if ('errors' in result) {
                dispatch(showToast('alert', 'Błąd podczas wysyłania zgłoszenia błędu'));
                return reject(result.errors);
            }


        });
    });
}

function getBrowserInfo() {
    try {
        let nAgt = navigator.userAgent;
        let browserName = navigator.appName;
        let fullVersion = '' + parseFloat(navigator.appVersion);
        let majorVersion = parseInt(navigator.appVersion, 10);
        let nameOffset, verOffset, ix;
        let systemName = "Nieznany";
        if (navigator.userAgent.indexOf("Win") !== -1) systemName =
            "Windows OS";
        if (navigator.userAgent.indexOf("Mac") !== -1) systemName =
            "Macintosh";
        if (navigator.userAgent.indexOf("Linux") !== -1) systemName =
            "Linux OS";
        if (navigator.userAgent.indexOf("Android") !== -1) systemName =
            "Android OS";
        if (navigator.userAgent.indexOf("like Mac") !== -1) systemName =
            "iOS";

        // In Opera 15+, the true version is after "OPR/" 
        if ((verOffset = nAgt.indexOf("OPR/")) !== -1) {
            browserName = "Opera";
            fullVersion = nAgt.substring(verOffset + 4);
        }
        // In older Opera, the true version is after "Opera" or after "Version"
        else if ((verOffset = nAgt.indexOf("Opera")) !== -1) {
            browserName = "Opera";
            fullVersion = nAgt.substring(verOffset + 6);
            if ((verOffset = nAgt.indexOf("Version")) !== -1)
                fullVersion = nAgt.substring(verOffset + 8);
        }
        // In MSIE, the true version is after "MSIE" in userAgent
        else if ((verOffset = nAgt.indexOf("MSIE")) !== -1) {
            browserName = "Microsoft Internet Explorer";
            fullVersion = nAgt.substring(verOffset + 5);
        }
        // In Chrome, the true version is after "Chrome" 
        else if ((verOffset = nAgt.indexOf("Chrome")) !== -1) {
            browserName = "Chrome";
            fullVersion = nAgt.substring(verOffset + 7);
        }
        // In Safari, the true version is after "Safari" or after "Version" 
        else if ((verOffset = nAgt.indexOf("Safari")) !== -1) {
            browserName = "Safari";
            fullVersion = nAgt.substring(verOffset + 7);
            if ((verOffset = nAgt.indexOf("Version")) !== -1)
                fullVersion = nAgt.substring(verOffset + 8);
        }
        // In Firefox, the true version is after "Firefox" 
        else if ((verOffset = nAgt.indexOf("Firefox")) !== -1) {
            browserName = "Firefox";
            fullVersion = nAgt.substring(verOffset + 8);
        }
        // In most other browsers, "name/version" is at the end of userAgent 
        else if ((nameOffset = nAgt.lastIndexOf(' ') + 1) <
            (verOffset = nAgt.lastIndexOf('/'))) {
            browserName = nAgt.substring(nameOffset, verOffset);
            fullVersion = nAgt.substring(verOffset + 1);
            if (browserName.toLowerCase() === browserName.toUpperCase()) {
                browserName = navigator.appName;
            }
        }
        // trim the fullVersion string at semicolon/space if present
        if ((ix = fullVersion.indexOf(";")) !== -1)
            fullVersion = fullVersion.substring(0, ix);
        if ((ix = fullVersion.indexOf(" ")) !== -1)
            fullVersion = fullVersion.substring(0, ix);

        majorVersion = parseInt('' + fullVersion, 10);
        if (isNaN(majorVersion)) {
            fullVersion = '' + parseFloat(navigator.appVersion);
            majorVersion = parseInt(navigator.appVersion, 10);
        }
        return `\n -------------------------------- \n Przeglądarka: ${browserName} \n Wersja: ${majorVersion} \n Numer wersji: ${fullVersion} \n System: ${systemName}`;
    } catch (err) {
        return `\n -------------------------------- \n Brak danych o systemie: ${err.message || 'Nieznany błąd'}`
    }
}