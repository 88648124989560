import React from 'react';
import { render } from 'react-dom';
import { Provider } from 'react-redux';
import { applyMiddleware, createStore, compose } from 'redux';
import thunkMiddleware from 'redux-thunk';
import Api from './application/service/api.service';
import rootReducer from './application/reducers/main.reducers';

// główny kontener aplikacji, tutaj znajdziemy routing aplikacji
import App from './application/containers/root.container';

const store = createStore(
  rootReducer,
  compose(
    applyMiddleware(thunkMiddleware),
    // window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
  )
);

Api.setDispatch(store.dispatch);

render(
  <Provider store={store}>
    <App />
  </Provider>,
  document.getElementById('root')
)