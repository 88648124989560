import * as Yup from 'yup';

export const SignupPersonInitialState = {
    name: '',
    surname: '',
    type: 'person',
    email: '',
    phone: '',
    password: '',
    passwordConfirm: '',
    agreements: {
        regulationsAcceptance: false,
        privacyPolicyAcceptance: false
    },
}

export const SignupPersonSchema = Yup.object().shape({
    name: Yup.string()
        .required('Wartość jest wymagana'),
    type: Yup.string()
        .required('Wartość jest wymagana'),
    surname: Yup.string()
        .required('Wartość jest wymagana'),
    email: Yup.string()
        .email('E-mail jest błędny')
        .required('Wartość jest wymagana'),
    phone: Yup.string(),
    password: Yup.string()
        .required('Wartość jest wymagana'),
    passwordConfirm: Yup.string()
        .oneOf([Yup.ref('password'), null], 'Hasła do siebie nie pasują')
        .required('Powtórz hasło'),
    agreements: Yup.object().shape(
        {
            regulationsAcceptance: Yup.bool().oneOf([true], 'Regulamin musi zostać zaakceptowany'),
            privacyPolicyAcceptance: Yup.bool().oneOf([true], 'Polityka prywatności musi zostać zaakceptowana')
        })
});

export const SignupCompanyInitialState = {
    companyName: '',
    NIP: '',
    type: 'company',
    email: '',
    password: '',
    passwordConfirm: '',
    agreements: {
        regulationsAcceptance: false,
        privacyPolicyAcceptance: false
    }
}

export const SignupCompanySchema = Yup.object().shape({
    companyName: Yup.string()
        .required('Wartość jest wymagana'),
    type: Yup.string()
        .required('Wartość jest wymagana'),
    email: Yup.string()
        .email('E-mail jest błędny')
        .required('Wartość jest wymagana'),
    phone: Yup.string(),
    password: Yup.string()
        .required('Wartość jest wymagana'),
    passwordConfirm: Yup.string()
        .oneOf([Yup.ref('password'), null], 'Hasła do siebie nie pasują')
        .required('Powtórz hasło'),
    agreements: Yup.object().shape(
        {
            regulationsAcceptance: Yup.bool().oneOf([true], 'Regulamin musi zostać zaakceptowany'),
            privacyPolicyAcceptance: Yup.bool().oneOf([true], 'Polityka prywatności musi zostać zaakceptowana')
        })
});

