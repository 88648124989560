import React, { Fragment, useState, useEffect } from 'react';

import { connect } from 'react-redux';
import { getProfile, clearProfile, saveProfile, uploadFile, deleteFile, downloadCv, deleteAccount } from '../actions/profile.action';
import { getAllAdministrativeAreaLevel1, getPlaces, showToast } from '../../application/actions/app.action';

import ConfirmModalComponent from '../../application/components/confirmModal.component';
import CompanyProfileComponent from '../components/companyProfile.component';
import PersonProfileComponent from '../components/personProfile.component';

function ProfileContainer(props) {

    const { loggedUser } = props;
    const [showModal, setShowModal] = useState(false);

    useEffect(() => {
        if (loggedUser && loggedUser._id && !loggedUser.profileCompleted) {
            setShowModal(true);
        }
    }, [loggedUser]);

    function handleUploadCV(event) {
        const uploadedFile = event.currentTarget.files[0];

        if (uploadedFile.type !== "application/pdf") {
            props.showToast("alert", "Wymagany format pliku to PDF");
            return Promise.resolve();
        }

        return props.uploadFile(uploadedFile, loggedUser._id);
    }

    function handleDeleteCV() {
        return props.deleteFile(loggedUser._id);
    }

    function getProfileView() {
        const { loggedUser } = props;

        if (loggedUser && loggedUser.type === "person") {
            return <PersonProfileComponent {...props} handleUploadCV={handleUploadCV} handleDeleteCV={handleDeleteCV} />;
        } else {
            return <CompanyProfileComponent {...props} />;
        }
    }

    function toggleModal() {
        setShowModal(!showModal);
    }

    return (
        <Fragment>
            <ConfirmModalComponent
                show={showModal}
                disableHandleSubmitButton={true}
                handleSubmit={() => { }} handleToggleModal={toggleModal}
                contentText="Aby w pełni korzystać ze wszystkich funkcjonalności systemu, uzupełnij swój profil o wymagane dane (*)."
                cancelText="Rozumiem" />
            {getProfileView()}
        </Fragment>
    );
}

const mapStateToProps = (state) => {
    return {
        profile: state.profile.user,
        profileFileExist: state.profile.profileFileExist,
        loggedUser: state.auth.loggedUser
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        uploadFile: (file, loggedUserId) => uploadFile(file, dispatch, loggedUserId), // jak nie potrzebujemy promise
        deleteFile: (loggedUserId) => deleteFile(dispatch, loggedUserId), // jak nie potrzebujemy promise
        showToast: (toastType, message) => dispatch(showToast(toastType, message)), // jak nie potrzebujemy promise
        getProfile: () => dispatch(getProfile()), // jak nie potrzebujemy promise
        clearProfile: () => dispatch(clearProfile()), // jak nie potrzebujemy promise
        saveProfile: (profileData) => saveProfile(profileData, dispatch), // jak potrzebujemy promise,
        getAllAdministrativeAreaLevel1: () => getAllAdministrativeAreaLevel1(),
        getPlaces: (query, token) => getPlaces('cities', query, token),
        downloadCv: (fileName) => { dispatch(downloadCv(fileName)) },
        deleteAccount: () => dispatch(deleteAccount())
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(ProfileContainer);
