import React from 'react';
import SingleOfferComponent from '../components/singleOffer.component';

import { connect } from 'react-redux';
import { getOfferDetails, clearOfferDetails, sendContactMail } from '../actions/home.action';

function SingleOfferContainer(props) {
    return (
        <SingleOfferComponent {...props} />
    );
}

const mapStateToProps = (state) => {
    return {
        details: state.home.offerDetails
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        getOfferDetails: (offerId) => dispatch(getOfferDetails(offerId)), // kiedy nie potrzebujemy promise
        clearOfferDetails: () => dispatch(clearOfferDetails()),  // kiedy nie potrzebujemy promise
        sendContactMail: (data) => sendContactMail(data, dispatch)  // kiedy potrzebujemy promise
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(SingleOfferContainer);
