import React from 'react';
import { connect } from 'react-redux'
import BeeLoaderGIF from '../assets/img/bee-loader.gif';

const LoaderComponent = ({ isLoading, isAuthLoading }) => {
    return (
        <div className={`global-loader-wrapper ${isLoading || isAuthLoading ? 'show-loader' : ''}`}>
            <div className="global-loader">
                <img alt="loader aplikacji" src={BeeLoaderGIF} height="65px" />
            </div>
        </div>
    )
}

const mapStateToProps = (state) => ({
    isLoading: state.app.isLoading,
    isAuthLoading: state.auth.isAuthLoading,
});

export default connect(mapStateToProps, null)(LoaderComponent);
