
import Api from '../../application/service/api.service';
import { isLoading, showToast } from '../../application/actions/app.action';

const namespace = 'ADMIN';

export const SET_CARDS_TO_ACCEPTANCE = `SET_CARDS_TO_ACCEPTANCE_${namespace}`;
export const SET_OFFERS_TO_ACCEPTANCE = `SET_OFFERS_TO_ACCEPTANCE_${namespace}`;
export const SET_USERS = `SET_USERS_${namespace}`;
export const CLEAR_CARDS = `CLEAR_CARDS_${namespace}`;
export const CLEAR_OFFERS = `CLEAR_OFFERS_${namespace}`;
export const CLEAR_USERS = `CLEAR_USERS_${namespace}`;
export const SET_CARD_DETAILS = `SET_CARD_DETAILS_${namespace}`;
export const SET_OFFER_DETAILS = `SET_OFFER_DETAILS_${namespace}`;
export const CLEAR_CARD_DETAILS = `CLEAR_CARD_DETAILS_${namespace}`;
export const CLEAR_OFFER_DETAILS = `CLEAR_OFFER_DETAILS_${namespace}`;
export const SET_SEARCH_QUERY = `SET_SEARCH_QUERY_${namespace}`;
export const CLEAR_SEARCH_QUERY = `CLEAR_SEARCH_QUERY_${namespace}`;

export const setSearchQuery = (query) => ({
    type: SET_SEARCH_QUERY,
    query
});

export const clearSearchQuery = () => ({
    type: CLEAR_SEARCH_QUERY
});

export const clearOffers = () => ({
    type: CLEAR_OFFERS
});

export const clearCards = () => ({
    type: CLEAR_CARDS
});

export const clearUsers = () => ({
    type: CLEAR_USERS
});

export const clearCardDetails = () => ({
    type: CLEAR_CARD_DETAILS
});

export const clearOfferDetails = () => ({
    type: CLEAR_OFFER_DETAILS
});

export const setCardDetails = (card) => ({
    type: SET_CARD_DETAILS,
    card
});

export const setOfferDetails = (offer) => ({
    type: SET_OFFER_DETAILS,
    offer
});

export const setCards = (cards) => ({
    type: SET_CARDS_TO_ACCEPTANCE,
    cards
});

export const setOffers = (offers) => ({
    type: SET_OFFERS_TO_ACCEPTANCE,
    offers
});

export const setUsers = (users) => ({
    type: SET_USERS,
    users
});

export function cardAcceptance(cardId, data) {
    return dispatch => {
        // globalny is loading 
        dispatch(isLoading(true));
        return Api.put('admin/changeCardStatus', data, cardId).then((result) => {
            if (result.success) {
                dispatch(getCards())
                dispatch(showToast('info', 'Status wizytówki został zmieniony'));
            } else if ('errors' in result) {
                dispatch(showToast('alert', 'Błąd podczas zmiany statusu wizytówki'));
            }
            dispatch(isLoading(true));
        });
    };
}

export function offerAcceptance(offerId, data) {
    return dispatch => {
        return Api.put('admin/changeOfferStatus', data, offerId).then((result) => {

            if (result.success) {
                dispatch(getOffers())
                dispatch(showToast('info', 'Status oferty został zmieniony'));
            } else if ('errors' in result) {
                dispatch(showToast('alert', 'Błąd podczas zmiany statusu oferty'));
            }
        });
    };
}

export function activateUser(userId) {
    return dispatch => {
        // globalny is loading 
        dispatch(isLoading(true));
        return Api.put('admin/activateUser', { active: true }, userId).then((result) => {
            if (result.success) {
                dispatch(getUsers())
                dispatch(showToast('info', 'Użytkownik został aktywowany'));
            } else if ('errors' in result) {
                dispatch(showToast('alert', 'Błąd podczas aktywacji użytkownika'));
            }
            dispatch(isLoading(false));
        });
    };
}

export function sendActivateMail(userEmail) {
    return dispatch => {
        // globalny is loading 
        dispatch(isLoading(true));
        return Api.post('application/resendActivationEmail', { email: userEmail }).then((result) => {
            if (result.success) {
                dispatch(showToast('info', 'Mail aktywacyjny został wysłany'));
            } else if ('errors' in result) {
                dispatch(showToast('alert', 'Błąd podczas wysyłania maila aktywacyjnego'));
            }
            dispatch(isLoading(false));
        });
    };
}


export function getCards() {
    return (dispatch, getState) => {

        // globalny is loading 
        dispatch(isLoading(true));

        const { admin: { searchQuery } } = getState();

        let params = {
            "sort[status]": 1,
            "limit": 1000,
        };

        if (searchQuery) {
            params["cardNumber[in]"] = searchQuery;
        }


        return Api.get('admin/card', params).then((result) => {

            if (result.success) {
                dispatch(setCards(result.documents))
            } else if ('errors' in result) {
                dispatch(showToast('alert', 'Błąd podczas pobierania wizytówek'));
            }

            // zawsze ukrywamy globalny is loading
            dispatch(isLoading(false));

        });
    };
}

export function getOffers() {
    return (dispatch, getState) => {

        // globalny is loading 
        dispatch(isLoading(true));

        const { admin: { searchQuery } } = getState();

        let params = {
            "sort[status]": 1,
            "limit": 1000
        };

        if (searchQuery) {
            params["offerNumber[in]"] = searchQuery;
        }

        return Api.get('admin/offer', params).then((result) => {

            if (result.success) {
                dispatch(setOffers(result.documents))
            } else if ('errors' in result) {
                dispatch(showToast('alert', 'Błąd podczas pobierania ofert'));
            }

            // zawsze ukrywamy globalny is loading
            dispatch(isLoading(false));
        });
    };
}

export function getUsers() {
    return (dispatch, getState) => {

        // globalny is loading 
        dispatch(isLoading(true));

        const { admin: { searchQuery } } = getState();

        let params = {
            "sort[active]": 1,
            "limit": 1000
        };

        if (searchQuery) {
            params["name[in]"] = searchQuery;
        }

        return Api.get('admin/user', params).then((result) => {

            if (result.success) {
                dispatch(setUsers(result.documents))
            } else if ('errors' in result) {
                dispatch(showToast('alert', 'Błąd podczas pobierania użytkowników'));
            }

            // zawsze ukrywamy globalny is loading
            dispatch(isLoading(false));
        });
    };
}

export function getCardDetails(id) {
    return dispatch => {

        // globalny is loading 
        dispatch(isLoading(true));

        return Api.get(`admin/card/${id}`).then((result) => {

            // zawsze ukrywamy globalny is loading
            if (result.success) {
                dispatch(setCardDetails(result.document))
            } else if ('errors' in result) {
                dispatch(showToast('alert', 'Błąd podczas pobierania wizytówki'));
            }

            dispatch(isLoading(false));
        });
    };
}

export function getOfferDetails(id) {
    return dispatch => {

        // globalny is loading 
        dispatch(isLoading(true));

        return Api.get(`admin/offer/${id}`).then((result) => {

            // zawsze ukrywamy globalny is loading
            dispatch(isLoading(false));

            if (result.success) {
                dispatch(setOfferDetails(result.document))
            } else if ('errors' in result) {
                dispatch(showToast('alert', 'Błąd podczas pobierania wizytówki'));
            }
        });
    };
}

// wyszukiwanie danych po nazwie
export function searchRecords(query, type) {
    return dispatch => {
        dispatch(setSearchQuery(query));

        if (type === "cards") {
            dispatch(getCards());
        }
        if (type === "offers") {
            dispatch(getOffers());
        }
        if (type === "users") {
            dispatch(getUsers());
        }
    }
}