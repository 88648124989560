import React, { useState, Fragment } from 'react';
import { useFormik } from 'formik';
import { useHistory } from "react-router-dom";
import Regulations from '../../application/assets/file/regulamin.pdf';
import Policy from '../../application/assets/file/polityka-prywatnosci.pdf';

import SliderBtnsComponent from '../../application/components/slider/slider-btns.component'
import SliderBtnsMobileComponent from '../../application/components/slider/slider-btns-mobile.component'

import { SignupPersonSchema, SignupCompanySchema, SignupPersonInitialState, SignupCompanyInitialState } from '../schemas/register.schema';

import '../assets/register.css';
import ContentnavComponent from '../../application/components/contentNav.component';

function RegisterComponent(props) {

    const [userType, setUserType] = useState("company");

    function handleFocusInputs() {
        let materialInput = document.querySelectorAll('.material-input');

        if (materialInput) {
            materialInput.forEach(function (e) {
                let input = e.querySelector('.material-input__input');
                let label = e.querySelector('label');

                if (input && label) {
                    input.addEventListener('focusin', (event) => {
                        label.classList.add('float-label');
                    })

                    input.addEventListener('focusout', (event) => {
                        if (input.value === "") {
                            label.classList.remove('float-label');
                        }
                    })
                }

            })
        }
    }

    const SignupForm = () => {

        let history = useHistory();
        const [errors, setApiErrors] = useState(null);

        const formik = useFormik({
            initialValues: userType === "person" ? SignupPersonInitialState : SignupCompanyInitialState,
            validationSchema: userType === "person" ? SignupPersonSchema : SignupCompanySchema,
            onSubmit: (values) => {
                // próbujemy się rejestrować

                props.signIn(values).then(
                    () => {
                        // jak się uda to spadamy stąd
                        history.push('/login', { isRegistered: 'true' });
                        return;
                    },
                    (errors) => {
                        // nie udało się to podstawiamy błędy
                        setApiErrors(errors);
                    });
            }
        });

        return (
            <form className="row justify-content-center" onSubmit={formik.handleSubmit} autoComplete="off">
                {/* IMIE */}
                {userType === "person" &&
                    <div className={`col-12 material-input ${(formik.errors.name && formik.touched.name) || (errors && errors.name) ? "input-error" : ""} mb-5`}>
                        <div className="d-flex align-items-center">
                            <label className="ml-5">Imię<span className="required"> *</span></label>
                            <input
                                id="name"
                                name="name"
                                type="text"
                                autoComplete="new-password"
                                onChange={formik.handleChange}
                                value={formik.values.name}
                                className="material-input__input h-100"
                                onFocus={handleFocusInputs} />
                        </div>
                        {formik.errors.name && formik.touched.name ? (
                            <div className="material-input__error">{formik.errors.name}</div>
                        ) : null}
                        {errors && errors.name ? (
                            <div className="material-input__error">{errors.name}</div>
                        ) : null}
                    </div>
                }
                {/* NAZWA FIRMY */}
                {userType === "company" &&
                    <div className={`col-12 material-input ${(formik.errors.companyName && formik.touched.companyName) || (errors && errors.companyName) ? "input-error" : ""} mb-5`}>
                        <div className="d-flex align-items-center">
                            <label className="ml-5">Nazwa firmy<span className="required"> *</span></label>
                            <input
                                id="companyName"
                                name="companyName"
                                type="text"
                                autoComplete="new-password"
                                onChange={formik.handleChange}
                                value={formik.values.companyName}
                                className="material-input__input h-100"
                                onFocus={handleFocusInputs} />
                        </div>
                        {formik.errors.companyName && formik.touched.companyName ? (
                            <div className="material-input__error">{formik.errors.companyName}</div>
                        ) : null}
                        {errors && errors.companyName ? (
                            <div className="material-input__error">{errors.companyName}</div>
                        ) : null}
                    </div>
                }
                {/* NIP */}
                {userType === "company" &&
                    <div className={`col-12 material-input ${(formik.errors.NIP && formik.touched.NIP) || (errors && errors.NIP) ? "input-error" : ""} mb-5`}>
                        <div className="d-flex align-items-center">
                            <label className="ml-5">Nip firmy </label>
                            <input
                                id="NIP"
                                name="NIP"
                                type="text"
                                autoComplete="new-password"
                                onChange={formik.handleChange}
                                value={formik.values.NIP}
                                className="material-input__input h-100" />
                        </div>
                        {formik.errors.NIP && formik.touched.NIP ? (
                            <div className="material-input__error">{formik.errors.NIP}</div>
                        ) : null}
                        {errors && errors.NIP ? (
                            <div className="material-input__error">{errors.NIP}</div>
                        ) : null}
                    </div>
                }
                {/* NAZWISKO */}
                {
                    userType === "person" &&
                    <div className={`col-12 material-input ${(formik.errors.surname && formik.touched.surname) || (errors && errors.surname) ? "input-error" : ""} mb-5`}>
                        <div className="d-flex align-items-center">
                            <label className="ml-5">Nazwisko<span className="required"> *</span></label>
                            <input
                                type="text"
                                id="surname"
                                name="surname"
                                autoComplete="new-password"
                                onChange={formik.handleChange}
                                value={formik.values.surname}
                                className="material-input__input h-100" />
                        </div>
                        {formik.errors.surname && formik.touched.surname ? (
                            <div className="material-input__error">{formik.errors.surname}</div>
                        ) : null}
                        {errors && errors.surname ? (
                            <div className="material-input__error">{errors.surname}</div>
                        ) : null}
                    </div>
                }
                {/* EMAIL */}
                <div className={`col-12 material-input ${(formik.errors.email && formik.touched.email) || (errors && errors.email) ? "input-error" : ""} mb-5`}>
                    <div className="d-flex align-items-center">
                        <label className="ml-5">E-mail<span className="required"> *</span></label>
                        <input
                            id="email"
                            name="email"
                            type="email"
                            autoComplete="new-password"
                            onChange={formik.handleChange}
                            value={formik.values.email}
                            className="material-input__input h-100" />
                    </div>
                    {formik.errors.email && formik.touched.email ? (
                        <div className="material-input__error">{formik.errors.email}</div>
                    ) : null}
                    {errors && errors.email ? (
                        <div className="material-input__error">{errors.email}</div>
                    ) : null}
                </div>
                {/* TELEFON */}
                <div className={`col-12 material-input ${(formik.errors.phone && formik.touched.phone) || (errors && errors.phone) ? "input-error" : ""} mb-5`}>
                    <div className="d-flex align-items-center">
                        <label className="ml-5">Telefon</label>
                        <input
                            id="phone"
                            name="phone"
                            autoComplete="new-password"
                            value={formik.values.phone}
                            onChange={formik.handleChange}
                            className="material-input__input h-100" />
                    </div>
                    {formik.errors.phone && formik.touched.phone ? (
                        <div className="material-input__error">{formik.errors.phone}</div>
                    ) : null}
                    {errors && errors.phone ? (
                        <div className="material-input__error">{errors.phone}</div>
                    ) : null}
                </div>
                {/* HASŁO */}
                <div className={`col-12 material-input ${(formik.errors.password && formik.touched.password) || (errors && errors.password) ? "input-error" : ""} mb-5`}>
                    <div className="d-flex align-items-center">
                        <label className="ml-5">Hasło<span className="required"> *</span></label>
                        <input
                            id="password"
                            name="password"
                            type="password"
                            autoComplete="new-password"
                            onChange={formik.handleChange}
                            value={formik.values.password}
                            className="material-input__input h-100" />
                    </div>
                    {formik.errors.password && formik.touched.password ? (
                        <div className="material-input__error">{formik.errors.password}</div>
                    ) : null}
                    {errors && errors.password ? (
                        <Fragment>
                            {errors.password.split(",").map((error, index) => {
                                return <div key={`reset-pass-${index}`} className="material-input__error">{error}</div>
                            })}
                        </Fragment>
                    ) : null}
                </div>
                {/* HASŁO CONFIRM */}
                <div className={`col-12 material-input ${formik.errors.passwordConfirm && formik.touched.passwordConfirm ? "input-error" : ""} mb-5`}>
                    <div className="d-flex align-items-center">
                        <label className="ml-5">Powtórz hasło <span className="required"> *</span></label>
                        <input
                            id="passwordConfirm"
                            name="passwordConfirm"
                            type="password"
                            autoComplete="new-password"
                            onChange={formik.handleChange}
                            value={formik.values.passwordConfirm}
                            className="material-input__input h-100" />
                    </div>
                    {formik.errors.passwordConfirm && formik.touched.passwordConfirm ? (
                        <div className="material-input__error">{formik.errors.passwordConfirm}</div>
                    ) : null}
                </div>
                {/* ZGODY */}
                <div className="col-12">
                    <label className="pure-material-checkbox">
                        <input
                            id="regulationsAcceptance"
                            name="agreements.regulationsAcceptance"
                            type="checkbox"
                            onChange={formik.handleChange}
                            checked={formik.values.agreements.regulationsAcceptance}
                            value={formik.values.agreements.regulationsAcceptance} />
                        <span className="align-items-start text--light-grey">
                            <span>
                                Oświadczam, że zapoznałam/em się i akceptuję <a href={Regulations} target="_blank" rel="noopener noreferrer">Regulamin</a> serwisu BEEMPLOYEE.PL
                                    <ul className="dot-list mt-4">
                                    <li className="mb-3">
                                        <span>
                                            Administratorem danych osobowych jest Over Cloud sp. z o.o. z siedzibą w Łodzi. Podanie danych jest dobrowolne, ale niezbędne do korzystania z Serwisu. Dane osobowe będą przetwarzane w celach i na zasadach określonych w <a href={Policy} target="_blank" rel="noopener noreferrer">Polityce Prywatności</a> serwisu BEEMPLOYEE.PL
                                            </span>
                                    </li>
                                    <li>
                                        <span>
                                            Przyjmuję do wiadomości, że podając przy rejestracji dane kontaktowe, w szczególności adres email i numer telefonu, upoważniam Administratora do korzystania z tych kanałów komunikacji do kontaktowania się ze mną w sprawach związanych z realizacją Usługi.
                                             </span>
                                    </li>
                                </ul>
                            </span>
                        </span>
                    </label>
                </div>
                {
                    formik.errors.agreements && formik.touched.agreements && formik.errors.agreements.regulationsAcceptance ? (
                        <div className="alert alert-danger">{formik.errors.agreements.regulationsAcceptance}</div>
                    ) : null
                }
                {
                    errors && errors.agreements ? (
                        <div className="alert alert-danger">{errors.agreements}</div>
                    ) : null
                }
                <div className="col-12">
                    <label className="pure-material-checkbox">
                        <input
                            id="privacyPolicyAcceptance"
                            name="agreements.privacyPolicyAcceptance"
                            type="checkbox"
                            onChange={formik.handleChange}
                            checked={formik.values.agreements.privacyPolicyAcceptance}
                            value={formik.values.agreements.privacyPolicyAcceptance} />
                        <span className="align-items-start text--light-grey">
                            <span>
                                Oświadczam, że zapoznałam/em się i akceptuję <a href={Policy} target="_blank" rel="noopener noreferrer">Politykę Prywatności</a> serwisu BEEMPLOYEE.PL
                            </span>
                        </span>
                    </label>
                </div>
                {
                    formik.errors.agreements && formik.touched.agreements && formik.errors.agreements.privacyPolicyAcceptance ? (
                        <div className="alert alert-danger">{formik.errors.agreements.privacyPolicyAcceptance}</div>
                    ) : null
                }
                {
                    errors && errors.agreements ? (
                        <div className="alert alert-danger">{errors.agreements}</div>
                    ) : null
                }
                {/* BUTTON */}
                <div className="col-md-6 mt-50">
                    <button className="btn btn--primary w-100 justify-content-center" type="submit">Załóż konto</button>
                </div>
            </form >
        )

    };

    return (
        <div>
            <ContentnavComponent />
            <section className="add-card--job">
                <div id="addCard" className="add-card__content">
                    <div className="container-fluid">
                        <div className="row justify-content-center">
                            <div className="col-12 col-xl-auto mb-5">
                                <section id="registerContent" className="register-content bg-white pt-5 pb-50 active">
                                    <div className="container">
                                        <div className="row justify-content-center">
                                            <div className="col-12 mb-50 d-none d-lg-block">
                                                <SliderBtnsComponent userType={userType} setUserType={(userType) => setUserType(userType)} />
                                            </div>
                                            <div className="col-12 mb-50 mt-5 d-block d-lg-none px-0 px-sm-4">
                                                <SliderBtnsMobileComponent userType={userType} setUserType={(userType) => setUserType(userType)} />
                                            </div>
                                            <div className="col-md-8 col-lg-6">
                                                <SignupForm />
                                            </div>
                                        </div>
                                    </div>
                                </section>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    )

}


export default RegisterComponent;
