import React, { useEffect, useState } from 'react';
import {
    useParams as navigateParam,
    useHistory
} from "react-router-dom";

import moment from 'moment';

import ContentNavComponent from '../../application/components/contentNav.component';
import ConfirmModalComponent from '../../application/components/confirmModal.component';
import RejectAcceptanceModalComponent from './rejectAcceptanceModal.component';

import BeeIcon from '../../application/assets/img/bee_icon.svg';
import ViewsIcon from '../../application/assets/img/views.png';

function CardDetailsComponent(props) {

    let { cardId } = navigateParam();

    const history = useHistory();
    const { getCardDetails, clearCardDetails, cardDetails, cardAcceptance, downloadCv } = props;

    const [selectedCardId, setSelectedCardId] = useState(false);

    const [showModal, setShowModal] = useState(false);
    const [showRejectModal, setShowRejectModal] = useState(false);

    useEffect(() => {

        getCardDetails(cardId);
        return () => {
            clearCardDetails();
        }
    }, [getCardDetails, cardId, clearCardDetails]);

    if (!cardDetails) {
        return null;
    }

    const hrefs = [{
        label: "Akceptacja wizytówek",
        route: "/admin/cards"
    },
    {
        label: `#${cardDetails.cardNumber}`,
        route: `/admin/card/${cardId}`
    }];

    function handleAcceptanceCard() {
        toggleModal(); // zamykamy modal

        cardAcceptance(selectedCardId, {
            status: 3
        }); // akceptujemy wizytówke

        setSelectedCardId(false); // zerujemy ID

        setTimeout(() => {
            history.push("/admin/cards", { withoutReload: true });
        }, 200);
    }

    function handleRejectAcceptanceCard(reson) {
        toggleRejectModal(); // zamykamy modal

        cardAcceptance(selectedCardId, {
            status: 2,
            rejectReason: reson
        }); // akceptujemy wizytówke

        setSelectedCardId(false); // zerujemy ID

        setTimeout(() => {
            history.push("/admin/acceptance", { withoutReload: true });
        }, 200);
    }

    function toggleModal(cardId) {
        setSelectedCardId(cardId ? cardId : false);
        setShowModal(!showModal);
    }

    function toggleRejectModal(cardId) {
        setSelectedCardId(cardId ? cardId : false);
        setShowRejectModal(!showRejectModal);
    }

    return (
        <div>
            <ContentNavComponent hrefs={hrefs} />
            <section id="singleCardContent" className="single-card__content">
                <div className="container-fluid">
                    <div className="row justify-content-center">
                        <div className="col-12 col-xl-5 mb-5 mb-xl-0">
                            <div className="single-card__info bg-white p-5">
                                <div className="row justify-content-between align-items-center mb-5">
                                    <div className="col-12 col-md-auto">
                                        <div className="row align-items-center">
                                            <div className="col-12 col-md-auto">
                                                <div className="hex__icon zi-1">
                                                    <img src={BeeIcon} alt="" className="w-100" />
                                                </div>
                                            </div>
                                            <div className="col-12 col-md-auto text-center mt-4 mt-md-0">
                                                <p className="single-card__id">#{cardDetails.cardNumber}</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12 col-md-auto">
                                        <div className="single-card__views text-right mt-4 mt-md-0">
                                            <span className="font-weight-bold mr-2">{cardDetails.views}</span>
                                            <img src={ViewsIcon} alt="" />
                                        </div>
                                    </div>
                                </div>
                                {/* Data dodania */}
                                <div className="row mb-4">
                                    <div className="col-12">
                                        <div className="single-card__category">
                                            <h3 className="mb-2">Data dodania</h3>
                                            <p className="font-weight-600">{moment(cardDetails.createdAt).format("DD.MM.YYYY")}</p>
                                        </div>
                                    </div>
                                </div>

                                {/* KATEGORIE */}
                                <div className="row mb-4">
                                    <div className="col-12">
                                        <div className="single-card__category">
                                            <h3 className="mb-2">Kategorie</h3>
                                            <ul className="dot-list">
                                                {cardDetails.categories.map((category, index) => {
                                                    return <li key={`card-catgory-${index}`}>{category.categoryBreadcrumbs}</li>
                                                })}
                                            </ul>
                                        </div>
                                    </div>
                                </div>

                                {/* STANOWISKO */}
                                <div className="row mb-4">
                                    <div className="col-12">
                                        <div className="single-card__position">
                                            <h3 className="mb-2">Stanowisko</h3>
                                            <p className="card-text">{cardDetails.position}</p>
                                        </div>
                                    </div>
                                </div>

                                {/* WOJEWóDZTWO */}
                                {!cardDetails.address.wholeCountry && cardDetails.address.administrative_areas_level_1 && cardDetails.address.administrative_areas_level_1.length > 0 && <div className="row mb-4">
                                    <div className="col-12">
                                        <div className="single-card__province">
                                            <h3 className="mb-2">Województwo</h3>
                                            <p className="font-weight-600">{cardDetails.address.administrative_areas_level_1.join(", ")}</p>
                                        </div>
                                    </div>
                                </div>}

                                {/* MIASTO */}
                                {!cardDetails.address.wholeCountry && cardDetails.address.cities && cardDetails.address.cities.length > 0 && <div className="row mb-4">
                                    <div className="col-12">
                                        <div className="single-card__city">
                                            <h3 className="mb-2">Miasto</h3>
                                            <p className="font-weight-600">{cardDetails.address.cities.join(", ")}</p>
                                        </div>
                                    </div>
                                </div>}

                                {/* CAŁY KRAJ */}
                                {(cardDetails.address.wholeCountry || cardDetails.address.outsiteCountry) && <div className="row mb-4">
                                    <div className="col-12">
                                        <div className="single-card__city">
                                            <h3 className="mb-2">Lokalizacja</h3>
                                            {cardDetails.address.wholeCountry && <p className="card-text">Cały kraj</p>}
                                            {cardDetails.address.outsiteCountry && <p className="card-text">Praca za granicą</p>}
                                        </div>
                                    </div>
                                </div>}
                            </div>

                            {/* JĘZYKI */}
                            {cardDetails.languages && cardDetails.languages.length > 0 && <div className="single-card__info bg-white p-5 mt-4">
                                <h3 className="mb-2">Języki</h3>
                                <ul className="dot-list">
                                    {cardDetails.languages.map((lang, index) => {
                                        return <li key={`card-lang-${index}`}>{lang}</li>
                                    })}
                                </ul>
                            </div>}

                            {/* WYKSZTAŁCENIE */}
                            {cardDetails.education && cardDetails.education.length > 0 && <div className="single-card__info bg-white p-5 mt-4">
                                <h3 className="mb-2">Wykształcenie</h3>
                                {cardDetails.education && <ul className="dot-list">
                                    {cardDetails.education.map((singleEducation, index) => {
                                        return <div key={`education-card-${index}`} sm={12} md={6} lg={6} xl={4} className="single-education-wrapper">
                                            <div className="single-education-card">
                                                <div><span className="education-field">Wykształcenie:</span> <span className="education-value">{singleEducation.degree}</span></div>
                                                <div><span className="education-field">Nazwa szkoły:</span> <span className="education-value">{singleEducation.schoolName}</span></div>
                                                <div><span className="education-field">Specjalizacja / kierunek:</span> <span className="singleEducationeducation-value">{singleEducation.major}</span></div>
                                            </div>
                                        </div>
                                    })}
                                </ul>}
                            </div>}

                            {/* UMIEJĘTNOŚCI */}
                            <div className="single-card__info bg-white p-5 mt-4">
                                <h3 className="mb-2">Umiejętności</h3>
                                <p className="font-weight-600">{cardDetails.skills.join(", ")}</p>
                            </div>

                            {/* WYNAGRODZENIE */}
                            {cardDetails.expectedSalary && cardDetails.expectedSalary !== "" && <div className="single-card__info bg-white p-5 mt-4">
                                <h3 className="mb-2">Oczekiwane wynagrodzenie (netto)</h3>
                                <p className="font-weight-600">{cardDetails.expectedSalary}</p>
                            </div>}

                            {/* OPIS */}
                            <div className="single-card__info bg-white p-5 mt-4">
                                <h3 className="mb-2">Opis Kandydata</h3>
                                <p className="font-weight-600" dangerouslySetInnerHTML={{ __html: cardDetails.description.replace(/(<? *script)/gi, 'illegalscript') }}></p>
                            </div>

                            {/* POWODY ODRZUCEŃ */}
                            {cardDetails.rejectHistory && cardDetails.rejectHistory.length > 0 && <div className="single-card__info bg-white p-5 mt-4">
                                <h3 className="mb-2">Powody odrzuceń</h3>
                                {cardDetails.rejectHistory.map((rejectInfo, index) => {
                                    return <p key={`card-lang-${index}`} className="card-text">{moment(rejectInfo.createdAt).format('llll')} - {rejectInfo.reason}</p>
                                })}
                            </div>}

                            {/* OWNER */}
                            {cardDetails.owner && cardDetails.owner.length > 0 && <div className="single-card__info bg-white p-5 mt-4">
                                <h3 className="mb-2">Dane kontaktowe z kandydatem</h3>
                                <p className="card-text">Imie: {cardDetails.owner[0].name}</p>
                                <p className="card-text">Nazwisko: {cardDetails.owner[0].surname}</p>
                                <p className="card-text">Kontakt: {cardDetails.owner[0].email}</p>
                                {cardDetails.owner[0].cv && <p style={{ cursor: 'pointer' }} className="card-text"><span onClick={() => downloadCv(cardDetails.owner[0].cv)} className=" cv-file">CV: {cardDetails.owner[0].cv}</span></p>}
                            </div>}

                            {/* AKCEPTACJA */}
                            {cardDetails.status === 1 && <div className="single-card__info bg-white p-5 mt-4">
                                <div className="row justify-content-center">
                                    <div type="button" className="col-auto text-center">
                                        <button className="btn btn--primary" onClick={() => toggleModal(cardDetails._id)}>Akceptuj</button>
                                    </div>
                                    <div className="col-auto text-center">
                                        <button type="button" className="btn btn--outline h-100" data-dismiss="modal" aria-label="Close" onClick={() => toggleRejectModal(cardDetails._id)}>Odrzuć</button>
                                    </div>
                                </div>
                            </div>}

                        </div>
                    </div>
                </div>
            </section>
            <ConfirmModalComponent show={showModal} handleSubmit={handleAcceptanceCard} handleToggleModal={toggleModal} contentText="Czy na pewno chcesz zaakceptować wizytówkę?" submitText="Tak" />
            <RejectAcceptanceModalComponent show={showRejectModal} handleSubmit={handleRejectAcceptanceCard} handleToggleModal={toggleRejectModal} />
        </div >
    )
}

export default CardDetailsComponent;
