import { SET_CARDS, CLEAR_CARDS } from '../actions/card.action';

const initial_state = {
    cards: [],
    total: 0
}

const cards = (state = initial_state, action) => {
    switch (action.type) {
        case SET_CARDS:
            return { ...state, cards: action.cards, total: action.total };
        case CLEAR_CARDS:
            return { ...state, cards: [], total: 0 };
        default:
            return state;
    }
};

export default cards;