import React, { useEffect, useState } from 'react';
import { Link, useLocation } from "react-router-dom";

import ConfirmModalComponent from '../../application/components/confirmModal.component';
import RejectAcceptanceModalComponent from './rejectAcceptanceModal.component';

import BeeIcon from '../../application/assets/img/bee_icon.svg';

function CardsAcceptanceComponent(props) {

    const location = useLocation();
    const { cards, getCards, clearCards, cardAcceptance, isLoading, handleSearchRecords, query } = props;
    const [selectedCardId, setSelectedCardId] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [showRejectModal, setShowRejectModal] = useState(false);

    useEffect(() => {
        // if (!_.get(location, 'state.withoutReload')) {
        getCards();
        // }
        return () => {
            clearCards();
        }
    }, [getCards, clearCards, location]);

    function handleAcceptanceCard() {
        toggleModal(); // zamykamy modal

        cardAcceptance(selectedCardId, {
            status: 3
        }); // akceptujemy wizytówke

        setSelectedCardId(false); // zerujemy ID
    }

    function handleRejectAcceptanceCard(reson) {
        toggleRejectModal(); // zamykamy modal

        cardAcceptance(selectedCardId, {
            status: 2,
            rejectReason: reson
        }); // akceptujemy wizytówke

        setSelectedCardId(false); // zerujemy ID
    }

    function toggleModal(cardId) {
        setSelectedCardId(cardId ? cardId : false);
        setShowModal(!showModal);
    }

    function toggleRejectModal(cardId) {
        setSelectedCardId(cardId ? cardId : false);
        setShowRejectModal(!showRejectModal);
    }

    function handleFocusInputs() {
        let materialInput = document.querySelectorAll('.material-input');

        if (materialInput) {
            materialInput.forEach(function (e) {
                let input = e.querySelector('.material-input__input');
                let label = e.querySelector('label');

                if (input && label) {
                    input.addEventListener('focusin', (event) => {
                        label.classList.add('float-label');
                    })

                    input.addEventListener('focusout', (event) => {
                        if (input.value === "") {
                            label.classList.remove('float-label');
                        }
                    })
                }
            })
        }
    }

    function getCardsList() {
        return cards.map((card, index) => {
            return (
                <div key={`single-acceptance-card=${index}`} className="row my-5">
                    <div className="col-12">
                        <div className="admin__content__item border--light-grey p-4">
                            <div className="row justify-content-center justify-content-sm-between align-items-center">
                                <Link className="d-block" to={`/admin/card/${card._id}`}>
                                    <div className="col">
                                        <div className="row align-items-center">
                                            <div className="col-12 col-md-auto">
                                                <div className="hex__icon zi-1">
                                                    <img src={BeeIcon} alt="" className="w-100" />
                                                </div>
                                            </div>
                                            <div className="col-12 col-md-auto text-center mt-4 mt-md-0">
                                                <p className="admin__id">#{card.cardNumber}</p>
                                            </div>
                                        </div>
                                    </div>
                                </Link>
                                {card.status === 1 && <div className="col-auto d-sm-flex">
                                    <button id="acceptCard" className="btn btn--primary mr-4 w-100" data-toggle="modal" data-target="#acceptModal" onClick={() => toggleModal(card._id)}>Akceptuj</button>
                                    <button id="rejectCard" className="btn btn--secondary w-100 mt-4 mt-sm-0" data-toggle="modal" data-target="#rejectModal" onClick={() => toggleRejectModal(card._id)}>Odrzuć</button>
                                </div>}
                                {card.status === 2 && <div className="col-12 col-md-auto d-sm-flex text-center text-sm-right">
                                    <p className="admin__content__item__status font-weight-bold m-0 text--light-grey">Odrzucono</p>
                                </div>}
                            </div>
                        </div>
                    </div>
                </div>
            )
        });
    }

    return (
        <div className="tab-pane">
            <section className="admin__content admin__content--offer">
                <div className="container-fluid">
                    <div className="row justify-content-center">
                        <div className="col-12 col-xl-8 mb-5">
                            <div className="admin__info bg-white p-5">
                                <div className="row">
                                    <div className="col material-input">
                                        <div className="d-flex align-items-center position-relative">
                                            <label className="ml-5">Wpisz identyfikator</label>
                                            <input
                                                id="query"
                                                name="query"
                                                type="text"
                                                autoComplete="off"
                                                onChange={(e) => handleSearchRecords(e.target.value)}
                                                value={query}
                                                onFocus={() => handleFocusInputs()}
                                                className="material-input__input h-100" />
                                        </div>
                                    </div>
                                </div>
                                {getCardsList()}
                                {cards.length === 0 && !isLoading && <div className="row my-5">
                                    <div className="col-12">
                                        <div className="admin__content__item admin__content__item--empty p-4">
                                            <p className="m-0">Brak wizytówek</p>
                                        </div>
                                    </div>
                                </div>}
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <ConfirmModalComponent show={showModal} handleSubmit={handleAcceptanceCard} handleToggleModal={toggleModal} contentText="Czy na pewno chcesz zaakceptować wizytówkę?" submitText="TAK" />
            <RejectAcceptanceModalComponent show={showRejectModal} handleSubmit={handleRejectAcceptanceCard} handleToggleModal={toggleRejectModal} />
        </div>
    )

}

export default CardsAcceptanceComponent;