import React, { useState, useEffect, useCallback } from 'react';
import { useFormik } from 'formik';
import { useHistory, useParams } from "react-router-dom";
import _ from 'lodash';

import '../assets/card.css';

import { CardInitialState, CardSchema } from '../schemas/card.schema';

import AutocompleteComponent from '../../application/components/autocomplete.component';
import SalaryComponent from './chooseSalary.component';

import { Editor } from 'react-draft-wysiwyg';
import { convertToRaw, ContentState, EditorState } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

import ContentnavComponent from '../../application/components/contentNav.component';

import CloseIcon from '../../application/assets/img/filter_close.svg';

function CardComponent(props) {

   let { id } = useParams();
   const { getCard, clearCard, loggedUser } = props;

   useEffect(() => {
      if (id) { // jeżeli jest ID to pobieramy wizytówkę 
         getCard(id); // pobieramy wizytówkę
      }
      return () => { // na componendUnmount czyścimy store
         clearCard();
      }
   }, [id, getCard, clearCard]);

   const Card = (props) => {

      let history = useHistory();

      const { card } = props;
      const [editorState, setEditorState] = useState(null);
      const [skills, setSkills] = useState([]);
      const [areasLevel1, setAreasLevel1] = useState([]);
      const [cities, setCities] = useState([]);
      const [cardCategories, setCardCategories] = useState([]);
      const [cardTags, setCardTags] = useState([]);
      const [rejectCompanies, setRejectCompanies] = useState([]);

      const [errors, setApiErrors] = useState(null);

      const formData = useCallback(setFormData, [card]);

      useEffect(() => { // jak przyjdzie profil w propsach to ustawiamy go na formularzu
         formData();
      }, [card, formData]);

      function handleFocusInputs() {
         let materialInput = document.querySelectorAll('.material-input');

         if (materialInput) {
            materialInput.forEach(function (e) {
               let input = e.querySelector('.material-input__input');
               let label = e.querySelector('label');

               if (input && label) {
                  input.addEventListener('focusin', (event) => {
                     label.classList.add('float-label');
                  });

                  input.addEventListener('focusout', (event) => {
                     if (input.value === "") {
                        label.classList.remove('float-label');
                     }
                  });
               }
            })
         }
      }


      function handleOnValueInput() {
         let materialInput = document.querySelectorAll('.material-input');

         if (materialInput) {
            materialInput.forEach(function (e) {
               let input = e.querySelector('.material-input__input');
               let label = e.querySelector('label');

               if (input && input.value && input.value !== '') {
                  label.classList.add('float-label');
               }

            })
         }
      }
      handleOnValueInput();

      function setFormData() {
         Object.keys(card).map((name) => {
            if (name === 'description') {// dla opisu musimy ustawić editorState i wykonać tą samą funkcje co przy dodawaniu, zmiany opisu (description)
               const contentBlock = htmlToDraft(card[name]);
               if (contentBlock) {
                  const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
                  onEditorStateChange(EditorState.createWithContent(contentState), formik.setFieldValue);
               }
            } else if (name === 'address') {
               setCities(card[name].cities.map((city => ({ name: city, value: city }))));
               setAreasLevel1(card[name].administrative_areas_level_1.map((level_1 => ({ name: level_1, value: level_1 }))));
               formik.setFieldValue(name, card[name]);
            } else if (name === 'categories' && card[name].length > 0) {
               setCardCategories(card[name].map(cat => {
                  return { name: cat.categoryBreadcrumbs, value: cat.categoryId }
               }));
               formik.setFieldValue(name, card[name].map(cat => cat.categoryId));
            } else if (name === 'tags' && card[name].length > 0) {
               setCardTags(card[name].map((tag => ({ name: tag, value: tag }))));
               formik.setFieldValue(name, card[name]);
            } else if (name === 'skills' && card[name].length > 0) {
               setSkills(card[name].map((skill => ({ name: skill, value: skill }))));
               formik.setFieldValue(name, card[name]);
            } else if (name === 'avatar' && card[name] !== "") {
               // setPreview(card[name]);
               formik.setFieldValue(name, card[name]);
            } else if (name === 'rejectCompanies' && card[name].length > 0) {
               setRejectCompanies(card[name].map((company => ({ name: company, value: company }))));
               formik.setFieldValue(name, card[name]);
            } else {
               formik.setFieldValue(name, card[name]);
            }

            return true;
         });
      }

      const formik = useFormik({
         initialValues: CardInitialState,
         validationSchema: CardSchema,
         onSubmit: (values, actions) => {
            // próbujemy dodać / zapisać kartę
            props.saveCard(values).then(
               () => {
                  // jak się uda to spadamy stąd
                  history.push('/cards');
                  return;
               },
               (errors) => {
                  // nie udało się to podstawiamy błędy
                  setApiErrors(errors);
               });
         }
      });

      function onEditorStateChange(editorState, setFieldValue) {
         setEditorState(editorState);
         setFieldValue('description', draftToHtml(convertToRaw(editorState.getCurrentContent())));
      }

      function getLengthFromDescription() {
         let descriptionLength = 0;
         [...document.querySelectorAll('.editor-text-wrapper span[data-text]')].map((element) => {
            return descriptionLength += element.innerHTML.length;
         });
         return descriptionLength;
      }

      function handleSelectSalary(salaryValue) {
         formik.setFieldValue("expectedSalary", salaryValue);
      }

      function handleRemoveSalary() {
         formik.setFieldValue("expectedSalary", "");
      }

      function handleSwitchCountry(wholeCountry, outsiteCountry) {
         setCities([]);
         setAreasLevel1([]);
         formik.setFieldValue("address.administrative_areas_level_1", []);
         formik.setFieldValue("address.cities", []);

         if (wholeCountry !== null) {
            formik.setFieldValue("address.wholeCountry", wholeCountry);
         }

         if (outsiteCountry !== null) {
            formik.setFieldValue("address.outsiteCountry", outsiteCountry);
         }
      }

      function handleRemoveRejectCompany(value) {
         let filterSelectedValues = rejectCompanies.filter((selectedValue) => {
            return selectedValue.name !== value.name
         });

         formik.setFieldValue("rejectCompanies", filterSelectedValues);
         setRejectCompanies(filterSelectedValues);
      }

      function handleRemoveSkill(value) {
         let filterSelectedValues = skills.filter((selectedValue) => {
            return selectedValue.name !== value.name
         });

         formik.setFieldValue("skills", filterSelectedValues);
         setSkills(filterSelectedValues);
      }

      function removeCityFromSelected(value) {
         let filterSelectedValues = cities.filter((selectedValue) => {
            return selectedValue.name !== value.name
         });

         formik.setFieldValue("address.cities", filterSelectedValues);
         setCities(filterSelectedValues);
      }

      function removeAreasLevel1FromSelected(value) {
         let filterSelectedValues = areasLevel1.filter((selectedValue) => {
            return selectedValue.name !== value.name
         });

         formik.setFieldValue("address.administrative_areas_level_1", filterSelectedValues);
         setAreasLevel1(filterSelectedValues);
      }

      function removeCategoryFromSelected(value) {
         let filterSelectedValues = cardCategories.filter((selectedValue) => {
            return selectedValue.value !== value.value
         });

         setCardCategories(filterSelectedValues);
      }

      function removeTagFromSelected(value) {
         let filterSelectedValues = cardTags.filter((selectedValue) => {
            return selectedValue.value !== value.value
         });

         setCardTags(filterSelectedValues);
      }

      return (
         <form onSubmit={formik.handleSubmit}>
            <ContentnavComponent />
            <section id="addCard" className="add-card__content">
               <div className="container-fluid">
                  <div className="row justify-content-center">
                     <div className="col-12 col-xl-8 mb-5">
                        <div className="add-card__info bg-white p-5">
                           <div className="row mb-4">
                              <div className="col-12">
                                 <h3 className="mb-4">Podstawowe informacje</h3>
                              </div>
                              {/* WYBRANE KATEGORIE */}
                              <div className="col-12 mb-4">
                                 <div className="row no-gutters">
                                    {cardCategories && cardCategories.length > 0 && cardCategories.map((cardCategory, index) => {
                                       return <div className="col-auto" key={'category-' + index}>
                                          <button
                                             className="btn btn--filter mr-4 mt-4 filter-result__single"
                                             onClick={() => removeCategoryFromSelected(cardCategory)}>{cardCategory.name}
                                             <span className="ml-5"><img src={CloseIcon} alt="" /></span>
                                          </button>
                                       </div>
                                    })}
                                 </div>
                              </div>

                              {/* KATEGORIA */}
                              <div className={`col-12 ${(formik.errors.categories && formik.touched.categories) || (errors && errors.categories) ? 'input-error' : ''} material-input mb-4`}>
                                 <AutocompleteComponent
                                    state={cardCategories}
                                    setState={setCardCategories}
                                    options={props.categoryTree}
                                    value={formik.values.categories}
                                    multiple={true}
                                    handleOnSelect={(values) => formik.setFieldValue("categories", values.map(cat => cat.value))}
                                    placeholder="Kategoria"
                                    id="addCardCategory"
                                    isRequired={true}
                                    handleFocusInputs={() => handleFocusInputs()}
                                 />
                                 {formik.errors.categories && formik.touched.categories ? (
                                    <div className="material-input__error">{formik.errors.categories}</div>
                                 ) : null}
                                 {errors && errors.categories ? (
                                    <div className="material-input__error">{errors.categories}</div>
                                 ) : null}
                              </div>

                              {/* STANOWISKO */}
                              <div className={`col-12 material-input ${(formik.errors.position && formik.touched.position) || (errors && errors.position) ? 'input-error' : ''}`}>
                                 <div className="d-flex align-items-center position-relative">
                                    <label className="ml-5">Stanowisko<span className="required"> *</span></label>
                                    <input
                                       id="position"
                                       name="position"
                                       type="text"
                                       onChange={formik.handleChange}
                                       value={formik.values.position}
                                       className="material-input__input h-100" />
                                 </div>
                                 {formik.errors.position && formik.touched.position ? (
                                    <div className="material-input__error">{formik.errors.position}</div>
                                 ) : null}
                                 {errors && errors.position ? (
                                    <div className="material-input__error">{errors.position}</div>
                                 ) : null}
                              </div>

                              {/* WYBRANE TAGI */}
                              <div className="col-12 mb-4">
                                 <div className="row no-gutters">
                                    {cardTags && cardTags.length > 0 && cardTags.map((cardTag, index) => {
                                       return <div className="col-auto" key={'tag-' + index}>
                                          <button
                                             className="btn btn--filter mr-4 mt-4 filter-result__single"
                                             onClick={() => removeTagFromSelected(cardTag)}>{cardTag.name}
                                             <span className="ml-5"><img src={CloseIcon} alt="" /></span>
                                          </button>
                                       </div>
                                    })}
                                 </div>
                              </div>

                              {/* TAGI */}
                              <div className="col-12 material-input">
                                 <AutocompleteComponent
                                    state={cardTags}
                                    setState={setCardTags}
                                    options={[]}
                                    totalSelected={5}
                                    onlyFilteredValue={true}
                                    value={formik.values.tags}
                                    handleOnSelect={(values) => formik.setFieldValue("tags", values.map((tag => tag.value)))}
                                    placeholder="Tagi (zarządzanie, projektowanie, IT - max 5)"
                                    multiple={true}
                                    handleFocusInputs={() => handleFocusInputs()}
                                 />
                              </div>
                           </div>

                           {/* MIEJSCE PRACY */}
                           <div className="row mb-4 mt-50">
                              <div className="col-12">
                                 <h3 className="mb-4">Miejsce podjęcia pracy</h3>
                                 <div className="row mb-5">
                                    <div className="col-auto">
                                       <div className="add-card__country d-flex align-items-md-center flex-column-reverse flex-md-row position-relative">
                                          <label className="pure-material-switch">
                                             <input
                                                type="checkbox"
                                                checked={formik.values.address.wholeCountry}
                                                onChange={(event) => { handleSwitchCountry(event.target.checked, null); }} />
                                             <span></span>
                                          </label>
                                          <span className="pure-material-switch__label ml-3 text--light-grey">Cały kraj</span>
                                       </div>
                                    </div>
                                    <div className="col-auto">
                                       <div className="add-card__world d-flex align-items-md-center flex-column-reverse flex-md-row position-relative">
                                          <label className="pure-material-switch">
                                             <input
                                                type="checkbox"
                                                checked={formik.values.address.outsiteCountry}
                                                onChange={(event) => { handleSwitchCountry(null, event.target.checked) }} />
                                             <span></span>
                                          </label>
                                          <span className="pure-material-switch__label ml-3 text--light-grey">Praca za granicą</span>
                                       </div>
                                    </div>
                                 </div>

                                 {!_.get(formik, 'values.address.wholeCountry', null) && !_.get(formik, 'values.address.outsiteCountry', null) && <div className="row">
                                    {/* WOJEWODZTWO */}
                                    <div className={`col-12 material-input ${(formik.errors.address && formik.touched.address && formik.errors.address.administrative_areas_level_1) || (errors && errors.address && errors.address.administrative_areas_level_1) ? 'input-error' : ''}`}>
                                       {/* WYBRANE WOJEWODZTWA */}
                                       <div className="row no-gutters mb-4">
                                          {areasLevel1 && areasLevel1.length > 0 && areasLevel1.map((areaLevel1, index) => {
                                             return <div className="col-auto" key={'tag-' + index}>
                                                <button
                                                   className="btn btn--filter mr-4 mt-4 filter-result__single"
                                                   onClick={() => removeAreasLevel1FromSelected(areaLevel1)}>{areaLevel1.name}
                                                   <span className="ml-5"><img src={CloseIcon} alt="" /></span>
                                                </button>
                                             </div>
                                          })}
                                       </div>
                                       <AutocompleteComponent
                                          state={areasLevel1}
                                          setState={setAreasLevel1}
                                          options={props.getAllAdministrativeAreaLevel1()}
                                          value={formik.values.address.administrative_areas_level_1}
                                          handleOnSelect={(values) => formik.setFieldValue("address.administrative_areas_level_1", values.map((level_1 => level_1.value)))}
                                          placeholder="Województwo"
                                          multiple={true}
                                          isRequired={true}
                                          handleFocusInputs={() => handleFocusInputs()}
                                       />
                                       {formik.errors.address && formik.touched.address && formik.errors.address.administrative_areas_level_1 ? (
                                          <div className="material-input__error">{formik.errors.address.administrative_areas_level_1}</div>
                                       ) : null}
                                       {errors && errors.address && errors.address.administrative_areas_level_1 ? (
                                          <div className="material-input__error">{errors.address.administrative_areas_level_1}</div>
                                       ) : null}
                                    </div>

                                    {/* MIASTO */}
                                    <div className={`col-12 material-input mb-5 ${(formik.errors.address && formik.touched.address && formik.errors.address.cities) || (errors && errors.address && errors.address.cities) ? 'input-error' : ''}`}>
                                       {/* WYBRANE MIASTA */}
                                       <div className="row no-gutters mb-4">
                                          {cities && cities.length > 0 && cities.map((city, index) => {
                                             return <div className="col-auto" key={'tag-' + index}>
                                                <button
                                                   className="btn btn--filter mr-4 mt-4 filter-result__single"
                                                   onClick={() => removeCityFromSelected(city)}>{city.name}
                                                   <span className="ml-5"><img src={CloseIcon} alt="" /></span>
                                                </button>
                                             </div>
                                          })}
                                       </div>
                                       <AutocompleteComponent
                                          state={cities}
                                          setState={setCities}
                                          options={[]}
                                          getOptionsMethod={props.getPlaces}
                                          value={formik.values.address.cities}
                                          handleOnSelect={(values) => formik.setFieldValue("address.cities", values.map((city => city.value)))}
                                          placeholder="Miasto"
                                          multiple={true}
                                          handleFocusInputs={() => handleFocusInputs()}
                                       />
                                       {formik.errors.address && formik.touched.address && formik.errors.address.cities ? (
                                          <div className="material-input__error">{formik.errors.address.cities}</div>
                                       ) : null}
                                       {errors && errors.address && errors.address.cities ? (
                                          <div className="material-input__error">{errors.address.cities}</div>
                                       ) : null}
                                    </div>
                                 </div>}
                              </div>
                           </div>
                        </div>

                        <div className="add-card__info bg-white p-5 mt-4">
                           {/* JĘZYKI */}
                           <div className="row mb-4">
                              <div className="col-12">
                                 <h3 className="mb-4">Znajomość języków (pobrane z profilu)</h3>
                              </div>
                              <div className="col-auto mb-4">
                                 <div className="add-card__lang d-flex align-items-md-center flex-column-reverse flex-md-row position-relative">
                                    <label className="pure-material-switch">
                                       <input
                                          type="checkbox"
                                          checked={formik.values.showLanguages}
                                          onChange={(event) => { formik.setFieldValue("showLanguages", event.target.checked) }} />
                                       <span></span>
                                    </label>
                                    <span className="pure-material-switch__label ml-3 text--light-grey">Pokaż języki na wizytówce</span>
                                 </div>
                              </div>
                              <div className={`col-12 ${!formik.values.showLanguages ? 'card-deactivated' : ''}`}>
                                 <ul id="addCardLangList" className="dot-list">
                                    {loggedUser && loggedUser.languages && loggedUser.languages.map((language, index) => {
                                       return <li key={`chips${index}`}>{language}</li>
                                    })}
                                 </ul>
                              </div>
                           </div>
                           {/* WYKSZTAŁCENIE */}
                           <div className="row mt-50">
                              <div className="col-12">
                                 <h3 className="mb-4">Wykształcenie (pobrane z profilu)</h3>
                              </div>
                              <div className="col-12 mb-4">
                                 <div className="add-card__education d-flex align-items-md-center flex-column-reverse flex-md-row position-relative">
                                    <label className="pure-material-switch">
                                       <input
                                          type="checkbox"
                                          checked={formik.values.showEducation}
                                          onChange={(event) => { formik.setFieldValue("showEducation", event.target.checked) }} />
                                       <span></span>
                                    </label>
                                    <span className="pure-material-switch__label ml-3 text--light-grey">Pokaż wykształcenie na wizytówce</span>
                                 </div>
                              </div>
                              <div className="col-12">
                                 <ul id="addCardEducationList" className="dot-list">
                                    {loggedUser && loggedUser.education && <div className={`card-education-warpper ${!formik.values.showEducation ? "inactive" : ""}`}>
                                       {loggedUser.education.map((singleEducation, index) => {
                                          return <div key={`education-card-${index}`} sm={12} md={6} lg={6} xl={4} className="single-education-wrapper">
                                             <div className="single-education-card">
                                                <div>
                                                   <span className="education-field">Wykształcenie:</span> <span className="education-value">{singleEducation.degree}</span>
                                                </div>
                                                <div>
                                                   <span className="education-field">Nazwa szkoły:</span> <span className="education-value">{singleEducation.schoolName}</span>
                                                </div>
                                                <div>
                                                   <span className="education-field">Specjalizacja / kierunek:</span> <span className="singleEducationeducation-value">{singleEducation.major}</span>
                                                </div>
                                             </div>
                                          </div>
                                       })}
                                    </div>}
                                 </ul>
                              </div>
                           </div>
                        </div>

                        <div className="add-card__info bg-white p-5 mt-4">
                           {/* OPIS KANDYDATA */}
                           <div className="row mb-4">
                              <div className="col">
                                 <h3 className="mb-4">Opis kandydata<span className="required"> *</span></h3>
                              </div>
                              <div className="col">
                                 <p className="h3 text-right">Ilość znaków: <span id="addCardCharacters">{`${getLengthFromDescription()}/2000`}</span></p>
                              </div>
                              <div className={`col-12 material-input ${(formik.errors.description && formik.touched.description) || (errors && errors.description) ? 'input-error' : ''}`}>
                                 <div className="add-card__editor">
                                    <Editor
                                       wrapperClassName="cardDescription"
                                       editorState={editorState}
                                       editorClassName="editor-text-wrapper material-input__textarea"
                                       stripPastedStyles={true}
                                       toolbar={{
                                          options: ["fontSize", "inline", "list", "history"],
                                          fontSize: {
                                             options: [16]
                                          },
                                          inline: {
                                             options: ["bold", "italic", "underline"]
                                          }
                                       }}
                                       onEditorStateChange={(editorState) => onEditorStateChange(editorState, formik.setFieldValue)}
                                    />
                                    {formik.errors.description && formik.touched.description ? (
                                       <div className="material-input__error">{formik.errors.description}</div>
                                    ) : null}
                                    {errors && errors.description ? (
                                       <div className="material-input__error">{errors.description}</div>
                                    ) : null}
                                 </div>
                              </div>
                           </div>

                           {/* DODATKOWE UMIEJĘTNOŚCI */}
                           <div className="row">
                              <div className="col-12">
                                 <h3 className="mb-4">Dodatkowe umiejętności</h3>
                              </div>
                              <div className="col-12 mb-4">
                                 <div className="row no-gutters">
                                    {skills && skills.length > 0 && skills.map((skill, index) => {
                                       return <div key={`skill-${index}`} className="col-auto">
                                          <button className="btn btn--filter mr-4 mt-4 filter-result__single" onClick={() => handleRemoveSkill(skill)}>
                                             {skill.name}
                                             <span className="ml-5"><img src={CloseIcon} alt="" /></span>
                                          </button>
                                       </div>
                                    })}
                                 </div>
                              </div>
                              <div className={`col-12 col-lg-6 ${(formik.errors.skills && formik.touched.skills) || (errors && errors.skills) ? 'input-error' : ''} material-input`}>
                                 <AutocompleteComponent
                                    state={skills}
                                    setState={setSkills}
                                    options={[]}
                                    onlyFilteredValue={true}
                                    value={formik.values.skills}
                                    handleOnSelect={(values) => formik.setFieldValue("skills", values.map((tag => tag.value)))}
                                    placeholder="Umiejętności (praca w zespole, sumienność)"
                                    multiple={true}
                                    isRequired={true}
                                    handleFocusInputs={() => handleFocusInputs()}
                                 />
                                 {formik.errors.skills && formik.touched.skills ? (
                                    <div className="material-input__error">{formik.errors.skills}</div>
                                 ) : null}
                                 {errors && errors.skills ? (
                                    <div className="material-input__error">{errors.skills}</div>
                                 ) : null}
                              </div>
                           </div>
                        </div>

                        <div className="add-card__info bg-white p-5 mt-4">
                           {/* WYNAGRODZENIE*/}
                           <div className="row">
                              <div className="col-12">
                                 <h3 className="mb-4">Dodatkowe informacje</h3>
                              </div>
                              <div className="col-12 mb-4">
                                 <div className="row no-gutters">
                                    {formik.values.expectedSalary && <div className="col-auto">
                                       <button className="btn btn--filter mr-4 mt-4 filter-result__single" onClick={() => handleRemoveSalary()}>
                                          {formik.values.expectedSalary}
                                          <span className="ml-5"><img src={CloseIcon} alt="" /></span>
                                       </button>
                                    </div>}
                                 </div>
                              </div>
                              <SalaryComponent handleSelectedValue={handleSelectSalary} />
                           </div>
                        </div>

                        <div className="add-card__info bg-white p-5 mt-4">
                           <div className="row">
                              <div className="col-12">
                                 <h3 className="mb-4">Firmy, z którymi nie chcę współpracować</h3>
                              </div>
                              {/* WYBRANE FIRMY */}
                              <div className="col-12 mb-4">
                                 <div className="row no-gutters">
                                    {rejectCompanies && rejectCompanies.length > 0 && rejectCompanies.map((company, index) => {
                                       return <div key={`reject-company-${index}`} className="col-auto">
                                          <button className="btn btn--filter mr-4 mt-4 filter-result__single" onClick={() => handleRemoveRejectCompany(company)}>
                                             {company.name}
                                             <span className="ml-5"><img src={CloseIcon} alt="" /></span>
                                          </button>
                                       </div>
                                    })}
                                 </div>
                              </div>
                              {/* FIRMY */}
                              <div className="col-12 material-input">
                                 <AutocompleteComponent
                                    state={rejectCompanies}
                                    setState={setRejectCompanies}
                                    options={[]}
                                    totalSelected={10}
                                    onlyFilteredValue={true}
                                    value={formik.values.rejectCompanies}
                                    handleOnSelect={(values) => formik.setFieldValue("rejectCompanies", values.map((company => company.value)))}
                                    placeholder="Wpisz nazwy firm"
                                    multiple={true}
                                    handleFocusInputs={() => handleFocusInputs()}
                                 />
                              </div>
                           </div>
                        </div>

                        <div className="add-card__info bg-white p-5 mt-4">
                           <div className="row justify-content-center">
                              <div className="col-12 col-lg-3">
                                 <button id="addCardSubmit" className="btn btn--primary w-100" type="submit" >{props.card && props.card._id ? 'ZAPISZ' : 'DODAJ'}</button>
                              </div>
                           </div>
                        </div>

                     </div>
                  </div>
               </div>
            </section>
         </form>
      )
   };


   return <Card {...props} />
}

export default CardComponent;
