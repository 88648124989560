import Api from '../../application/service/api.service';
import { isLoading } from '../../application/actions/app.action';

const namespace = 'AUTH';

export const SET_IS_LOGGED = `SET_IS_LOGGED_${namespace}`;
export const SET_IS_AUTH_LOADING = `SET_IS_AUTH_LOADING_${namespace}`;
export const SET_IS_LOGOUT = `SET_IS_LOGOUT_${namespace}`;


export const setIsLogged = (isLogged, loggedUser) => ({
    type: SET_IS_LOGGED,
    loggedUser
});

export const setIsLogout = () => ({
    type: SET_IS_LOGOUT
});

export const isAuthLoading = (isAuthLoading) => ({
    type: SET_IS_AUTH_LOADING,
    isAuthLoading
});

export function signIn(registerData, dispatch) {
    return new Promise((resolve, reject) => {

        // globalny is loading 
        dispatch(isLoading(true));

        return Api.post('application/register', registerData).then((result) => {

            // zawsze ukrywamy globalny is loading
            dispatch(isLoading(false));

            if (result.success) {
                return resolve();
            } else if ('errors' in result) {
                return reject(result.errors);
            }
        });
    });
}

export function checkToken(token, dispatch) {
    return new Promise((resolve, reject) => {

        // globalny is loading 
        dispatch(isLoading(true));

        return Api.get(`application/activateAccount/${token}`).then((result) => {

            // zawsze ukrywamy globalny is loading
            dispatch(isLoading(false));

            if (result.success) {
                return resolve();
            } else if ('errors' in result) {
                return reject(result.errors);
            }
        });
    });
}

export function loginIn(loginData, dispatch) {
    return new Promise((resolve, reject) => {

        // globalny is loading 
        dispatch(isLoading(true));

        return Api.post('application/login', loginData).then((result) => {

            // zawsze ukrywamy globalny is loading
            dispatch(isLoading(false));

            if (result.success) {

                dispatch(setIsLogged(true, result.user));
                localStorage.setItem('token', result.token);

                return resolve(result.user.type);
            } else if ('errors' in result) {
                return reject(result.errors);
            }
        });
    });
}

export function logout() {
    return function (dispatch) {
        dispatch(setIsLogout());
        localStorage.removeItem('token');
    }
}

export function checkIsAuth() {
    return function (dispatch) {

        dispatch(isAuthLoading(true));
        // dispatch(isLoading(true));

        Api.get('application/isAuth').then((result) => {

            if (result.success) {
                if (result.user.email === "p.uzytkownik@cloud.pl") {
                    result.user.type = "person";
                }
                if (result.user.email === "p.firma@cloud.pl") {
                    result.user.type = "company";
                }
                dispatch(setIsLogged(true, result.user));
                localStorage.setItem('token', result.token);
            } else {
                dispatch(logout());
            }

            // zawsze ukrywamy globalny is loading
            dispatch(isAuthLoading(false));
            // dispatch(isLoading(false)); // nie ukrywamy go bo zaraz jest inny request ii takto widać puste miejsce
        });

    }
}

export function restartPasswordMail(data, dispatch) {
    return new Promise((resolve, reject) => {

        // globalny is loading 
        dispatch(isLoading(true));

        return Api.post('application/resetPassword', data).then((result) => {

            // zawsze ukrywamy globalny is loading
            dispatch(isLoading(false));

            if (result.success) {
                return resolve();
            } else if ('errors' in result) {
                return reject(result.errors);
            }
        });
    });
}

export function restartPassword(data, token, dispatch) {
    return new Promise((resolve, reject) => {

        // globalny is loading 
        dispatch(isLoading(true));

        return Api.put('application/resetPassword', data, token).then((result) => {

            // zawsze ukrywamy globalny is loading
            dispatch(isLoading(false));

            if (result.success) {
                return resolve();
            } else if ('errors' in result) {
                return reject(result.errors);
            }
        });
    });
}
