import React from 'react';
import { connect } from 'react-redux';

import { setFilters } from '../actions/filter.action';
import TreeComponent from '../components/tree.component';

function TreeContainer(props) {
    return (
        <TreeComponent {...props} />
    );
}

const mapStateToProps = state => ({
    selectedCategories: state.filter.filters.category,
    categoriesTree: state.filter.categoriesTree
});

const mapDispatchToProps = (dispatch) => {
    return {
        setFilters: (filter, value) => dispatch(setFilters(filter, value))
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(TreeContainer);

