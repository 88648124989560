import Api from '../../application/service/api.service';
import { isLoading, showToast } from '../../application/actions/app.action';

const namespace = 'HOME';

export const SET_CARDS = `SET_CARDS_${namespace}`;
export const CLEAR_CARDS = `CLEAR_CARDS_${namespace}`;
export const SET_OFFERS = `SET_OFFERS_${namespace}`;
export const CLEAR_OFFERS = `CLEAR_OFFERS_${namespace}`;
export const SET_CARD_DETAILS = `SET_CARD_DETAILS_${namespace}`;
export const CLEAR_CARD_DETAILS = `CLEAR_CARD_DETAILS_${namespace}`;
export const SET_OFFER_DETAILS = `SET_OFFER_DETAILS_${namespace}`;
export const CLEAR_OFFER_DETAILS = `CLEAR_OFFER_DETAILS_${namespace}`;
export const SET_LIST_TYPE = `SET_LIST_TYPE_${namespace}`;
export const SET_OFFSET = `SET_OFFSET_${namespace}`;
export const CLEAR_OFFSET = `CLEAR_OFFSET_${namespace}`;
export const SET_MATCHES = `SET_MATCHES_${namespace}`;
export const SET_COUNT = `SET_COUNT_${namespace}`;
export const SET_PAGE = `SET_PAGE_${namespace}`;
export const CLEAR_PAGE = `CLEAR_PAGE_${namespace}`;

// zmiana typu listy
export function setListType(listType) {
    return (dispatch) => {
        dispatch({
            type: SET_LIST_TYPE,
            listType
        });
        // dispatch(clearPaginationRoute());
        // dispatch(getRecords());
    }
}

// filtrowanie
export function setOffset(offset) {
    return (dispatch) => {
        dispatch({
            type: SET_OFFSET,
            offset: offset
        });
    }
}

export function clearOffset() {
    return (dispatch) => {
        dispatch({
            type: CLEAR_OFFSET
        });
    }
}

// lista rekordów
export function clearRecords() {
    return (dispatch) => {
        dispatch({
            type: CLEAR_OFFERS
        });
        dispatch({
            type: CLEAR_CARDS
        });
    }
}

export function getRecords() {
    return (dispatch, getState) => {

        // filtry dla wizytówek i ofert takie same
        const { filter: { filters }, home: { limit, offset, listType, matches } } = getState();

        let params = {
            "sort[createdAt]": -1,
            "limit": limit,
            "offset": offset
        };

        if (filters.query instanceof Array && filters.query.length > 0) {
            // ogólne
            params["categories.categoryName[orin]"] = filters.query.join(',');
            params["categories.categoryBreadcrumbs[orin]"] = filters.query.join(',');
            params["tags[orin]"] = filters.query.join(',');
            params["position[orin]"] = filters.query.join(',');

            // filtry dla wizytówek
            params["description[orin]"] = filters.query.join(',');
            params["cardNumber[orin]"] = filters.query.join(',');
            params["skills[orin]"] = filters.query.join(',');

            // filtry dla ofert
            params["positionDescription[orin]"] = filters.query.join(',');
            params["requirements[orin]"] = filters.query.join(',');
            params["companyDescription[orin]"] = filters.query.join(',');
            params["benefits[orin]"] = filters.query.join(',');
            params["offerNumber[orin]"] = filters.query.join(',');
            params["typeOfContract[orin]"] = filters.query.join(',');
            params["requiredSkills[orin]"] = filters.query.join(',');
        }

        if (filters.category instanceof Array && filters.category.length > 0) {
            params["categories.categoryId[in]"] = filters.category.join(",");
        }

        if (filters.place instanceof Array && filters.place.length > 0) {
            params["address.wholeCountry[or]"] = true;
            params["address.cities[orin]"] = filters.place.join(',');
            params["address.administrative_areas_level_1[orin]"] = filters.place.join(',');
        }

        if (matches && matches instanceof Array) {
            if (listType === "cards") {
                params["cardNumber[in]"] = matches.join(",");
            } else if (listType === "offers") {
                params["offerNumber[in]"] = matches.join(",");
            }
        }

        dispatch(getCount());

        if (listType === "cards") {
            dispatch(getCards(params));
            dispatch(clearOffers());
        } else if (listType === "offers") {
            dispatch(getOffers(params));
            dispatch(clearCards());
        }
    }
}

// pojedyncza wizytówka
export const setCardDetails = (details) => ({
    type: SET_CARD_DETAILS,
    details
});

export const clearCardDetails = () => ({
    type: CLEAR_CARD_DETAILS
});

export function getCardDetails(id, dispatch) {
    return dispatch => {

        // globalny is loading 
        dispatch(isLoading(true));

        return Api.get(`card/card/${id}`).then((result) => {

            // zawsze ukrywamy globalny is loading
            dispatch(isLoading(false));

            if (result.success) {
                dispatch(setCardDetails(result.document))
            } else if ('errors' in result) {
                dispatch(showToast('alert', 'Błąd podczas pobierania wizytwki'));
            }
        });
    };
}

// pojedyncza oferta
export const setOfferDetails = (details) => ({
    type: SET_OFFER_DETAILS,
    details
});

export const clearOfferDetails = () => ({
    type: CLEAR_OFFER_DETAILS
});


export function getOfferDetails(id, dispatch) {
    return dispatch => {

        // globalny is loading 
        dispatch(isLoading(true));

        return Api.get(`offer/offer/${id}`).then((result) => {

            // zawsze ukrywamy globalny is loading
            dispatch(isLoading(false));

            if (result.success) {
                dispatch(setOfferDetails(result.document))
            } else if ('errors' in result) {
                dispatch(showToast('alert', 'Błąd podczas pobierania oferty'));
            }
        });
    };
}

// lista wizytówek
export const setCards = (cards, total) => ({
    type: SET_CARDS,
    cards,
    total
});

export const clearCards = () => ({
    type: CLEAR_CARDS
});

export function getCards(params) {

    return (dispatch) => {

        dispatch(isLoading(true));

        return Api.get('card/card', params).then((result) => {

            // zawsze ukrywamy globalny is loading

            if (result.success) {
                dispatch(setCards(result.documents, result.total));
            } else if ('errors' in result) {
                dispatch(showToast('alert', 'Błąd podczas pobierania wizytówek'));
            }
            dispatch(isLoading(false));
        });
    };
}

// lista ofert
export const setOffers = (offers, total) => ({
    type: SET_OFFERS,
    offers,
    total
});

export const clearOffers = () => ({
    type: CLEAR_OFFERS
});

export function getOffers(params) {

    return (dispatch) => {

        dispatch(isLoading(true));

        return Api.get('offer/offer', params).then((result) => {

            // zawsze ukrywamy globalny is loading
            if (result.success) {

                dispatch(setOffers(result.documents, result.total));
            } else if ('errors' in result) {
                dispatch(showToast('alert', 'Błąd podczas pobierania ofert'));
            }
            dispatch(isLoading(false));
        });
    };
}

// wysyłka maila z kontaktem
export function sendContactMail(data, dispatch) {
    return new Promise((resolve, reject) => {
        dispatch(isLoading(true));

        let url = '';
        const currentData = {};

        currentData.message = data.message;
        currentData.contact = data.contact;

        if (data.recordType === "card") {
            currentData.cardId = data.recordId;
            url = 'card/contact';
        } else if (data.recordType === "offer") {
            currentData.offerId = data.recordId;
            url = 'offer/contact';
        }

        return Api.post(url, currentData).then((result) => {

            dispatch(isLoading(false));

            if (result.success) {
                dispatch(showToast('info', 'Wiadomość została wysłana'));
                return resolve();
            } else if ('errors' in result) {
                return reject(result.errors);
            }
        });
    });
}

export function setMatches(matches, type) {
    return dispatch => {
        dispatch({
            type: SET_MATCHES,
            listType: type,
            matches: matches
        });
    };
}

export function setCount(count) {
    return dispatch => {
        dispatch({
            type: SET_COUNT,
            ...count
        });
    };
}

export function getCount() {
    return async (dispatch) => {
        let res = await Api.get("card/getCount");

        if (res && res.success) {
            dispatch(setCount(res));
        }
    };
}

export function setPaginationRoute(page) {
    return (dispatch, getState) => {
        const { home: { limit } } = getState();

        dispatch(setOffset(limit * ((page || 1) - 1)));
        dispatch({
            type: SET_PAGE,
            page: page || 1
        });
        dispatch(getRecords());
        // window.scrollTo(0, 0);
    }
}

export function clearPaginationRoute() {
    return dispatch => {
        dispatch(setOffset(0));
        dispatch({
            type: CLEAR_PAGE
        });
        // window.scrollTo(0, 0);
    }
}
