import React, { Fragment } from 'react';
import { connect } from 'react-redux'

function ToastComponent(props) {

    const { toast } = props;

    return (
        <Fragment>
            {toast.show && <div className="toast-wrapper">
                <div  className={`app-toast ${toast.type === 'alert' ? 'alert-toast' : 'info-toast'}`}>
                    <span>{toast.message}</span>
                </div>
            </div>}
        </Fragment>
    )
}
 
const mapStateToProps = state => ({
    toast: state.app.toast
});

export default connect(mapStateToProps, null)(ToastComponent);


