import moment from 'moment';
import 'moment/locale/pl';
import React, { useEffect, useState } from 'react';
import { Container, Row } from 'react-bootstrap';
import { useHistory } from "react-router-dom";
import BeeIcon from '../../application/assets/img/bee_icon.svg';
import ViewsIcon from '../../application/assets/img/views.png';
import ConfirmModalComponent from '../../application/components/confirmModal.component';
import ContentnavComponent from '../../application/components/contentNav.component';
moment.locale('pl');

function CardsComponent(props) {
    let history = useHistory();

    const { getCards, cards, deleteCard, refreshCard, clearCards, isLoading, changeStatus, setMatches } = props;
    const [deleteCardId, setDeleteCardId] = useState(false);
    const [showConfirmModal, setShowConfirmModal] = useState(false);

    useEffect(() => {
        getCards();
        return () => { // componentUnmount
            clearCards();
        }
    }, [getCards, clearCards]);

    const handleEditCard = (cardId) => {
        history.push(`/card/${cardId}`);
    }

    const handleDeleteCard = () => {
        deleteCard(deleteCardId);
        toggleModal();
    }

    const handleRefreshCard = (cardId) => {
        refreshCard(cardId);
    }

    function toggleModal(cardId) {
        setDeleteCardId(cardId ? cardId : false);
        setShowConfirmModal(!showConfirmModal);
    }

    function goToMatches(matches) {
        setMatches(matches, "offers");
        history.push("/oferty");
    }

    function getStatusDescription(status) {
        switch (status) {
            case 1:
            case "1":
                return "Twoja wizytówka oczekuje na akceptację. Do czasu zaakceptowania nie jest widoczna w wynikach wyszukiwania.";
            case 2:
            case "2":
                return "Twoja wizytówka została odrzucona w procesie weryfikacji i wymaga edycji aby pojawić się w wynikach wyszukiwania";
            case 3:
            case "3":
                return "Twoja wizytówka przeszła proces weryfikacji i jest widoczna w wynikach wyszukiwania."
            default:
                return "Nieznany status";
        }
    }

    return (
        <div>
            <ContentnavComponent />
            <section className="my-card__content">
                <div className="container-fluid">
                    <div className="row">
                        {cards.map((card, index) => {
                            const activeButton = card.active ?
                                (<button onClick={() => changeStatus(card._id, !card.active)} className="btn btn--secondary w-100">Dezaktywuj </button>)
                                :
                                (<button onClick={() => changeStatus(card._id, !card.active)} className="btn btn--secondary w-100"> Aktywuj </button>);
                            const isActivatedClass = !card.active ? "card-deactivated" : "";
                            const statusInfo = card.status === 1 ? <span className="offer-for-acceptance">wysłano do akceptacji</span> : card.status === 2 ? <span className="offer-reject">odrzucono</span> : <span className="offer-accepted">zaakceptowano</span>;
                            const statusDescription = getStatusDescription(card.status);

                            return <div key={'card-' + index} className="col-12 col-lg-6 col-xl-4 mb-5">
                                <div className="my-card__info bg-white p-5">
                                    <div className={isActivatedClass}>
                                        {/* HEADER */}
                                        <div className={`row justify-content-center justify-content-sm-between align-items-center mb-5 ${isActivatedClass}`}>
                                            <div className="col-auto">
                                                <div className="row align-items-center">
                                                    <div className="col-12 col-md-auto">
                                                        <div className="hex__icon zi-1">
                                                            <img src={BeeIcon} alt="" className="w-100" />
                                                        </div>
                                                    </div>
                                                    <div className="col-12 col-md-auto text-center mt-4 mt-md-0">
                                                        <p className="my-card__id">#{card.cardNumber}</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-12 col-md-auto">
                                                <div className="my-card__views text-right mt-4 mt-md-0">
                                                    <span className="font-weight-bold mr-2">{card.views}</span>
                                                    <img src={ViewsIcon} alt="" />
                                                </div>
                                            </div>
                                        </div>
                                        {/* KATEGORIE */}
                                        <div className="row mb-4">
                                            <div className="col-12">
                                                <div className="my-card__category">
                                                    <h3 className="mb-2">Kategorie</h3>
                                                    <ul className="dot-list">
                                                        {card.categories.map((category, index) => {
                                                            return <li key={`card-catgory-${index}`} >{category.categoryBreadcrumbs}</li>
                                                        })}
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                        {/* STANOWISKO */}
                                        <div className="row mb-4">
                                            <div className="col-12">
                                                <div className="my-card__position">
                                                    <h3 className="mb-2">Stanowisko</h3>
                                                    <p className="font-weight-600">{card.position}</p>
                                                </div>
                                            </div>
                                        </div>
                                        {/* DATA WYGASNIECIA */}
                                        {/* <div className="row mb-4">
                                            <div className="col-12">
                                                <div className="my-card__expire">
                                                    <h3 className="mb-2">Data wygaśnięcia wizytówki</h3>
                                                    <p className="font-weight-600">{moment(card.activeUntil).format('LLLL')}</p>
                                                </div>
                                            </div>
                                        </div> */}
                                        {/* STATUS */}
                                        <div className="row mb-4">
                                            <div className="col-12">
                                                <div className="my-card__status my-card__status--waiting">
                                                    <h3 className="mb-2">Status</h3>
                                                    <span className="font-weight-600 mr-3">{statusInfo}</span>
                                                    <a href="#!" className="info" data-toggle="tooltip" data-placement="top" title={statusDescription}>?</a>
                                                </div>
                                                {card.status === 2 && <div className="card-text positon-text"><b>Powód:</b> {card.rejectReason}</div>}
                                            </div>
                                        </div>
                                        {/* REKOMENDOWANE OFERTY */}
                                        <div className="row mb-5">
                                            <div className="col-12">
                                                <div className="my-card__recommended">
                                                    <button
                                                        className="btn btn--primary w-100"
                                                        type="submit"
                                                        disabled={card.matches && card.matches.length > 0 ? false : true}
                                                        onClick={() => goToMatches(card.matches)}>
                                                        Rekomendowane oferty pracy ({card.matches ? card.matches.length : 0})
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row mb-4">
                                        <div className="col-md-6 mb-4 mb-md-0">
                                            <div className="my-card__deactivate">
                                                {activeButton}
                                            </div>
                                        </div>
                                        {/* <div className="col-md-6">
                                            <div className="my-card__refresh">
                                                <button className="btn btn--primary w-100" type="submit" onClick={() => handleRefreshCard(card._id)}>Odśwież</button>
                                            </div>
                                        </div> */}
                                        <div className="col-md-6 mb-4 mb-md-0">
                                            <div className="my-card__edit">
                                                <button className="btn btn--primary w-100" type="submit" onClick={() => handleEditCard(card._id)}>Edytuj</button>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row mb-4">
                                        <div className="col-md-6">
                                            <div className="my-card__delete">
                                                <button className="btn btn--light w-100" type="submit" onClick={() => toggleModal(card._id)}>Usuń</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        })}

                    </div>
                </div>
            </section>
            {cards.length === 0 && !isLoading && <Container>
                <Row className="justify-content-center">
                    <div>Nie znaleziono twoich wizytówek</div>
                </Row>
            </Container>}
            <ConfirmModalComponent show={showConfirmModal} handleToggleModal={toggleModal} handleSubmit={handleDeleteCard} contentText="Czy na pewno chcesz usunąć wizytówkę?" submitText="USUŃ" />
        </div>
    )
}

export default CardsComponent;
