import { ContentState, convertToRaw, EditorState } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import { useFormik } from 'formik';
import htmlToDraft from 'html-to-draftjs';
import _ from 'lodash';
import React, { Fragment, useCallback, useEffect, useState } from 'react';
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { useHistory, useParams } from "react-router-dom";
import CloseIcon from '../../application/assets/img/filter_close.svg';
import AutocompleteComponent from '../../application/components/autocomplete.component';
import ContentnavComponent from '../../application/components/contentNav.component';
import LanguageSelector from '../../application/components/languageSelector.component';
import '../assets/offer.css';
import { OfferInitialState, OfferSchema } from '../schemas/offer.schema';

function OfferComponent(props) {

    const { id } = useParams();
    const { getOffer, clearOffer } = props;

    useEffect(() => {
        if (id) { // jeżeli jest ID to pobieramy ofertę 
            getOffer(id); // pobieramy ofertę
        }
        return () => { // na componendUnmount czyścimy store
            clearOffer();
        }
    }, [id, getOffer, clearOffer]);

    const Offer = (props) => {

        let history = useHistory();

        const { offer } = props;
        const [offerCategories, setOfferCategories] = useState([]);
        const [typeOfContract, setTypeOfContract] = useState([]);
        const [requiredSkills, setRequiredSkills] = useState([]);
        const [areasLevel1, setAreasLevel1] = useState([]);
        const [cities, setCities] = useState([]);
        const [editorStates, setEditorStates] = useState({
            positionDescription: null,
            companyDescription: null,
            requirements: null,
            benefits: null
        });

        const [errors, setApiErrors] = useState(null);
        const formData = useCallback(setFormData, [offer]);

        useEffect(() => { // jak przyjdzie oferta w propsach to ustawiamy ją na formularzu
            formData();
        }, [offer, formData]);

        function handleFocusInputs() {
            let materialInput = document.querySelectorAll('.material-input');

            if (materialInput) {
                materialInput.forEach(function (e) {
                    let input = e.querySelector('.material-input__input');
                    let label = e.querySelector('label');

                    if (input && label) {
                        input.addEventListener('focusin', (event) => {
                            label.classList.add('float-label');
                        });

                        input.addEventListener('focusout', (event) => {
                            if (input.value === "") {
                                label.classList.remove('float-label');
                            }
                        });
                    }
                })
            }
        }

        function handleOnValueInput() {
            let materialInput = document.querySelectorAll('.material-input');

            if (materialInput) {
                materialInput.forEach(function (e) {
                    let input = e.querySelector('.material-input__input');
                    let label = e.querySelector('label');

                    if (input && input.value && input.value !== '') {
                        label.classList.add('float-label');
                    }

                })
            }
        }
        handleOnValueInput();

        function setFormData() {
            let currentEditorStates = { ...editorStates };

            Object.keys(offer).map((name) => {
                if (name === "categories" && offer[name].length > 0) {
                    setOfferCategories(offer[name].map(cat => {
                        return { name: cat.categoryBreadcrumbs, value: cat.categoryId }
                    }));
                    formik.setFieldValue(name, offer[name].map(cat => cat.categoryId));
                } else if (name === 'address') {
                    setCities(offer[name].cities.map((city => ({ name: city, value: city }))));
                    setAreasLevel1(offer[name].administrative_areas_level_1.map((level_1 => ({ name: level_1, value: level_1 }))));
                    formik.setFieldValue(name, offer[name]);
                } else if (name === 'typeOfContract') {
                    setTypeOfContract(_.castArray(offer[name]).map((type => ({ name: type, value: type }))));
                    formik.setFieldValue(name, offer[name]);
                } else if (name === "requiredSkills" && offer[name].length > 0) {
                    setRequiredSkills(offer[name].map((skill => ({ name: skill, value: skill }))));
                    formik.setFieldValue(name, offer[name]);
                } else if (["companyDescription", "positionDescription", "requirements", "benefits"].indexOf(name) >= 0) {
                    const contentBlock = htmlToDraft(offer[name]);
                    if (contentBlock) {
                        const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
                        const currentEditorState = EditorState.createWithContent(contentState);
                        currentEditorStates[name] = currentEditorState;
                        formik.setFieldValue(name, draftToHtml(convertToRaw(currentEditorState.getCurrentContent())));
                    }
                } else {
                    formik.setFieldValue(name, offer[name]);
                }
                return true;
            });
            setEditorStates({ ...currentEditorStates });
        }

        function onEditorStateChange(fieldName, editorState) {
            let currentState = { ...editorStates };
            currentState[fieldName] = editorState;

            setEditorStates({ ...currentState });
            formik.setFieldValue(fieldName, draftToHtml(convertToRaw(editorState.getCurrentContent())));
        }

        function getLengthFromDescription(elementClass) {
            let descriptionLength = 0;
            [...document.querySelectorAll(`.${elementClass} span[data-text]`)].map((element) => {
                return descriptionLength += element.innerHTML.length;
            });
            return descriptionLength;
        }

        function handleSelectLanguage(selectedLanguage) {
            setApiErrors({});
            if (formik.values.languages.indexOf(selectedLanguage) >= 0) {
                setApiErrors({ selectedLanugage: "Ten język już został wybrany" });
            } else {
                formik.setFieldValue("languages", [...formik.values.languages, selectedLanguage]);
            }
        }

        function handleRemoveLanguage(selectedLanguage) {
            formik.setFieldValue("languages", [...formik.values.languages.filter(e => e !== selectedLanguage)]);
        }

        function handleSwitchCountry(wholeCountry, outsiteCountry) {

            setCities([]);
            setAreasLevel1([]);
            formik.setFieldValue("address.administrative_areas_level_1", []);
            formik.setFieldValue("address.cities", []);

            if (wholeCountry !== null) {
                formik.setFieldValue("address.wholeCountry", wholeCountry);
                // formik.setFieldValue("address.outsiteCountry", false);
            }

            if (outsiteCountry !== null) {
                formik.setFieldValue("address.outsiteCountry", outsiteCountry);
                // formik.setFieldValue("address.wholeCountry", false);
            }
        }

        const formik = useFormik({
            initialValues: OfferInitialState,
            validationSchema: OfferSchema,
            onSubmit: (values) => {
                // próbujemy dodać / zapisać kartę
                props.saveOffer(values).then(
                    () => {
                        // jak się uda to spadamy stąd
                        history.push('/offers');
                        return;
                    },
                    (errors) => {
                        // nie udało się to podstawiamy błędy
                        setApiErrors(errors);
                    });
            }
        });

        function removeCategoryFromSelected(value) {
            let filterSelectedValues = offerCategories.filter((selectedValue) => {
                return selectedValue.value !== value.value
            });

            setOfferCategories(filterSelectedValues);
        }

        function removeTypeOfContractFromSelected(value) {
            let filterSelectedValues = formik.values.typeOfContract.filter((selectedValue) => {
                return selectedValue.value !== value.value
            });
            formik.setFieldValue("typeOfContract", filterSelectedValues);
            setTypeOfContract(_.without(typeOfContract, value))
        }

        function removeAreasLevel1FromSelected(value) {
            let filterSelectedValues = areasLevel1.filter((selectedValue) => {
                return selectedValue.name !== value.name
            });

            formik.setFieldValue("address.administrative_areas_level_1", filterSelectedValues);
            setAreasLevel1(filterSelectedValues);
        }

        function removeCityFromSelected(value) {
            let filterSelectedValues = cities.filter((selectedValue) => {
                return selectedValue.name !== value.name
            });

            formik.setFieldValue("address.cities", filterSelectedValues);
            setCities(filterSelectedValues);
        }

        function removeRequiredSkillsFromSelected(value) {
            let filterSelectedValues = requiredSkills.filter((selectedValue) => {
                return selectedValue.name !== value.name
            });

            formik.setFieldValue("requiredSkills", filterSelectedValues);
            setRequiredSkills(filterSelectedValues);
        }

        return (
            <form onSubmit={formik.handleSubmit}>
                <ContentnavComponent />
                <section id="addCard" className="add-card__content">
                    <div className="container-fluid">
                        <div className="row justify-content-center">
                            <div className="col-12 col-xl-8 mb-5">
                                <div className="add-card__info bg-white p-5">
                                    <div className="row mb-4">
                                        <div className="col-12">
                                            <h3 className="mb-4">Podstawowe informacje</h3>
                                        </div>
                                        {/* Kategorie */}
                                        <div className={`col-12 ${(formik.errors.categories && formik.touched.categories) || (errors && errors.categories) ? 'input-error' : ''} material-input`}>
                                            {/* WYBRANE KATEGORIE */}
                                            <div className="row no-gutters mb-4">
                                                {offerCategories && offerCategories.length > 0 && offerCategories.map((offerCategory, index) => {
                                                    return <div className="col-auto" key={'category-' + index}>
                                                        <button
                                                            type="button"
                                                            className="btn btn--filter mr-4 mt-4 filter-result__single"
                                                            onClick={() => removeCategoryFromSelected(offerCategory)}>{offerCategory.name}
                                                            <span className="ml-5"><img src={CloseIcon} alt="" /></span>
                                                        </button>
                                                    </div>
                                                })}
                                            </div>
                                            <AutocompleteComponent
                                                state={offerCategories}
                                                setState={setOfferCategories}
                                                options={props.categoryTree}
                                                multiple={true}
                                                value={formik.values.categories}
                                                handleOnSelect={(values) => formik.setFieldValue("categories", values.map(cat => cat.value))}
                                                placeholder="Kategoria oferty"
                                                isRequired={true}
                                                handleFocusInputs={() => handleFocusInputs()}
                                            />
                                            {formik.errors.categories && formik.touched.categories ? (
                                                <div className="material-input__error">{formik.errors.categories}</div>
                                            ) : null}
                                            {errors && errors.categories ? (
                                                <div className="material-input__error">{errors.categories}</div>
                                            ) : null}
                                        </div>
                                        {/* RODZAJ UMOWY */}
                                        <div className={`col-12 mb-4 ${(formik.errors.typeOfContract && formik.touched.typeOfContract) || (errors && errors.typeOfContract) ? 'input-error' : ''} material-input`}>
                                            {/* WYBRANA UMOWA */}
                                            <div className="row no-gutters mb-4">
                                                {typeOfContract && typeOfContract.length > 0 && typeOfContract.map((contract, index) => {
                                                    return <div className="col-auto" key={'category-' + index}>
                                                        <button
                                                            type="button"
                                                            className="btn btn--filter mr-4 mt-4 filter-result__single"
                                                            onClick={() => removeTypeOfContractFromSelected(contract)}>{contract.name}
                                                            <span className="ml-5"><img src={CloseIcon} alt="" /></span>
                                                        </button>
                                                    </div>
                                                })}
                                            </div>
                                            <AutocompleteComponent
                                                state={typeOfContract}
                                                setState={setTypeOfContract}
                                                multiple={true}
                                                options={[
                                                    { name: "Umowa o pracę", value: "Umowa o pracę" },
                                                    { name: "Umowa o dzieło", value: "Umowa o dzieło" },
                                                    { name: "Umowa zlecenie", value: "Umowa zlecenie" },
                                                    { name: "Umowa o zastępstwo", value: "Umowa o zastępstwo" },
                                                    { name: "Umowa B2B", value: "Umowa B2B" },
                                                    { name: "staż/praktyka", value: "staż/praktyka" }
                                                ]}
                                                value={formik.values.typeOfContract}
                                                handleOnSelect={(values) => formik.setFieldValue("typeOfContract", values.length > 0 ? values.map(type => type.value) : "")}
                                                placeholder="Rodzaj umowy"
                                                isRequired={true}
                                                handleFocusInputs={() => handleFocusInputs()} />
                                            {formik.errors.typeOfContract && formik.touched.typeOfContract ? (
                                                <div className="material-input__error">{formik.errors.typeOfContract}</div>
                                            ) : null}
                                            {errors && errors.typeOfContract ? (
                                                <div className="material-input__error">{errors.typeOfContract}</div>
                                            ) : null}
                                        </div>
                                        {/* STANOWISKO */}
                                        <div className={`col-12 material-input ${(formik.errors.position && formik.touched.position) || (errors && errors.position) ? 'input-error' : ''}`}>
                                            <div className="d-flex align-items-center position-relative">
                                                <label className="ml-5">Stanowisko<span className="required"> *</span></label>
                                                <input
                                                    id="position"
                                                    name="position"
                                                    type="text"
                                                    onChange={formik.handleChange}
                                                    value={formik.values.position}
                                                    className="material-input__input h-100" />
                                            </div>
                                            {formik.errors.position && formik.touched.position ? (
                                                <div className="material-input__error">{formik.errors.position}</div>
                                            ) : null}
                                            {errors && errors.position ? (
                                                <div className="material-input__error">{errors.position}</div>
                                            ) : null}
                                        </div>
                                        {/* LOKALIZACJA */}
                                        <div className="col-12 mt-50">
                                            <h3 className="mb-4">Lokalizacja</h3>
                                            <div className="row mb-5">
                                                <div className="col-auto">
                                                    <div className="add-card__country d-flex align-items-md-center flex-column-reverse flex-md-row position-relative">
                                                        <label className="pure-material-switch">
                                                            <input
                                                                type="checkbox"
                                                                checked={formik.values.address.wholeCountry}
                                                                onChange={(event) => { handleSwitchCountry(event.target.checked, null) }} />
                                                            <span></span>
                                                        </label>
                                                        <span className="pure-material-switch__label ml-3 text--light-grey">Cały kraj</span>
                                                    </div>
                                                </div>
                                                <div className="col-auto">
                                                    <div className="add-card__world d-flex align-items-md-center flex-column-reverse flex-md-row position-relative">
                                                        <label className="pure-material-switch">
                                                            <input
                                                                type="checkbox"
                                                                checked={formik.values.address.outsiteCountry}
                                                                onChange={(event) => { handleSwitchCountry(null, event.target.checked) }} />
                                                            <span></span>
                                                        </label>
                                                        <span className="pure-material-switch__label ml-3 text--light-grey">Praca za granicą</span>
                                                    </div>
                                                </div>
                                            </div>
                                            {!_.get(formik, 'values.address.wholeCountry', null) && !_.get(formik, 'values.address.outsiteCountry', null) && <div className="row">
                                                {/* WOJEWODZTWO */}
                                                <div className={`col-12 material-input ${(formik.errors.address && formik.touched.address && formik.errors.address.administrative_areas_level_1) || (errors && errors.address && errors.address.administrative_areas_level_1) ? 'input-error' : ''}`}>
                                                    {/* WYBRANE WOJEWODZTWA */}
                                                    <div className="row no-gutters mb-4">
                                                        {areasLevel1 && areasLevel1.length > 0 && areasLevel1.map((areaLevel1, index) => {
                                                            return <div className="col-auto" key={'tag-' + index}>
                                                                <button
                                                                    type="button"
                                                                    className="btn btn--filter mr-4 mt-4 filter-result__single"
                                                                    onClick={() => removeAreasLevel1FromSelected(areaLevel1)}>{areaLevel1.name}
                                                                    <span className="ml-5"><img src={CloseIcon} alt="" /></span>
                                                                </button>
                                                            </div>
                                                        })}
                                                    </div>
                                                    <AutocompleteComponent
                                                        state={areasLevel1}
                                                        setState={setAreasLevel1}
                                                        options={props.getAllAdministrativeAreaLevel1()}
                                                        value={formik.values.address.administrative_areas_level_1}
                                                        handleOnSelect={(values) => formik.setFieldValue("address.administrative_areas_level_1", values.map((level_1 => level_1.value)))}
                                                        placeholder="Województwo"
                                                        multiple={true}
                                                        isRequired={true}
                                                        handleFocusInputs={() => handleFocusInputs()}
                                                    />
                                                    {formik.errors.address && formik.touched.address && formik.errors.address.administrative_areas_level_1 ? (
                                                        <div className="material-input__error">{formik.errors.address.administrative_areas_level_1}</div>
                                                    ) : null}
                                                    {errors && errors.address && errors.address.administrative_areas_level_1 ? (
                                                        <div className="material-input__error">{errors.address.administrative_areas_level_1}</div>
                                                    ) : null}
                                                </div>
                                                {/* MIASTO */}
                                                <div className={`col-12 material-input mb-5 ${(formik.errors.address && formik.touched.address && formik.errors.address.cities) || (errors && errors.address && errors.address.cities) ? 'input-error' : ''}`}>
                                                    {/* WYBRANE MIASTA */}
                                                    <div className="row no-gutters mb-4">
                                                        {cities && cities.length > 0 && cities.map((city, index) => {
                                                            return <div className="col-auto" key={'tag-' + index}>
                                                                <button
                                                                    type="button"
                                                                    className="btn btn--filter mr-4 mt-4 filter-result__single"
                                                                    onClick={() => removeCityFromSelected(city)}>{city.name}
                                                                    <span className="ml-5"><img src={CloseIcon} alt="" /></span>
                                                                </button>
                                                            </div>
                                                        })}
                                                    </div>
                                                    <AutocompleteComponent
                                                        state={cities}
                                                        setState={setCities}
                                                        options={[]}
                                                        getOptionsMethod={props.getPlaces}
                                                        value={formik.values.address.cities}
                                                        handleOnSelect={(values) => formik.setFieldValue("address.cities", values.map((city => city.value)))}
                                                        placeholder="Miasto"
                                                        multiple={true}
                                                        handleFocusInputs={() => handleFocusInputs()}
                                                    />
                                                    {formik.errors.address && formik.touched.address && formik.errors.address.cities ? (
                                                        <div className="material-input__error">{formik.errors.address.cities}</div>
                                                    ) : null}
                                                    {errors && errors.address && errors.address.cities ? (
                                                        <div className="material-input__error">{errors.address.cities}</div>
                                                    ) : null}
                                                </div>
                                            </div>}
                                        </div>
                                    </div>
                                </div>

                                <div className="add-card__info bg-white p-5 mt-4">
                                    {/* WYMAGANE JEZYKI */}
                                    <div className="row">
                                        <div className="col">
                                            <h3 className="mb-4">Wymagane języki<span className="required"> *</span></h3>
                                        </div>
                                        <div className="col-12 mb-4">
                                            <div className="row no-gutters">
                                                {formik.values.languages && formik.values.languages.map((language, index) => {
                                                    return <div className="col-auto" key={'language-' + index}>
                                                        <button
                                                            type="button"
                                                            className="btn btn--filter mr-4 mt-4 filter-result__single"
                                                            onClick={() => handleRemoveLanguage(language)}>{language}
                                                            <span className="ml-5"><img src={CloseIcon} alt="" /></span>
                                                        </button>
                                                    </div>
                                                })}
                                            </div>
                                        </div>
                                        <LanguageSelector
                                            errors={errors}
                                            formikErrors={formik.errors.languages}
                                            formikTouched={formik.touched.languages}
                                            setErrors={setApiErrors}
                                            handleSelectLanguage={handleSelectLanguage}
                                            handleFocusInputs={() => handleFocusInputs()}
                                            isRequired={true} />
                                    </div>
                                </div>

                                <div className="add-card__info bg-white p-5 mt-4">
                                    {/* O FIRMIE */}
                                    <div className="row">
                                        <div className="col">
                                            <h3 className="mb-4">O firmie<span className="required"> *</span></h3>
                                        </div>
                                        <div className="col">
                                            <p className="h3 text-right">Ilość znaków: <span id="addCardCharacters">{` ${getLengthFromDescription("companyDescription")}/4000`}</span></p>
                                        </div>
                                    </div>
                                    <div className={`material-input ${(formik.errors.companyDescription && formik.touched.companyDescription) || (errors && errors.companyDescription) ? 'input-error' : ''}`}>
                                        <div className="add-card__editor">
                                            <Editor
                                                wrapperClassName="companyDescription"
                                                editorState={editorStates.companyDescription}
                                                editorClassName="editor-text-wrapper material-input__textarea"
                                                stripPastedStyles={true}
                                                toolbar={{
                                                    options: ["fontSize", "inline", "list", "history"],
                                                    fontSize: {
                                                        options: [16]
                                                    },
                                                    inline: {
                                                        options: ["bold", "italic", "underline"]
                                                    }
                                                }}
                                                onEditorStateChange={(editorState) => onEditorStateChange("companyDescription", editorState)}
                                            />
                                            {formik.errors.companyDescription && formik.touched.companyDescription ? (
                                                <div className="material-input__error">{formik.errors.companyDescription}</div>
                                            ) : null}
                                            {errors && errors.companyDescription ? (
                                                <div className="material-input__error">{errors.companyDescription}</div>
                                            ) : null}
                                        </div>
                                    </div>

                                    {/* OPIS STANOWISKA */}
                                    <div className="mt-50">
                                        <div className="row">
                                            <div className="col">
                                                <h3 className="mb-4">Opis stanowiska<span className="required"> *</span></h3>
                                            </div>
                                            <div className="col">
                                                <p className="h3 text-right">Ilość znaków: <span id="addCardCharacters">{`${getLengthFromDescription("positionDescription")}/2000`}</span></p>
                                            </div>
                                        </div>
                                        <div className={`material-input ${(formik.errors.positionDescription && formik.touched.positionDescription) || (errors && errors.positionDescription) ? 'input-error' : ''}`}>
                                            <div className="add-card__editor">
                                                <Editor
                                                    wrapperClassName="positionDescription"
                                                    editorState={editorStates.positionDescription}
                                                    editorClassName="editor-text-wrapper material-input__textarea"
                                                    stripPastedStyles={true}
                                                    toolbar={{
                                                        options: ["fontSize", "inline", "list", "history"],
                                                        fontSize: {
                                                            options: [16]
                                                        },
                                                        inline: {
                                                            options: ["bold", "italic", "underline"]
                                                        }
                                                    }}
                                                    onEditorStateChange={(editorState) => onEditorStateChange("positionDescription", editorState)}
                                                />
                                                {formik.errors.positionDescription && formik.touched.positionDescription ? (
                                                    <div className="material-input__error">{formik.errors.positionDescription}</div>
                                                ) : null}
                                                {errors && errors.positionDescription ? (
                                                    <div className="material-input__error">{errors.positionDescription}</div>
                                                ) : null}
                                            </div>
                                        </div>
                                    </div>

                                    {/* OFERUJEMY */}
                                    <div className="mt-50">
                                        <div className="row">
                                            <div className="col">
                                                <h3 className="mb-4">Oferujemy<span className="required"> *</span></h3>
                                            </div>
                                            <div className="col">
                                                <p className="h3 text-right">Ilość znaków: <span id="addCardCharacters">{` ${getLengthFromDescription("benefits")}/1000`}</span></p>
                                            </div>
                                        </div>
                                        <div className={`material-input ${(formik.errors.benefits && formik.touched.benefits) || (errors && errors.benefits) ? 'input-error' : ''}`}>
                                            <div className="add-card__editor">
                                                <Editor
                                                    wrapperClassName="benefits"
                                                    editorState={editorStates.benefits}
                                                    editorClassName="editor-text-wrapper material-input__textarea"
                                                    stripPastedStyles={true}
                                                    toolbar={{
                                                        options: ["fontSize", "inline", "list", "history"],
                                                        fontSize: {
                                                            options: [16]
                                                        },
                                                        inline: {
                                                            options: ["bold", "italic", "underline"]
                                                        }
                                                    }}
                                                    onEditorStateChange={(editorState) => onEditorStateChange("benefits", editorState)}
                                                />
                                                {formik.errors.benefits && formik.touched.benefits ? (
                                                    <div className="material-input__error">{formik.errors.benefits}</div>
                                                ) : null}
                                                {errors && errors.benefits ? (
                                                    <div className="material-input__error">{errors.benefits}</div>
                                                ) : null}
                                            </div>
                                        </div>
                                    </div>

                                    {/* WYMAGANIA */}
                                    <div className="mt-50">
                                        <div className="row">
                                            <div className="col">
                                                <h3 className="mb-4">Wymagania<span className="required"> *</span></h3>
                                            </div>
                                            <div className="col">
                                                <p className="h3 text-right">Ilość znaków: <span id="addCardCharacters">{` ${getLengthFromDescription("requirements")}/2000`}</span></p>
                                            </div>
                                        </div>
                                        <div className={`material-input ${(formik.errors.requirements && formik.touched.requirements) || (errors && errors.requirements) ? 'input-error' : ''}`}>
                                            <div className="add-card__editor">
                                                <Editor
                                                    wrapperClassName="requirements"
                                                    editorState={editorStates.requirements}
                                                    editorClassName="editor-text-wrapper material-input__textarea"
                                                    stripPastedStyles={true}
                                                    toolbar={{
                                                        options: ["fontSize", "inline", "list", "history"],
                                                        fontSize: {
                                                            options: [16]
                                                        },
                                                        inline: {
                                                            options: ["bold", "italic", "underline"]
                                                        }
                                                    }}
                                                    onEditorStateChange={(editorState) => onEditorStateChange("requirements", editorState)}
                                                />
                                                {formik.errors.requirements && formik.touched.requirements ? (
                                                    <div className="material-input__error">{formik.errors.requirements}</div>
                                                ) : null}
                                                {errors && errors.requirements ? (
                                                    <div className="material-input__error">{errors.requirements}</div>
                                                ) : null}
                                            </div>
                                        </div>
                                    </div>

                                    {/* DODATKOWE UMIEJETNOSCI */}
                                    <div className="mt-50">
                                        <h3 className="mb-4">Dodatkowe umiejętności<span className="required"> *</span></h3>
                                        <div className={`${(formik.errors.requiredSkills && formik.touched.requiredSkills) || (errors && errors.requiredSkills) ? 'input-error' : ''} material-input`}>
                                            {/* WYBRANE DODATKOWE UMIEJETNOSCI */}
                                            <div className="row no-gutters mb-4">
                                                {requiredSkills && requiredSkills.length > 0 && requiredSkills.map((requiredSkills, index) => {
                                                    return <div className="col-auto" key={'tag-' + index}>
                                                        <button
                                                            type="button"
                                                            className="btn btn--filter mr-4 mt-4 filter-result__single"
                                                            onClick={() => removeRequiredSkillsFromSelected(requiredSkills)}>{requiredSkills.name}
                                                            <span className="ml-5"><img src={CloseIcon} alt="" /></span>
                                                        </button>
                                                    </div>
                                                })}
                                            </div>
                                            <AutocompleteComponent
                                                state={requiredSkills}
                                                setState={setRequiredSkills}
                                                options={[]}
                                                onlyFilteredValue={true}
                                                value={formik.values.requiredSkills}
                                                handleOnSelect={(values) => formik.setFieldValue("requiredSkills", values.map((tag => tag.value)))}
                                                placeholder="Dodatkowe umiejętności"
                                                multiple={true}
                                                isRequired={true}
                                                handleFocusInputs={() => handleFocusInputs()}
                                            />
                                            {formik.errors.requiredSkills && formik.touched.requiredSkills ? (
                                                <div className="material-input__error">{formik.errors.requiredSkills}</div>
                                            ) : null}
                                            {errors && errors.requiredSkills ? (
                                                <div className="material-input__error">{errors.requiredSkills}</div>
                                            ) : null}
                                        </div>
                                    </div>
                                </div>

                                <div className="add-card__info bg-white p-5 mt-4">
                                    {/* REKRUTER */}
                                    <h3 className="mb-4">Dane kontaktowe</h3>
                                    <div className="row">
                                        <Fragment>
                                            <div className={`col-12 col-lg-4 mb-4 ${(formik.errors.recruiter && formik.touched.recruiter && formik.errors.recruiter.name) || (errors && errors.recruiter && errors.recruiter.name) ? 'input-error' : ''} material-input`}>
                                                <div className="d-flex align-items-center">
                                                    <label className="ml-5">Imie </label>
                                                    <input
                                                        id="recruiter.name"
                                                        name="recruiter.name"
                                                        type="text"
                                                        onChange={formik.handleChange}
                                                        value={formik.values.recruiter.name}
                                                        autoComplete="new-password"
                                                        onFocus={() => handleFocusInputs()}
                                                        className="material-input__input h-100" />
                                                </div>
                                                {formik.errors.recruiter && formik.touched.recruiter && formik.errors.recruiter.name ? (
                                                    <div className="material-input__error">{formik.errors.recruiter.name}</div>
                                                ) : null}
                                                {errors && errors.recruiter && errors.recruiter.name ? (
                                                    <div className="material-input__error">{errors.recruiter.name}</div>
                                                ) : null}
                                            </div>
                                            <div className={`col-12 col-lg-4 mb-4 ${(formik.errors.recruiter && formik.touched.recruiter && formik.errors.recruiter.surname) || (errors && errors.recruiter && errors.recruiter.surname) ? 'input-error' : ''} material-input`}>
                                                <div className="d-flex align-items-center">
                                                    <label className="ml-5">Nazwisko </label>
                                                    <input
                                                        id="recruiter.surname"
                                                        name="recruiter.surname"
                                                        type="text"
                                                        onChange={formik.handleChange}
                                                        value={formik.values.recruiter.surname}
                                                        autoComplete="new-password"
                                                        onFocus={() => handleFocusInputs()}
                                                        className="material-input__input h-100" />
                                                </div>
                                                {formik.errors.recruiter && formik.touched.recruiter && formik.errors.recruiter.surname ? (
                                                    <div className="material-input__error">{formik.errors.recruiter.surname}</div>
                                                ) : null}
                                                {errors && errors.recruiter && errors.recruiter.surname ? (
                                                    <div className="material-input__error">{errors.recruiter.surname}</div>
                                                ) : null}
                                            </div>
                                            <div className={`col-12 col-lg-4 ${(formik.errors.recruiter && formik.touched.recruiter && formik.errors.recruiter.contact) || (errors && errors.recruiter && errors.recruiter.contact) ? 'input-error' : ''} material-input`}>
                                                <div className="d-flex align-items-center">
                                                    <label className="ml-5">Email / telefon <span className="required"> *</span></label>
                                                    <input
                                                        id="recruiter.contact"
                                                        name="recruiter.contact"
                                                        type="text"
                                                        onChange={formik.handleChange}
                                                        value={formik.values.recruiter.contact}
                                                        autoComplete="new-password"
                                                        onFocus={() => handleFocusInputs()}
                                                        className="material-input__input h-100" />
                                                </div>
                                                {formik.errors.recruiter && formik.touched.recruiter && formik.errors.recruiter.contact ? (
                                                    <div className="material-input__error">{formik.errors.recruiter.contact}</div>
                                                ) : null}
                                                {errors && errors.recruiter && errors.recruiter.contact ? (
                                                    <div className="material-input__error">{errors.recruiter.contact}</div>
                                                ) : null}
                                            </div>
                                        </Fragment>
                                    </div>
                                    <div className="row mt-5 col-12 justify-content-center">
                                        <div className="col-12 col-lg-5">
                                            <button id="addCardSubmit" className="btn btn--primary w-100" type="submit"> {props.offer && props.offer._id ? 'ZAPISZ' : 'DODAJ'}</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </form >
        )
    };


    return <Offer {...props} />
}

export default OfferComponent;
