import { SET_IS_LOGGED, SET_IS_AUTH_LOADING, SET_IS_LOGOUT } from '../actions/auth.action';

const initial_state = {
    loggedUser: null,
    isAuthLoading: true,
}

const auth = (state = initial_state, action) => {
    switch (action.type) {
        case SET_IS_LOGGED:
            return {
                loggedUser: action.loggedUser
            };
        case SET_IS_AUTH_LOADING:
            return {
                ...state,
                isAuthLoading: action.isAuthLoading
            };
        case SET_IS_LOGOUT:
            return {
                loggedUser: null
            };
        default:
            return state;
    }
};

export default auth;