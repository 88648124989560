import { useFormik } from 'formik';
import React, { useCallback, useEffect, useState } from 'react';
import AutocompleteComponent from '../../application/components/autocomplete.component';
import ConfirmModalComponent from '../../application/components/confirmModal.component';
import '../assets/profile.css';
import { ProfileCompanyInitialValues, ProfileCompanySchema } from '../schemas/profile.schema';

import CloseIcon from '../../application/assets/img/filter_close.svg';

import ContentnavComponent from '../../application/components/contentNav.component';

function CompanyProfileComponent(props) {

    const { getProfile, clearProfile, deleteAccount } = props;
    const [showModal, setShowModal] = useState(false);

    useEffect(() => {
        getProfile();
        return () => {
            clearProfile();
        }
    }, [getProfile, clearProfile]);

    function toggleModal() {
        setShowModal(!showModal);
    }

    function handleDeleteAccount() {
        toggleModal(); // zamykamy modal
        deleteAccount();
    }

    const Profile = (props) => {

        const { profile } = props;

        const [areaProfileLevel1, setProfileAreaLevel1] = useState([]);
        const [profileCity, setProfileCity] = useState([]);
        const [errors, setApiErrors] = useState(null);
        const formData = useCallback(setFormData, [profile]);

        useEffect(() => { // jak przyjdzie profil w propsach to ustawiamy go na formularzu
            formData();
        }, [profile, formData]);

        function handleFocusInputs() {
            let materialInput = document.querySelectorAll('.material-input');

            if (materialInput) {
                materialInput.forEach(function (e) {
                    let input = e.querySelector('.material-input__input');
                    let label = e.querySelector('label');

                    if (input && label) {
                        input.addEventListener('focusin', (event) => {
                            label.classList.add('float-label');
                        })

                        input.addEventListener('focusout', (event) => {
                            if (input.value === "") {
                                label.classList.remove('float-label');
                            }
                        })
                    }

                })
            }
        }

        function handleOnValueInput() {
            let materialInput = document.querySelectorAll('.material-input');

            if (materialInput) {
                materialInput.forEach(function (e) {
                    let input = e.querySelector('.material-input__input');
                    let label = e.querySelector('label');

                    if (input && input.value && input.value !== '') {
                        label.classList.add('float-label');
                    }

                })
            }
        }
        handleOnValueInput();

        function setFormData() {
            Object.keys(profile).map((name) => {
                if (name === 'address') {
                    setProfileCity([{ name: profile[name].city, value: profile[name].city }]);
                    setProfileAreaLevel1([{ name: profile[name].administrative_area_level_1, value: profile[name].administrative_area_level_1 }]);
                    formik.setFieldValue(name, profile[name]);
                } else {
                    formik.setFieldValue(name, profile[name]);
                }
                return true;
            });
        }

        const formik = useFormik({
            initialValues: ProfileCompanyInitialValues,
            validationSchema: ProfileCompanySchema,
            onSubmit: (values) => {
                props.saveProfile(values).then(
                    () => { },
                    (errors) => {
                        // nie udało się to podstawiamy błędy
                        setApiErrors(errors);
                    });
            }
        });

        function removeProfileCityFromSelected(value) {
            let filterSelectedValues = profileCity.filter((selectedValue) => {
                return selectedValue.name !== value.name
            });

            formik.setFieldValue("address.profileCity", filterSelectedValues);
            setProfileCity(filterSelectedValues);
        }

        function removeAreasProfileLevel1FromSelected(value) {
            let filterSelectedValues = areaProfileLevel1.filter((selectedValue) => {
                return selectedValue.name !== value.name
            });

            formik.setFieldValue("address.administrative_area_level_1", filterSelectedValues);
            setProfileAreaLevel1(filterSelectedValues);
        }

        return (
            <form onSubmit={formik.handleSubmit}>
                <ContentnavComponent />
                <section id="myProfile" className="my-profile__content">
                    <div className="container-fluid">
                        <div className="row justify-content-center">
                            <div className="col-12 col-xl-8 mb-5">
                                <div className="my-profile__info bg-white p-5">
                                    <div className="row mb-4">
                                        <div className="col-12">
                                            <h3 className="mb-4">Dane osobowe<span className="required"> *</span></h3>
                                        </div>
                                        {/* PELNA NAZWA FIRMY */}
                                        <div className={`col-12 col-md-6 ${(formik.errors.companyName && formik.touched.companyName) || (errors && errors.companyName) ? 'input-error' : ''} material-input mb-5`}>
                                            <div className="my-profile__name d-flex align-items-center position-relative">
                                                <label className="ml-5">Pełna nazwa firmy<span className="required">  *</span></label>
                                                <input
                                                    id="companyName"
                                                    name="companyName"
                                                    type="text"
                                                    autoComplete="off"
                                                    onChange={formik.handleChange}
                                                    value={formik.values.companyName}
                                                    className="material-input__input h-100"
                                                    onFocus={() => handleFocusInputs()} />
                                            </div>
                                            {formik.errors.companyName && formik.touched.companyName ? (
                                                <div className="material-input__error">{formik.errors.companyName}</div>
                                            ) : null}
                                            {errors && errors.companyName ? (
                                                <div className="material-input__error">{errors.companyName}</div>
                                            ) : null}
                                        </div>
                                        {/* NIP */}
                                        <div className={`my-profile__last-name col-12 ${(formik.errors.NIP && formik.touched.NIP) || (errors && errors.NIP) ? 'input-error' : ''} col-md-6 material-input mb-5`}>
                                            <div className="d-flex align-items-center position-relative">
                                                <label className="ml-5">NIP</label>
                                                <input id="NIP"
                                                    name="NIP"
                                                    type="text"
                                                    autoComplete="off"
                                                    onChange={formik.handleChange}
                                                    value={formik.values.NIP}
                                                    className="material-input__input h-100" />
                                            </div>
                                            {formik.errors.NIP && formik.touched.NIP ? (
                                                <div className="material-input__error">{formik.errors.NIP}</div>
                                            ) : null}
                                            {errors && errors.NIP ? (
                                                <div className="material-input__error">{errors.NIP}</div>
                                            ) : null}
                                        </div>
                                        {/* EMAIL */}
                                        <div className={`my-profile__mail col-12 ${(formik.errors.email && formik.touched.email) || (errors && errors.email) ? 'input-error' : ''} col-md-6 material-input mb-5`}>
                                            <div className="d-flex align-items-center position-relative">
                                                <label className="ml-5">E-mail<span className="required"> *</span></label>
                                                <input
                                                    id="email"
                                                    name="email"
                                                    type="email"
                                                    autoComplete="off"
                                                    onChange={formik.handleChange}
                                                    value={formik.values.email}
                                                    className="material-input__input h-100" />
                                            </div>
                                            {formik.errors.email && formik.touched.email ? (
                                                <div className="material-input__error">{formik.errors.email}</div>
                                            ) : null}
                                            {errors && errors.email ? (
                                                <div className="material-input__error">{errors.email}</div>
                                            ) : null}
                                        </div>
                                        {/* TELEFON */}
                                        <div className={`my-profile__phone col-12 ${(formik.errors.phone && formik.touched.phone) || (errors && errors.phone) ? 'input-error' : ''} col-md-6 material-input mb-5`}>
                                            <div className="d-flex align-items-center position-relative">
                                                <label className="ml-5">Telefon</label>
                                                <input id="phone"
                                                    name="phone"
                                                    autoComplete="new-password"
                                                    value={formik.values.phone}
                                                    onChange={formik.handleChange}
                                                    className="material-input__input h-100" />
                                            </div>
                                            {formik.errors.phone && formik.touched.phone ? (
                                                <div className="material-input__error">{formik.errors.phone}</div>
                                            ) : null}
                                            {errors && errors.phone ? (
                                                <div className="material-input__error">{errors.phone}</div>
                                            ) : null}
                                        </div>
                                        {/* WOJEWODZTWO */}
                                        <div className={`col-12 material-input mb-4 ${(formik.errors.address && formik.touched.address && formik.errors.address.administrative_area_level_1) ||
                                            (errors && errors.address && errors.address.administrative_area_level_1) ? 'input-error' : ''}`}>
                                            {/* WYBRANE WOJEWODZTWA */}
                                            <div className="row no-gutters mb-4">
                                                {areaProfileLevel1 && areaProfileLevel1.length > 0 && areaProfileLevel1.map((areaProfileLevel1, index) => {
                                                    return <div className="col-auto" key={'tag-' + index}>
                                                        <button
                                                            className="btn btn--filter mr-4 mt-4 filter-result__single"
                                                            onClick={() => removeAreasProfileLevel1FromSelected(areaProfileLevel1)}>{areaProfileLevel1.name}
                                                            <span className="ml-5"><img src={CloseIcon} alt="" /></span>
                                                        </button>
                                                    </div>
                                                })}
                                            </div>
                                            <AutocompleteComponent
                                                state={areaProfileLevel1}
                                                setState={setProfileAreaLevel1}
                                                options={props.getAllAdministrativeAreaLevel1()}
                                                value={formik.values.address.administrative_area_level_1}
                                                handleOnSelect={(values) => formik.setFieldValue("address.administrative_area_level_1", values.length > 0 ? values[0].value : "")}
                                                placeholder="Województwo"
                                                isRequired={true}
                                                handleFocusInputs={() => handleFocusInputs()} />
                                            {formik.errors.address && formik.touched.address && formik.errors.address.administrative_area_level_1 ? (
                                                <div className="material-input__error">{formik.errors.address.administrative_area_level_1}</div>
                                            ) : null}
                                            {errors && errors.address && errors.address.administrative_area_level_1 ? (
                                                <div className="material-input__error">{errors.address.administrative_area_level_1}</div>
                                            ) : null}

                                        </div>
                                        {/* MIASTO */}
                                        <div className={`col-12 material-input ${(formik.errors.address && formik.touched.address && formik.errors.address.city) ||
                                            (errors && errors.address && errors.address.city) ? 'input-error' : ''} mb-5`}>
                                            {/* WYBRANE MIASTA */}
                                            <div className="row no-gutters mb-4">
                                                {profileCity && profileCity.length > 0 && profileCity.map((city, index) => {
                                                    return <div className="col-auto" key={'tag-' + index}>
                                                        <button
                                                            type="button"
                                                            className="btn btn--filter mr-4 mt-4 filter-result__single"
                                                            onClick={() => removeProfileCityFromSelected(city)}>{city.name}
                                                            <span className="ml-5"><img src={CloseIcon} alt="" /></span>
                                                        </button>
                                                    </div>
                                                })}
                                            </div>
                                            <AutocompleteComponent
                                                state={profileCity}
                                                setState={setProfileCity}
                                                options={[]}
                                                getOptionsMethod={props.getPlaces}
                                                value={formik.values.address.city}
                                                handleOnSelect={(values) => formik.setFieldValue("address.city", values.length > 0 ? values[0].value : "")}
                                                placeholder="Miasto"
                                                isRequired={true}
                                                handleFocusInputs={() => handleFocusInputs()}
                                            />
                                            {formik.errors.address && formik.touched.address && formik.errors.address.city ? (
                                                <div className="material-input__error">{formik.errors.address.city}</div>
                                            ) : null}
                                            {errors && errors.address && errors.address.city ? (
                                                <div className="material-input__error">{errors.address.city}</div>
                                            ) : null}

                                        </div>
                                        {/* ULICA */}
                                        <div className={`col-12 col-md-6 ${(formik.errors.address && formik.touched.address && formik.errors.address.street) || (errors && errors.address && errors.address.street) ? 'input-error' : ''} material-input mb-5`}>
                                            <div className="my-profile__name d-flex align-items-center position-relative">
                                                <label className="ml-5">Ulica<span className="required">  *</span></label>
                                                <input
                                                    type="text"
                                                    id="address.street"
                                                    name="address.street"
                                                    autoComplete="off"
                                                    onChange={formik.handleChange}
                                                    value={formik.values.address.street}
                                                    className="material-input__input h-100"
                                                    onFocus={() => handleFocusInputs()} />
                                            </div>
                                            {formik.errors.address && formik.touched.address && formik.errors.address.street ? (
                                                <div className="material-input__error">{formik.errors.address.street}</div>
                                            ) : null}
                                            {errors && errors.address && errors.address.street ? (
                                                <div className="material-input__error">{errors.address.street}</div>
                                            ) : null}
                                        </div>
                                        {/* NUMER ULICY */}
                                        <div className={`col-12 col-md-6 ${(formik.errors.address && formik.touched.address && formik.errors.address.streetNumber) ||
                                            (errors && errors.address && errors.address.streetNumber) ? 'input-error' : ''} material-input mb-5`}>
                                            <div className="my-profile__name d-flex align-items-center position-relative">
                                                <label className="ml-5">Nr. ulicy<span className="required">  *</span></label>
                                                <input
                                                    type="text"
                                                    id="address.streetNumber"
                                                    name="address.streetNumber"
                                                    autoComplete="off"
                                                    onChange={formik.handleChange}
                                                    value={formik.values.address.streetNumber}
                                                    className="material-input__input h-100"
                                                    onFocus={() => handleFocusInputs()} />
                                            </div>
                                            {formik.errors.address && formik.touched.address && formik.errors.address.streetNumber ? (
                                                <div className="material-input__error">{formik.errors.address.streetNumber}</div>
                                            ) : null}
                                            {errors && errors.address && errors.address.streetNumber ? (
                                                <div className="material-input__error">{errors.address.streetNumber}</div>
                                            ) : null}
                                        </div>
                                        {/* USUN KONTO */}
                                        <div className="col-12">
                                            <div className="row justify-content-md-end justify-content-center">
                                                <div className="col-12 col-lg-3 d-flex justify-content-center">
                                                    <button
                                                        type="button"
                                                        id="myProfileDelete"
                                                        className="btn btn--secondary w-100"
                                                        data-toggle="modal"
                                                        onClick={toggleModal}>Usuń konto</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row mt-4 justify-content-center">
                                        <div className="col-12 col-lg-5 d-flex justify-content-center">
                                            <button id="myProfileSave" className="btn btn--primary w-100">Zapisz</button>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <ConfirmModalComponent
                    show={showModal}
                    handleSubmit={handleDeleteAccount}
                    handleToggleModal={toggleModal}
                    contentText="Czy na pewno chcesz usunuąć konto? Operacja jest procesem nieodwracalnym."
                    submitText="TAK" />
            </form >
        )
    };

    return <Profile {...props} />
}

export default CompanyProfileComponent;
